import React from "react"
import { withRouter } from "react-router-dom"

import styled, { css } from "styled-components"
import { Row, Col, SOrderItem, SOrderInfo } from "../../../styles/styles"
import SAlter from "../../Alter"
import NumberFormat from "react-number-format"
import ReactMoment from "react-moment"
import moment from "moment"
import { graphqlUrl } from "../../../Config/Env"
import { dayOfWeekName } from "../../../Config/Util"

const SSOrderItem = styled(SOrderItem)`
  ${({ theme }) => {
    return css`
      padding: ${theme.paddings.base};
      box-shadow: none;
      border: 0;
      .product_list {
        padding: ${theme.paddings.base};
        border: 1px solid #eee;
        border-radius: 5px;
        .data {
          font-size: 12px;
        }
        ::after {
          display: none;
        }
      }
    `
  }}
`
const SSOrderInfo = styled(SOrderInfo)`
  ${({ theme }) => {
    return css`
      padding-top: 0;
      .flextext {
        margin-bottom: 10px;
        ul {
          padding: 0;
          li {
            :nth-child(2) {
              border-bottom: 0;
            }
            :last-child {
              border-bottom: 0;
            }
          }
        }
      }
    `
  }}
`

function Product({
  history,
  match,
  location,
  orderType,
  users,
  ticketProductDate,
  ticketProductDateTime,
  count,

  data,
}) {
  const {
    TicketOrderInit: { ticketProduct },
  } = data

  const CoverImage =
    ticketProduct.coverImage === "" || ticketProduct.coverImage === null
      ? "/assets/img/product/product-default.jpg"
      : `${graphqlUrl}${ticketProduct.coverImage}`

  let productPrice = ticketProduct.isDiscount
    ? ticketProduct.price - ticketProduct.discountPrice
    : ticketProduct.price
  const startDate =
    orderType === "me" && new Date(ticketProductDateTime.ticketDateTime)
  const endDate =
    orderType === "me" &&
    moment(ticketProductDateTime.ticketDateTime).add(
      parseInt(ticketProduct.minute),
      "minutes"
    )

  return (
    <>
      <SSOrderItem>
        <div className="title">티켓정보</div>
        <div className="product_list">
          <div className="img">
            <img src={CoverImage} alt={ticketProduct.name} />
          </div>
          <div className="cont">
            <Row>
              <Col>
                <span className="category">{ticketProduct.store.name}</span>
              </Col>
            </Row>
            <strong>{ticketProduct.name}</strong>
          </div>
        </div>
      </SSOrderItem>
      <SSOrderInfo>
        <div className="flextext">
          <ul>
            {orderType === "me" && (
              <li>
                <div className="left">예매일</div>
                <div className="right">
                  <ReactMoment format="YYYY.MM.DD">{startDate}</ReactMoment>(
                  {dayOfWeekName(startDate.getDay())}){" "}
                  <ReactMoment format="HH:mm">{startDate}</ReactMoment> ~{" "}
                  <ReactMoment format="HH:mm">{endDate}</ReactMoment>
                </div>
              </li>
            )}
            <li style={orderType === "me" ? { display: "none" } : {}}>
              <div className="left">수신자</div>
              <div className="right">
                <b>{users.length}</b>명
              </div>
            </li>
            <li>
              <div className="left">금액</div>
              <div className="right">
                {ticketProduct.isPay ? (
                  <>
                    <b>
                      <NumberFormat
                        value={productPrice}
                        displayType={"text"}
                        thousandSeparator={true}
                      />
                    </b>
                    드림
                  </>
                ) : (
                  `무료`
                )}
              </div>
            </li>
            <li>
              <div className="left">수량</div>
              <div className="right">
                <b>{count}</b>개
              </div>
            </li>
            <li className="total">
              <div className="left">총 결제금액</div>
              <div className="right">
                <b>
                  <NumberFormat
                    value={productPrice * count}
                    displayType={"text"}
                    thousandSeparator={true}
                  />
                </b>
                드림
              </div>
            </li>
          </ul>
        </div>
        <SAlter color="lightgray" border="radius">
          <strong>선물구입 안내</strong>
          <p>
            - 구매하신 상품은 <b>티켓함 &gt; 구매내역</b>에서 확인 가능합니다.
          </p>
        </SAlter>
      </SSOrderInfo>
    </>
  )
}

export default withRouter(Product)
