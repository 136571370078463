import { useState } from "react"
import { withRouter } from "react-router-dom"
import { useMutation } from "@apollo/client"
import {
  NAME_BY_USERS_MUTATION,
  ACCOUNT_ORDERPRODUCTSEND_MUTATION,
} from "../../../../../../Config/Queries"

import styled, { css } from "styled-components"
import {
  Roundbox,
  Col,
  Ready,
  SearchContainer,
  SearchList,
  FormBox,
  UserList,
  TabBars,
} from "../../../../../../styles/styles"
import Tabs, { TabPane } from "rc-tabs"
import { useForm } from "react-hook-form"
import Button from "../../../../../Buttons"
import theme from "../../../../../../styles/Theme"
import { graphqlUrl } from "../../../../../../Config/Env"
import { toast } from "react-toastify"
import { SearchNormal1 } from "iconsax-react"

const PresentUserBox = styled.div`
  ${({ theme }) => {
    return css`
      border-radius: 16px;
      background-color: white;
      .bottom {
        padding: ${theme.paddings.lg} ${theme.paddings.xl};
      }
    `
  }}
`

const renderTabBar = (props, DefaultTabBar) => (
  <TabBars>
    <DefaultTabBar {...props} />
  </TabBars>
)
function PresentUser({
  history,
  match,
  location,
  user,
  setUser,
  userAdd,
  userRemove,
}) {
  console.log(match.params)
  const id = match.params.id

  const [keyword, setKeyword] = useState("")
  const [searchUsers, setSearchUsers] = useState([])

  const [nameByUsersMutation, { loading }] = useMutation(NAME_BY_USERS_MUTATION)
  const [orderProductSendMutation, { loading: sendLoading }] = useMutation(
    ACCOUNT_ORDERPRODUCTSEND_MUTATION
  )

  const { register, handleSubmit, errors, setValue } = useForm({
    mode: "onChange",
  })

  const onSearch = async () => {
    if (keyword === "") {
      toast.error(`수신자 이름을 입력해주세요.`)
      return
    }
    setKeyword("")
    console.log({ keyword })
    try {
      const {
        data: {
          TicketProductUserSeach: { users },
        },
      } = await nameByUsersMutation({
        variables: {
          keyword: keyword,
        },
      })
      setSearchUsers(users)
      return
    } catch (e) {
      console.log(e)
    }
  }
  const onSubmit = async (data) => {
    if (user?.phone === data.phone) {
      toast.error(`이미등록된 전화번호 입니다.`)
      return
    }

    const userObject = {
      isMember: false,
      id: null,
      name: data.name,
      phone: data.phone,
    }
    userAdd(userObject)
    setValue("name", "")
    setValue("phone", "")
  }
  const onSend = async () => {
    if (sendLoading) {
      return
    }
    if (!user) {
      toast.error(`티켓 받는분을 검색해주세요.`)
      return
    }

    const {
      data: { TicketAccountOrderProductSend },
    } = await orderProductSendMutation({
      variables: {
        id,
        user,
      },
    })

    if (TicketAccountOrderProductSend) {
      toast.success(`${user.name}님에게 티켓을 보냈습니다.`)
      history.replace(`/account/readyOrderProducts`)
    }
  }

  return (
    <>
      <PresentUserBox>
        <UserList>
          <ul>
            {user ? (
              <li>
                <div className="left">
                  {user.isMember
                    ? `${user.name}님 - ${user.userId}`
                    : `${user.name}님 - ${user.phone}`}
                </div>
                <div className="right">
                  <Button
                    type="button"
                    color="danger"
                    border="solid"
                    size="xs"
                    onClick={() => userRemove(user)}
                  >
                    삭제
                  </Button>
                </div>
              </li>
            ) : (
              <li className="check_list_done">
                <Roundbox>
                  <div className="icon"></div>
                </Roundbox>
                <div className="cont">
                  <p>수신자를 검색해주세요.</p>
                </div>
              </li>
            )}
          </ul>
        </UserList>

        <Tabs defaultActiveKey="1" renderTabBar={renderTabBar}>
          <TabPane tab={<span>회원</span>} key="1">
            <SearchContainer>
              <form>
                <div className="inner">
                  <button
                    type="button"
                    onClick={onSearch}
                    className="searchBtn"
                  >
                    <SearchNormal1
                      color={theme.colors.black02}
                      variant="Linear"
                      size={18}
                    />
                  </button>
                  <input
                    type="text"
                    name="keyword"
                    placeholder="이름 입력"
                    value={keyword}
                    onChange={(e) => {
                      setKeyword(e.target.value)
                    }}
                    onKeyPress={(e) => {
                      if (e.key === "Enter") {
                        e.preventDefault()
                        onSearch()
                      }
                    }}
                  />
                </div>
              </form>
            </SearchContainer>
            <SearchList>
              {searchUsers.length > 0 ? (
                <ul>
                  {searchUsers.map((item, index) => {
                    const avatarImage =
                      item.avatar === ""
                        ? "/assets/img/product/product-default.jpg"
                        : `${graphqlUrl}${item.avatar}`
                    return (
                      <li key={index}>
                        <label>
                          <Roundbox>
                            <div className="img">
                              <img src={avatarImage} alt={item.name} />
                            </div>
                          </Roundbox>
                          <input
                            type="checkbox"
                            onChange={() => {
                              const user = {
                                isMember: true,
                                id: item.id,
                                userId: item.userId,
                                name: item.name,
                                phone: item.phone,
                              }
                              userAdd(user)
                            }}
                            checked={user?.id === item.id}
                          />
                          <p className="name">
                            {item.role === "User" && (
                              <>
                                {item.name}님<span>({item.userId})</span>
                              </>
                            )}
                            {item.role === "Store" && (
                              <>
                                {item.store.name} - {item.name}님
                                <span>({item.userId})</span>
                              </>
                            )}
                          </p>
                        </label>
                      </li>
                    )
                  })}
                </ul>
              ) : (
                <Ready>
                  <div className="list_none">검색어를 입력해주세요.</div>
                </Ready>
              )}
            </SearchList>
          </TabPane>
          <TabPane tab={<span>비회원</span>} key="2">
            <FormBox>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="form_group">
                  <label>이름</label>
                  <input
                    type="text"
                    name="name"
                    placeholder="이름"
                    ref={register({
                      required: "이름를 입력해주세요.",
                    })}
                  />
                </div>
                <div className="form_group">
                  <label>전화번호</label>
                  <input
                    type="text"
                    name="phone"
                    placeholder="전화번호 ‘-’제외"
                    ref={register({
                      required: "전화번호를 입력해주세요.",
                    })}
                  />
                </div>
                <Button
                  type="submit"
                  color="primary"
                  border="solid"
                  size="sm"
                  width="full"
                >
                  추가
                </Button>
              </form>
            </FormBox>
          </TabPane>
        </Tabs>
        <div className="bottom">
          <Button
            disabled={sendLoading}
            onClick={onSend}
            color="lightprimary"
            width="full"
            size="lg"
          >
            {sendLoading ? "처리중..." : "확인"}
          </Button>
        </div>
      </PresentUserBox>
    </>
  )
}

export default withRouter(PresentUser)
