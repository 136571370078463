import React, { useEffect } from "react"
import { withRouter, Link } from "react-router-dom"
import { useQuery, useMutation } from "@apollo/client"
import {
  ACCOUNT_READYORDERPRODUCTS_QUERY,
  ACCOUNT_ORDERPRODUCT_ISREADSEDIT_MUTATION,
  ACCOUNT_QUERY,
} from "../../../Config/Queries"

import styled, { css } from "styled-components"
import {
  Container,
  TabsNav,
  Ready,
  MainText,
  MainTitle,
} from "../../../styles/styles"
import { Layout } from "../../../Components/Layouts/Layout"
import PageTitle from "../../../Components/Layouts/PageTitle"
import Item from "../../../Components/OrderProduct/Account/OrderProduct/List/Item"
import Pagination from "../../../Components/Pagination"
import SLoading from "../../../Components/Loading"
import qs from "qs"
import { toast } from "react-toastify"

const SContainer = styled(Container)`
  ${({ theme }) => {
    return css`
      padding: 0 ${theme.paddings.xl};
    `
  }}
`
const SItemlist = styled.div`
  ${({ theme }) => {
    return css`
      ul {
        width: 100%;
        li {
          margin-bottom: ${theme.margins.lg};
          a {
            display: block;
          }
        }
      }
    `
  }}
`

function List({ history, match, location }) {
  const isApp = localStorage.getItem("isApp")

  const queryString = qs.parse(location.search.substr(1))
  const page = queryString.page ? queryString.page : 1
  const blockSize = 5
  const first = 10
  const skip = first * (page - 1)

  const baseUrl = "?"

  const { data, loading } = useQuery(ACCOUNT_READYORDERPRODUCTS_QUERY, {
    fetchPolicy: "network-only",
    variables: {
      orderBy: "id_DESC",
      skip: skip,
      first: first,
    },
  })

  const [orderProductIsReadEditMutation] = useMutation(
    ACCOUNT_ORDERPRODUCT_ISREADSEDIT_MUTATION,
    {
      refetchQueries: () => [{ query: ACCOUNT_QUERY }],
    }
  )

  useEffect(() => {
    const onRead = async () => {
      if (isApp) {
        const data = {
          method: "orderProducts",
        }
        window.ReactNativeWebView.postMessage(JSON.stringify(data))
      }
      // try {
      const {
        data: { TicketOrderProductIsReadsEdit },
      } = await orderProductIsReadEditMutation({
        variables: {
          isRead: true,
        },
      })
      // } catch (e) {
      //   console.log(e);
      //   var error = e.toString();
      //   error = error.replace("Error: GraphQL error:", "");
      //   toast.error(error);
      // }
    }
    onRead()
  }, [])

  return (
    <Layout isNav={true} isFooter={true}>
      <PageTitle title="받은 선물함" />
      <SContainer>
        <MainTitle>
          <MainText>사용가능한 티켓이</MainText>
          <MainText>
            <span className="TextBlue">
              {!loading && data.TicketAccountOrderProductReadyList.count}개
            </span>{" "}
            남아있어요.
          </MainText>
        </MainTitle>
        <TabsNav>
          <ul>
            <li className="active">
              <Link to="/account/readyOrderProducts">
                <span>사용가능</span>
              </Link>
            </li>
            <li>
              <Link to="/account/completeOrderProducts">
                <span>사용완료</span>
              </Link>
            </li>
          </ul>
        </TabsNav>
        {!loading ? (
          <>
            {data.TicketAccountOrderProductReadyList.count > 0 ? (
              <>
                <SItemlist>
                  <ul>
                    {data.TicketAccountOrderProductReadyList.ticketOrderProducts.map(
                      (item, index) => {
                        return (
                          <li key={index}>
                            <Link to={`/account/orderProducts/${item.id}`}>
                              <Item item={item} />
                            </Link>
                          </li>
                        )
                      }
                    )}
                  </ul>
                </SItemlist>
                <Pagination
                  totalRecord={data.TicketAccountOrderProductReadyList.count}
                  blockSize={blockSize}
                  pageSize={first}
                  currentPage={page}
                  baseUrl={baseUrl}
                />
              </>
            ) : (
              <Ready>
                <div>예매한 티켓이 없습니다.</div>
              </Ready>
            )}
          </>
        ) : (
          <SLoading />
        )}
      </SContainer>
    </Layout>
  )
}

export default withRouter(List)
