import React from "react"
import { Link } from "react-router-dom"
import { useReactiveVar } from "@apollo/client"
import { isLoggedInVar, logUserOut } from "../../apollo"
import { useQuery } from "@apollo/client"
import { ACCOUNT_QUERY } from "../../Config/Queries"

import { Layout } from "../../Components/Layouts/Layout"
import { Col } from "../../styles/styles"
import styled, { css } from "styled-components"
import ReactMoment from "react-moment"
import { graphqlUrl } from "../../Config/Env"
import SLoading from "../../Components/Loading"
import {
  DirectInbox,
  Edit2,
  Happyemoji,
  HeartAdd,
  NotificationStatus,
  Ticket,
} from "iconsax-react"

const Profile = styled.div`
  ${({ theme }) => {
    return css`
      background-color: ${theme.colors.lightgray};
      padding: ${theme.paddings.xl};
      .profile_top {
        display: flex;
        background-color: white;
        flex-direction: row;
        align-items: center;
        padding: ${theme.paddings.xl} ${theme.paddings.lg};
        margin-bottom: ${theme.margins.sm};
        border-radius: 12px;
        .img {
          width: 44px;
          height: 44px;
          border-radius: 50%;
          overflow: hidden;
          margin-right: ${theme.margins.base};
          img {
            width: 100%;
            height: 100%;
          }
        }
        .inner {
          flex: 1;
          display: flex;
          width: 100%;
          flex-direction: column;
          .date {
            margin-top: ${theme.margins.sm};
            font-size: ${theme.fonts.size.sm};
            color: ${theme.colors.black04};
          }
          .row {
            display: flex;
            justify-content: space-between;
            align-items: center;
            .name {
              font-family: ${theme.fonts.family.base};
              font-size: ${theme.fonts.size.base};
              color: ${theme.colors.black02};
            }
            a {
              font-size: ${theme.fonts.size.sm};
              color: ${theme.colors.black04};
              &:hover {
                color: ${theme.colors.primary};
              }
            }
          }
        }
      }
      .navi_row {
        ul {
          margin-top: ${theme.margins.base};
          display: flex;
          flex-direction: row;
          li {
            flex: 1;
            display: flex;
            justify-content: center;
            :nth-child(2) {
              padding: 0 ${theme.paddings.base};
            }
            a {
              background-color: white;
              position: relative;
              width: 100%;
              padding: ${theme.paddings.base} 0;
              border-radius: 12px;
              display: flex;
              flex-direction: column;
              align-items: center;
              font-size: ${theme.fonts.size.base};
              font-family: ${theme.fonts.family.base};
              .icon {
                color: ${theme.colors.primary};
              }
              p {
                margin-top: ${theme.margins.xs};
                color: #666;
                font-size: 13px;
                font-weight: ${theme.fonts.weight.medium};
                letter-spacing: -0.5px;
                b {
                  color: ${theme.colors.primary};
                }
              }
            }
          }
        }
      }
    `
  }}
`
const Navi = styled.nav`
  ${({ theme }) => {
    return css`
      margin-top: ${theme.paddings.base};
      ul {
        li {
          display: flex;
          padding: ${theme.paddings.base} ${theme.paddings.xl};
          :last-child {
            border-bottom: 0;
          }
          a {
            position: relative;
            flex: 1 0 auto;
            display: flex;
            align-items: center;
            font-size: ${theme.fonts.size.base};
            .icon {
              margin-left: 0;
              color: ${theme.colors.black04};
            }
            span {
              display: flex;
              margin-left: ${theme.margins.sm};
              font-weight: ${theme.fonts.weight.medium};
              color: #555;
              font-size: ${theme.fonts.size.base};
            }
          }
        }
      }
    `
  }}
`
const RoundDot = styled.div`
  ${({ theme }) => {
    return css`
      position: absolute;
      right: 32px;
      top: 12px;
      width: 6px;
      height: 6px;
      background-color: ${theme.colors.danger};
      border-radius: 12px;
    `
  }}
`

function Home() {
  const isLoggedIn = useReactiveVar(isLoggedInVar)
  const isApp = localStorage.getItem("isApp")

  const { data, loading } = useQuery(ACCOUNT_QUERY, {
    fetchPolicy: "network-only",
  })

  if (!loading) {
    const {
      TicketAccountUser: {
        user,
        ticketOrderProductsCount,
        ticketOrdersCount,
        ticketWishlistsCount,
      },
    } = data
    const avatarImage =
      user.avatar === "" || user.avatar === null
        ? "/assets/img/user/user-default.jpg"
        : `${graphqlUrl}${user.avatar}`
    return (
      <Layout isNav={true} isFooter={true}>
        <Profile>
          <div className="profile_top">
            <div className="img">
              <img src={avatarImage} alt={user.name} />
            </div>
            <div className="inner">
              <div className="row">
                <b className="name">
                  {user.name}({user.userId})님
                </b>
                {!isApp && (
                  <Link to="#" onClick={logUserOut}>
                    로그아웃
                  </Link>
                )}
              </div>
              <div className="date">
                최근 로그인 :{" "}
                <ReactMoment format="YYYY.MM.DD HH:mm:ss">
                  {user.visitedAt}
                </ReactMoment>
              </div>
            </div>
          </div>
          <div className="navi_row">
            <ul>
              <li>
                <Link
                  to="/account/readyOrderProducts"
                  onClick={() => {
                    if (isApp) {
                      const data = {
                        method: "orderProducts",
                      }
                      window.ReactNativeWebView.postMessage(
                        JSON.stringify(data)
                      )
                    }
                  }}
                >
                  <span className="icon">
                    {user.ticketOrderProductCount > 0 && <RoundDot />}
                    <Ticket variant="Bulk" size="28" />
                  </span>
                  <p>
                    예매확인/취소 <b>{ticketOrderProductsCount}</b>
                  </p>
                </Link>
              </li>
              <li>
                <Link to="/account/orders">
                  <span className="icon">
                    <DirectInbox variant="Bulk" size="28" />
                  </span>
                  <p>
                    구매내역 <b>{ticketOrdersCount}</b>
                  </p>
                </Link>
              </li>
              <li>
                <Link to="/account/wishlist">
                  <span className="icon">
                    <HeartAdd variant="Bulk" size="28" />
                  </span>
                  <p>
                    관심티켓 <b>{ticketWishlistsCount}</b>
                  </p>
                </Link>
              </li>
            </ul>
          </div>
        </Profile>
        <Navi>
          <ul>
            <li>
              <Link to="/account/readyReviews">
                <span className="icon">
                  <Edit2 variant="Linear" size="20" />
                </span>
                <span>이용후기</span>
              </Link>
            </li>
            <li>
              <Link to="/account/inquiries">
                <span className="icon">
                  <Happyemoji variant="Linear" size="20" />
                </span>
                <span>티켓문의</span>
              </Link>
            </li>
            <li>
              <Link to="/notices">
                <span className="icon">
                  <NotificationStatus variant="Linear" size="20" />
                </span>
                <span>공지사항</span>
              </Link>
            </li>
          </ul>
        </Navi>
      </Layout>
    )
  } else {
    return (
      <>
        <SLoading />
      </>
    )
  }
}
export default Home
