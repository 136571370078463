import React from "react";
import PropTypes from "prop-types";
import { Route, Switch, Redirect } from "react-router-dom";

// 홈
import Home from "../Routes/Home/index";

//상품
import ProductList from "../Routes/Product/List/index";
import ProductDetail from "../Routes/Product/Detail/index";

//문의
import InquiryWrite from "../Routes/Inquiry/Write";
import InquiryEdit from "../Routes/Inquiry/Edit";

//주문
import OrderInit from "../Routes/Order/Init";
import OrderAuthPassword from "../Routes/Order/AuthPassword";
import OrderResult from "../Routes/Order/Result";

// 검색
import SearchInit from "../Routes/Search/Init";
import SearchResult from "../Routes/Search/Result";

// 마이페이지
// 마이페이지 > 홈
import AccountHome from "../Routes/Account/Home";

// 마이페이지 > 받은 선물함
import AccountOrderProductReadyList from "../Routes/Account/OrderProduct/ReadyList";
import AccountOrderProductCompleteList from "../Routes/Account/OrderProduct/CompleteList";
import AccountOrderProductDetail from "../Routes/Account/OrderProduct/Detail";

// 마이페이지 > wishlist
import AccountWishlistList from "../Routes/Account/Wishlist";

// // 마이페이지 > 선물후기
import AccountReviewReadyList from "../Routes/Account/Review/ReadyList";
import AccountReviewWrite from "../Routes/Account/Review/Write";
import AccountReviewCompleteList from "../Routes/Account/Review/CompleteList";
import AccountReviewEdit from "../Routes/Account/Review/Edit";

// 마이페이지 > 선물문의
import AccountInquiry from "../Routes/Account/Inquiry";

// 마이페이지 > 구매내역
import AccountOrderList from "../Routes/Account/Order/List";
import AccountOrderDetail from "../Routes/Account/Order/Detail";

// 공지사항
import NoticeList from "../Routes/Notice/List";

// 선물하기 이용약관
import Provision from "../Routes/Provision/Provision";
import Privacy from "../Routes/Provision/Privacy";
import ThirdParty from "../Routes/Provision/ThirdParty";

// 로그인
import Login from "../Routes/Auth/Login";

// 비회원
import GuestLogin from "../Routes/Guest/Login";
import GuestTicketDetail from "../Routes/Guest/TicketDetail";

// 404 Errorç
import NotFound from "../Routes/NotFound";

// import SampleLink from "../Sample/Link";
// import SampleHome from "../Sample/Home/Index";
// import SampleSearchInit from "../Sample/Search/Init";
// import SampleSearchResult from "../Sample/Search/Result";
// import SampleLogin from "../Sample/Auth/Login";
// import SampleGuestTrace from "../Sample/Auth/Guest/Trace";
// import SampleGuestDetail from "../Sample/Auth/Guest/Detail";
// import SampleGuestComplation from "../Sample/Auth/Guest/Complation";
// import SampleTicketList from "../Sample/Ticket/List/Index";
// import SampleTicketDetail from "../Sample/Ticket/Detail/Index";
// import SampleOrderInit from "../Sample/Order/Init";
// import SampleOrderResult from "../Sample/Order/Result";
// import SampleOrderAuthPassword from "../Sample/Order/AuthPassword";
// import SampleAccount from "../Sample/Account/Home";
// import SampleAccountWishlist from "../Sample/Account/Wishlist";
// import SampleAccountOrderList from "../Sample/Account/Order/List";
// import SampleAccountOrderDetail from "../Sample/Account/Order/Detail";
// import SampleAccountOrderTicketReadyList from "../Sample/Account/OrderTicket/ReadyList";
// import SampleAccountOrderTicketCompleteList from "../Sample/Account/OrderTicket/CompleteList";
// import SampleAccountOrderTicketDetail from "../Sample/Account/OrderTicket/Detail";
// import SampleAccountOrderTicketComplete from "../Sample/Account/OrderTicket/Complete";
// import SampleAccountReviewReadyList from "../Sample/Account/Review/ReadyList";
// import SampleAccountReviewCompleteList from "../Sample/Account/Review/CompleteList";
// import SampleAccountReviewWrite from "../Sample/Account/Review/Write";
// import SampleAccountInquiry from "../Sample/Account/Inquiry";
// import SampleInquiryWrite from "../Sample/Inquiry/Write";
// import SampleNoticeList from "../Sample/Notice/List";
// import SampleProvisionProvision from "../Sample/Provision/Provision";
// import SampleProvisionPrivacy from "../Sample/Provision/Privacy";
// import SampleProvisionThirdParty from "../Sample/Provision/ThirdParty";

const AppRouter = ({ isLoggedIn }) => {
  return (
    <Switch>
      <Route exact path={"/"}>
        <Redirect to={"/home"} />
      </Route>
      <Route exact path={"/home"}>
        <Home />
      </Route>

      <Route exact path="/products">
        <ProductList />
      </Route>
      <Route exact path="/products/:id">
        <ProductDetail />
      </Route>
      <Route exact path="/products/:id/inquiries/write">
        {isLoggedIn ? <InquiryWrite /> : <Redirect to={"/login"} />}
      </Route>
      <Route exact path="/products/:id/inquiries/:inquiryId/edit">
        {isLoggedIn ? <InquiryEdit /> : <Redirect to={"/login"} />}
      </Route>

      <Route exact path="/order">
        <OrderInit />
      </Route>
      <Route exact path="/order/authPassword">
        <OrderAuthPassword />
      </Route>
      <Route exact path="/order/result">
        <OrderResult />
      </Route>

      <Route exact path="/search">
        <SearchInit />
      </Route>
      <Route exact path="/search/result">
        <SearchResult />
      </Route>

      <Route exact path="/notices">
        <NoticeList />
      </Route>

      <Route exact path="/provision/provision">
        <Provision />
      </Route>
      <Route exact path="/provision/privacy">
        <Privacy />
      </Route>
      <Route exact path="/provision/thirdParty">
        <ThirdParty />
      </Route>

      <Route exact path="/account">
        {isLoggedIn ? <AccountHome /> : <Redirect to={"/login"} />}
      </Route>
      <Route exact path="/account/readyOrderProducts">
        {isLoggedIn ? (
          <AccountOrderProductReadyList />
        ) : (
          <Redirect to={"/login"} />
        )}
      </Route>
      <Route exact path="/account/completeOrderProducts">
        {isLoggedIn ? (
          <AccountOrderProductCompleteList />
        ) : (
          <Redirect to={"/login"} />
        )}
      </Route>
      <Route exact path="/account/orderProducts/:id">
        {isLoggedIn ? (
          <AccountOrderProductDetail />
        ) : (
          <Redirect to={"/login"} />
        )}
      </Route>
      <Route exact path="/account/wishlist">
        {isLoggedIn ? <AccountWishlistList /> : <Redirect to={"/login"} />}
      </Route>

      <Route exact path="/account/readyReviews">
        {isLoggedIn ? <AccountReviewReadyList /> : <Redirect to={"/login"} />}
      </Route>
      <Route exact path="/account/readyReviews/:ticketOrderProductId/write">
        {isLoggedIn ? <AccountReviewWrite /> : <Redirect to={"/login"} />}
      </Route>
      <Route exact path="/account/completeReviews">
        {isLoggedIn ? (
          <AccountReviewCompleteList />
        ) : (
          <Redirect to={"/login"} />
        )}
      </Route>
      <Route exact path="/account/completeReviews/:id/edit">
        {isLoggedIn ? <AccountReviewEdit /> : <Redirect to={"/login"} />}
      </Route>
      <Route exact path="/account/inquiries">
        {isLoggedIn ? <AccountInquiry /> : <Redirect to={"/login"} />}
      </Route>

      <Route exact path="/account/orders">
        {isLoggedIn ? <AccountOrderList /> : <Redirect to={"/login"} />}
      </Route>
      <Route exact path="/account/orders/:id">
        {isLoggedIn ? <AccountOrderDetail /> : <Redirect to={"/login"} />}
      </Route>
      <Route exact path="/login">
        {isLoggedIn ? <Redirect to={"/home"} /> : <Login />}
      </Route>

      <Route exact path="/guest/login">
        <GuestLogin />
      </Route>
      <Route exact path="/guest/ticketDetail">
        <GuestTicketDetail />
      </Route>

      <Route>
        <NotFound />
      </Route>

      {/* <Route path={"/sample"} exact>
        <SampleLink />
      </Route>
      <Route path={"/sample/login"}>
        <SampleLogin />
      </Route>
      <Route path={"/sample/auth/guest/trace"}>
        <SampleGuestTrace />
      </Route>
      <Route path={"/sample/auth/guest/detail"}>
        <SampleGuestDetail />
      </Route>
      <Route path={"/sample/auth/guest/complation"}>
        <SampleGuestComplation />
      </Route>
      <Route path={"/sample/Home"}>
        <SampleHome />
      </Route>
      <Route path={"/sample/search/init"}>
        <SampleSearchInit />
      </Route>
      <Route path={"/sample/search/result"}>
        <SampleSearchResult />
      </Route>
      <Route path={"/sample/provision/provision"}>
        <SampleProvisionProvision />
      </Route>
      <Route path={"/sample/provision/privacy"}>
        <SampleProvisionPrivacy />
      </Route>
      <Route path={"/sample/provision/thirdparty"}>
        <SampleProvisionThirdParty />
      </Route>
      <Route path={"/sample/ticket/list"}>
        <SampleTicketList />
      </Route>
      <Route path={"/sample/ticket/detail"}>
        <SampleTicketDetail />
      </Route>
      <Route path={"/sample/order/init"}>
        <SampleOrderInit />
      </Route>
      <Route path={"/sample/order/authpassword"}>
        <SampleOrderAuthPassword />
      </Route>
      <Route path={"/sample/order/result"}>
        <SampleOrderResult />
      </Route>
      <Route path={"/sample/account/home"}>
        <SampleAccount />
      </Route>
      <Route path={"/sample/account/wishlist"}>
        <SampleAccountWishlist />
      </Route>
      <Route path={"/sample/account/order/list"}>
        <SampleAccountOrderList />
      </Route>
      <Route path={"/sample/account/order/detail"}>
        <SampleAccountOrderDetail />
      </Route>
      <Route path={"/sample/account/orderticket/readylist"}>
        <SampleAccountOrderTicketReadyList />
      </Route>
      <Route path={"/sample/account/orderticket/completelist"}>
        <SampleAccountOrderTicketCompleteList />
      </Route>
      <Route path={"/sample/account/orderticket/detail"}>
        <SampleAccountOrderTicketDetail />
      </Route>
      <Route path={"/sample/account/orderticket/complete"}>
        <SampleAccountOrderTicketComplete />
      </Route>
      <Route path={"/sample/account/review/readylist"}>
        <SampleAccountReviewReadyList />
      </Route>
      <Route path={"/sample/account/review/completelist"}>
        <SampleAccountReviewCompleteList />
      </Route>
      <Route path={"/sample/account/review/write"}>
        <SampleAccountReviewWrite />
      </Route>
      <Route path={"/sample/account/inquiry"}>
        <SampleAccountInquiry />
      </Route>
      <Route path={"/sample/inquiry/write"}>
        <SampleInquiryWrite />
      </Route>
      <Route path={"/sample/account/notice/list"}>
        <SampleNoticeList />
      </Route> */}
    </Switch>
  );
};

AppRouter.propTypes = {
  isLoggedIn: PropTypes.bool.isRequired,
};

export default AppRouter;
