import React from "react";
import { withRouter, Link } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { CATEGORIES_QUERY } from "../../../../Config/Queries";

import styled, { css } from "styled-components";
import Loading from "../../../Loading";
import { graphqlUrl } from "../../../../Config/Env";

const SearchCategory = styled.div`
  ${({ theme }) => {
    return css`
      border-bottom: solid 4px #eee;
      padding: ${theme.paddings.lg} ${theme.paddings.base};
      h1 {
        display: flex;
        font-size: ${theme.fonts.size.base};
        color: ${theme.colors.default};
        font-weight: ${theme.fonts.weight.medium};
      }
      ul {
        margin-top: ${theme.margins.base};
        display: flex;
        flex-wrap: wrap;
        li {
          flex: 1 0 50%;
          display: flex;
          border-bottom: solid 1px #eee;
          a {
            flex: 1;
            display: flex;
            padding: ${theme.paddings.base};
            flex-direction: row;
            font-size: ${theme.fonts.size.sm};
            color: #666;
            align-items: center;
            .round {
              width: 28px;
              height: 28px;
              border-radius: 5px;
              overflow: hidden;
              justify-content: center;
              align-items: center;
              text-align: center;
              border: solid 1px #eee;
              img {
                max-width: 100%;
              }
            }
            svg {
              color: ${theme.colors.gray};
              font-size: 14px;
            }
            &:hover {
              color: ${theme.colors.primary};
            }
          }
          .text {
            font-size: ${theme.fonts.size.sm};
            margin-left: ${theme.margins.base};
          }
        }
      }
    `;
  }}
`;

function Category({ history, match, location, productType }) {
  const { data, loading } = useQuery(CATEGORIES_QUERY, {
    fetchPolicy: "network-only",
    variables: {
      parentId: null,
    },
  });

  let title = "티켓분류";
  let uri = "/products";

  if (!loading) {
    return (
      <SearchCategory>
        <h1>{title}</h1>
        <ul>
          {data.TicketProductCategoryList.ticketCategories.map(
            (item, index) => {
              const iconImage =
                item.iconImage === "" || item.iconImage === null
                  ? "/assets/img/category-default.png"
                  : `${graphqlUrl}${item.iconImage}`;
              return (
                <li key={index}>
                  <Link to={`${uri}?depth1=${item.id}`}>
                    <span className="round">
                      <img src={iconImage} alt={item.name} />
                    </span>
                    <div className="text">
                      {item.name}({item.ticketProductsCount})
                    </div>
                  </Link>
                </li>
              );
            }
          )}
        </ul>
      </SearchCategory>
    );
  } else {
    return <Loading />;
  }
}

export default withRouter(Category);
