import { useState } from "react"
import { withRouter } from "react-router-dom"
import { useMutation } from "@apollo/client"
import {
  ACCOUNT_ORDERREFUND_MUTATION,
  ACCOUNT_ORDER_QUERY,
  ACCOUNT_ORDERPRODUCTREFUND_MUTATION,
} from "../../../../../Config/Queries"

import styled, { css } from "styled-components"
import { Row, Col, Roundbox, SearchList } from "../../../../../styles/styles"
import ReactMoment from "react-moment"
import Labels from "../../../../Labels"
import Buttons from "../../../../Buttons"

import { dayOfWeekName } from "../../../../../Config/Util"
import moment from "moment"
import { ArrowDown, ArrowDown2, ArrowUp2 } from "iconsax-react"
import theme from "../../../../../styles/Theme"

const ReserveList = styled.div`
  ${({ theme }) => {
    return css`
      margin-top: ${theme.margins.base};
      background-color: #fafafa;
      border: solid 1px #eee;
      border-radius: 5px;
      overflow: hidden;
      .reserve_top {
        background-color: white;
        position: relative;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: ${theme.paddings.base};
        font-size: 15px;
        .arrowDown {
          position: relative;
          :before {
            position: absolute;
            right: 5px;
            margin: -5px 0 0 !important;
            top: 50%;
            content: "";
            border-radius: 2px;
            height: 6px !important;
            width: 6px !important;
            border-bottom: 2px solid #777 !important;
            border-right: 2px solid #777 !important;
            transform: rotate(45deg) !important;
            z-index: 10;
          }
        }
        .arrowUp {
          position: relative;
          :before {
            position: absolute;
            right: 5px;
            margin: -5px 0 0 !important;
            top: 50%;
            content: "";
            border-radius: 2px;
            height: 6px !important;
            width: 6px !important;
            border-bottom: 2px solid #777;
            border-right: 2px solid #777;
            transform: rotate(-135deg);
            z-index: 10;
          }
        }
        cursor: pointer;
        .left {
          flex: 1;
          font-size: 15px;
          color: #333;
        }
        .right {
          flex: 0 0 auto;
          text-align: right;
          justify-content: flex-end;
        }
        .button {
          right: 5px;
          width: 20px;
          height: 20px;
          line-height: 20px;
          text-align: center;
          margin-top: -10px;
          border-radius: 50%;
          z-index: 9;
          &.open {
            :before {
              content: "\f077";
              font-family: ${theme.fonts.family.fontAwesome};
              color: #fff;
              font-size: 11px;
            }
          }
          &:before {
            content: "\f078";
            font-family: ${theme.fonts.family.fontAwesome};
            color: #fff;
            font-size: 11px;
          }
        }
      }
      .reserve_content {
        ul {
          height: 180px;
          overflow-x: hidden;
          overflow-y: scroll;
          ::-webkit-scrollbar {
            display: none;
          }
          li {
            display: flex;
            flex-direction: row;
            padding: ${theme.paddings.base};
            border-bottom: solid 1px #eee;
            .left {
              flex: 0 0 auto;
              font-size: 14px;
              color: #333;
              font-weight: 500;
            }
            .center {
              padding-left: 5px;
              flex: 1;
              .name {
                font-size: ${theme.fonts.size.sm};
                color: ${theme.colors.black01};
                font-weight: ${theme.fonts.weight.medium};
              }
              span {
                font-size: 13px;
                color: ${theme.colors.black03};
                font-weight: ${theme.fonts.weight.base};
              }
              .date {
                margin-top: 5px;
                font-size: 13px;
                color: ${theme.colors.black03};
                font-weight: ${theme.fonts.weight.base};
              }
            }
            .right {
              display: flex;
              flex: 0 0 auto;
              justify-content: flex-end;
              align-items: center;
            }

            .check {
              flex: 1;
              display: flex;
              align-items: center;
              color: #666;
              font-size: 14px;
              input {
                position: relative;
              }
              input[type="checkbox"] {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 20px;
                height: 20px;
                border-radius: 50%;
                border: solid 1px #e5e5e5;
                ::before {
                  content: "\f00c";
                  font-family: ${theme.fonts.family.fontAwesome};
                  color: #ccc;
                }
                :checked {
                  background-color: ${theme.colors.primary};
                  border: solid 1px ${theme.colors.primary};
                  ::before {
                    font-family: ${theme.fonts.family.fontAwesome};
                    color: white;
                  }
                }
              }
              span {
                display: flex;
                margin-left: ${theme.margins.base};
              }
            }
          }
        }
      }
      .hide {
        display: none;
      }
    `
  }}
`

function ProductOrderProduct({ history, match, location, data }) {
  console.log({ data })
  const id = match.params.id

  const {
    TicketAccountOrderDetail: { count, ticketOrderProducts, ticketProduct },
  } = data

  const [orderProducts, setOrderProducts] = useState([])

  const orderProductSelectToggle = (orderProduct) => {
    const orderProductIds =
      orderProducts.indexOf(orderProduct.id) > -1
        ? [...orderProducts.filter((item) => item !== orderProduct.id)]
        : [...orderProducts, orderProduct.id]

    setOrderProducts(orderProductIds)
  }

  const [orderProductRefundMutation] = useMutation(
    ACCOUNT_ORDERPRODUCTREFUND_MUTATION,
    {
      refetchQueries: () => [
        {
          query: ACCOUNT_ORDER_QUERY,
          variables: {
            id,
          },
        },
      ],
    }
  )
  const onRefund = async (ticketOrderProductId) => {
    console.log(`onRefund : ${ticketOrderProductId}`)
    if (!window.confirm("티켓을 수신자에게 회수(환불)하시겠습니까?")) {
      return
    }

    try {
      const {
        data: { TicketAccountOrderProductRefund },
      } = await orderProductRefundMutation({
        variables: { id: ticketOrderProductId },
      })

      if (TicketAccountOrderProductRefund) {
        history.replace(`/account/orders/${id}`)
      }
    } catch (e) {
      console.log(e)
    }
  }
  const [isDetail, setIsDetail] = useState(false)
  const toggleView = () => {
    setIsDetail(!isDetail)
  }

  return (
    <ReserveList>
      <Row className="reserve_top">
        <Col>
          수신자 <b> {ticketOrderProducts.length}</b>명
        </Col>
      </Row>
      <div className={`reserve_content `}>
        <ul>
          {ticketOrderProducts.map((item, index) => {
            const ticketDateTime = new Date(
              item.ticketProductDateTime?.ticketDateTime
            )
            const ticketDateTimeBefore = ticketDateTime.setDate(
              ticketDateTime.getDate() - 1
            )
            const limitDate = item.ticketProductDateTime
              ? new Date(ticketDateTimeBefore)
              : new Date(ticketProduct.applyEndDateTime)

            const startDate =
              item.isReservation === true &&
              new Date(item.ticketProductDateTime?.ticketDateTime)
            const endDate =
              item.isReservation === true &&
              moment(item.ticketProductDateTime?.ticketDateTime).add(
                parseInt(ticketProduct.minute),
                "minutes"
              )

            return (
              <li key={index}>
                <div className="left">
                  {item.commonStatus === "S" && (
                    <>
                      {limitDate <= new Date() ? (
                        <Labels bg={"none"} color="lightgray">
                          만료
                        </Labels>
                      ) : (
                        <>
                          {item.isReservation === true && (
                            <Labels bg={"none"} color="lightwarning">
                              예매
                            </Labels>
                          )}
                          {item.isReservation === false && (
                            <Labels bg={"none"} color="lightgray">
                              대기
                            </Labels>
                          )}
                        </>
                      )}
                    </>
                  )}
                  {item.commonStatus === "C" && (
                    <>
                      {item.isRefund === true && (
                        <Labels bg={"none"} color="lightdanger">
                          환불
                        </Labels>
                      )}

                      {item.isRefund === false && (
                        <Labels bg={"none"} color="lightprimary">
                          사용
                        </Labels>
                      )}
                    </>
                  )}
                </div>
                <div className="center">
                  {item.isMember ? (
                    <div className="name">
                      {item.name}(<b>{item.count}</b>장)
                      <span> - {item.user.userId}</span>
                    </div>
                  ) : (
                    <div className="name">
                      {item.name}(<b>{item.count}</b>장)
                      <span> - {item.phone}</span>
                    </div>
                  )}
                  {item.ticketProductDateTime && (
                    <>
                      <div className="date">
                        예매일:
                        <ReactMoment format="YYYY.MM.DD">
                          {startDate}
                        </ReactMoment>
                        ({dayOfWeekName(startDate.getDay())}){" "}
                        <ReactMoment format="HH:mm">{startDate}</ReactMoment> ~{" "}
                        <ReactMoment format="HH:mm">{endDate}</ReactMoment>
                      </div>
                    </>
                  )}
                </div>
                <div className="right">
                  {item.commonStatus === "S" && (
                    <>
                      {limitDate <= new Date() ? (
                        <Buttons color="lightgray" size="xs" disabled>
                          회수
                        </Buttons>
                      ) : (
                        <Buttons
                          onClick={() => onRefund(item.id)}
                          color="danger"
                          border="solid"
                          size="xs"
                        >
                          회수
                        </Buttons>
                      )}
                    </>
                  )}
                </div>
              </li>
            )
          })}
        </ul>
      </div>
    </ReserveList>
  )
}

export default withRouter(ProductOrderProduct)
