import React, { useState } from "react"
import { withRouter } from "react-router-dom"
import { useMutation } from "@apollo/client"
import {
  ACCOUNT_REVIEWWRITE_MUTATION,
  ACCOUNT_READYREVIEWS_QUERY,
} from "../../../Config/Queries"

import { Layout } from "../../../Components/Layouts/Layout"
import PageTitle from "../../../Components/Layouts/PageTitle"
import styled, { css } from "styled-components"
import { Container, FooterButton } from "../../../styles/styles"
import { useForm } from "react-hook-form"
import FormError from "../../../Components/FormError"
import ReactStar from "react-rating-stars-component"
import Button from "../../../Components/Buttons"

const SContainer = styled(Container)`
  ${({ theme }) => {
    return css`
      padding: ${theme.paddings.xl};
    `
  }}
`
const Stars = styled.i`
  ${({ theme }) => {
    return css`
      padding: 0 0.2rem;
      ::before {
        content: "\f005";
        font-family: ${theme.fonts.family.fontAwesomeL};
        font-size: ${theme.fonts.size.title};
        color: #bbb;
      }
      ${(props) =>
        props.option === "active" &&
        css`
          ::before {
            content: "\f005";
            color: ${theme.colors.primary};
            font-family: ${theme.fonts.family.fontAwesomeS};
          }
        `}
    `
  }}
`
const SFormbox = styled.div`
  ${({ theme }) => {
    return css`
      background-color: white;
      overflow: hidden;
      flex: 1 0 auto;
      form {
        display: flex;
        flex-direction: column;
        .title {
          display: block;
          margin-bottom: ${theme.margins.base};
        }
        .form_group {
          label {
            display: flex;
            flex-direction: column;
            color: ${theme.colors.black03};
            font-size: ${theme.fonts.size.sm};
            margin-bottom: ${theme.margins.sm};
          }
          textarea {
            width: 100%;
            height: 200px;
            padding: ${theme.paddings.base};
            border: solid 1px #eee;
            border-radius: 8px;
            font-size: ${theme.fonts.size.base};
            font-family: ${theme.fonts.family.base};
          }
        }
        .button {
          margin-top: ${theme.margins.sm};
        }
      }
    `
  }}
`
const Scorebox = styled.div`
  ${({ theme }) => {
    return css`
      display: flex;
      flex-direction: column;
      padding: 30px 0;
      justify-content: center;
      align-items: center;
      .title {
        margin-bottom: ${theme.margins.lg};
        color: ${theme.colors.black01};
        font-size: ${theme.fonts.size.xl};
        font-weight: ${theme.fonts.weight.medium};
      }
      .text {
        margin-top: ${theme.margins.base};
        color: ${theme.colors.primary};
        font-size: 14px;
      }
    `
  }}
`
function Write({ history, match, location }) {
  const ticketOrderProductId = match.params.ticketOrderProductId

  const [score, setScore] = useState(0)

  const [reviewWriteMutation, { loading }] = useMutation(
    ACCOUNT_REVIEWWRITE_MUTATION,
    {
      refetchQueries: () => [
        {
          query: ACCOUNT_READYREVIEWS_QUERY,
          variables: {
            orderBy: "id_DESC",
            skip: 0,
            first: 10,
          },
        },
      ],
    }
  )
  const { register, handleSubmit, errors } = useForm()

  const onSubmit = async (data) => {
    try {
      const {
        data: { TicketAccountReviewWrite },
      } = await reviewWriteMutation({
        variables: {
          ticketOrderProduct: ticketOrderProductId,
          score: score,
          contents: data.contents,
        },
      })

      if (TicketAccountReviewWrite) {
        history.push(`/account/completeReviews${location.search}`)
      }
    } catch (e) {
      console.log(e)
    }
  }
  // console.log(errors);

  return (
    <>
      <Layout isNav={false} isFooterButton={true}>
        <PageTitle title="선물후기" />
        <SContainer>
          <SFormbox>
            <Scorebox>
              <strong className="title">선물은 만족하셨나요?</strong>

              <ReactStar
                onChange={(newRating) => {
                  setScore(newRating)
                }}
                emptyIcon={<Stars />}
                filledIcon={<Stars option="active" />}
              />
              <p className="text">별점을 선택해주세요.</p>
            </Scorebox>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="form_group">
                <label>
                  <textarea
                    name="contents"
                    placeholder="선물후기를 입력해주세요."
                    ref={register({
                      required: "선물후기를 입력해주세요.",
                    })}
                    rows="10"
                  ></textarea>
                </label>
              </div>
              <FormError message={errors?.contents?.message} />
              <FooterButton>
                <div>
                  <Button
                    type="submit"
                    disabled={loading}
                    color="primary"
                    width="full"
                  >
                    {loading ? "처리중..." : "후기작성"}
                  </Button>
                </div>
              </FooterButton>
            </form>
          </SFormbox>
        </SContainer>
      </Layout>
    </>
  )
}
export default withRouter(Write)
