import styled, { css } from "styled-components"
import { Col, InputGroup } from "../../../../../styles/styles"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPlus, faMinus, faTimes } from "@fortawesome/pro-regular-svg-icons"
import Button from "../../../../Buttons"
import NumberFormat from "react-number-format"
import { toast } from "react-toastify"
import RowInfo from "../../../../RowInfo"

const SCounter = styled.div`
  ${({ theme }) => {
    return css`
      .price_top {
        padding: ${theme.paddings.base} ${theme.paddings.base} 0;
      }
      .title {
        font-size: ${theme.fonts.size.sm};
        margin-bottom: ${theme.margins.sm};
      }
      .price_content {
        padding: ${theme.paddings.xl};
        border-radius: 5px;
        .name {
          padding: ${theme.paddings.base} 0;
          font-size: ${theme.fonts.size.base};
        }
        .price_box {
          background-color: ${theme.colors.lightgray};
          border: solid 1px #eee;
          border-top-right-radius: 5px;
          border-top-left-radius: 5px;
          padding: ${theme.paddings.base};
        }
        .price_total {
          background-color: white;
          border: solid 1px #eee;
          padding: ${theme.paddings.sm} ${theme.paddings.base};
          font-size: ${theme.fonts.size.base};
          justify-content: flex-end;
          text-align: right;
          color: #444;
          border-bottom-right-radius: 5px;
          border-bottom-left-radius: 5px;
          b {
            padding-left: 0.5rem;
            font-weight: bold;
          }
        }
      }
      .bottom {
        padding: 0 ${theme.paddings.xl} ${theme.paddings.lg};
      }
      .check_list {
        margin-top: ${theme.margins.xs};
        overflow-x: scroll;
        overflow-y: hidden;
        white-space: nowrap;
        ::-webkit-scrollbar {
          display: none;
        }
        display: flex;
        flex-direction: row;
        li {
          display: flex;
          flex-direction: column;
          padding: ${theme.paddings.base};
          align-items: center;
          justify-content: center;
          p {
            margin-top: ${theme.margins.xs};
            font-size: ${theme.fonts.size.sm};
            font-weight: normal;
            color: ${theme.colors.black01};
          }
          .close {
            background: rgba(0, 0, 0, 0.5);
            position: absolute;
            z-index: 11;
            top: -7px;
            right: -7px;
            display: flex;
            width: 12px;
            height: 12px;
            border-radius: 16px;
            align-items: center;
            justify-content: center;
            :hover {
              background: rgba(0, 0, 0, 0.6);
            }
            ::before {
              content: "\f00d";
              font-family: ${theme.fonts.family.fontAwesomeL};
              font-size: 13px;
              line-height: 0;
              color: #fff;
            }
          }
        }
        .check_list_done {
          flex: 1;
          display: flex;
          flex-direction: row;
          align-items: center;
          p {
            margin: 0 0 0 0.5rem;
            font-size: 1.3rem;
            font-weight: normal;
            color: #777;
          }
        }
      }
      form {
        width: 100%;
        display: flex;
        flex-direction: column;
        .inner {
          border: solid 1px #eee;
          border-radius: 8px;
          overflow: hidden;
        }
      }
      .scroll {
        height: 120px;
        overflow-y: scroll;
        white-space: nowrap;
        ::-webkit-scrollbar {
          display: none;
        }
      }
      .form_group {
        position: relative;
        width: 100%;
        margin-bottom: 10px;
        .form_input {
          display: flex;
          width: 100%;
          &.row {
            flex-direction: row;
            .first {
              position: relative;
              display: flex;
              flex: 0 0 20%;
              margin-right: 5px;
              ::before {
                position: absolute;
                right: 10px;
                top: 50%;
                transform: translateY(-50%);
                content: "\f078";
                font-family: ${theme.fonts.family.fontAwesome};
                font-size: 12px;
                line-height: 0;
                color: #bbb;
              }
            }
            .second {
              display: flex;
              flex: 0 0 30%;
              margin-right: 5px;
            }
            .third {
              display: flex;
              flex: 1;
            }
          }
          input {
            width: 100%;
            height: 40px;
            line-height: 40px;
            padding: 0 10px;
            border: solid 1px #e5e5e5;
            border-radius: 4px;
            overflow: hidden;
            font-size: 14px;
          }
          select {
            width: 100%;
            height: 35px;
            padding: 0 10px;
            border: solid 1px #e5e5e5;
            border-radius: 4px;
            overflow: hidden;
            font-size: 14px;
            appearance: none;
            -webkit-appearance: none; /* 사파리, 크롬 하위버전용 */
            -moz-appearance: none;
            color: #777;
          }
          select::-ms-expand {
            display: none;
          }
        }
      }
      .guest_btn {
        display: flex;
        flex: 0 0 auto;
        justify-content: center;
        margin-top: 20px;
        .link {
          font-size: 14px;
          border-bottom: solid 1px #ddd;
        }
      }
    `
  }}
`

function ReservationCounter({
  activeModal,
  setActiveModal,
  isPay,
  productCount,
  productPrice,
  totalPrice,
  count,
  name,
  minus,
  plus,
  ticketDateTimePeoples,
  handleTicketDateTimeChange,
  onSubmit,
}) {
  return (
    <SCounter
      style={
        activeModal === "Counter" ? { display: "block" } : { display: "none" }
      }
    >
      <form>
        <div className="price_content">
          <div className="form_group">
            <InputGroup>
              <button type={"button"} onClick={minus}>
                <FontAwesomeIcon icon={faMinus} />
              </button>
              <input value={count} readOnly={true} />
              <button type={"button"} onClick={plus}>
                <FontAwesomeIcon icon={faPlus} />
              </button>
            </InputGroup>
          </div>

          <div className="scroll ">
            {ticketDateTimePeoples.map((item, index) => {
              return (
                <div key={index} className="form_group ">
                  <div className="row form_input">
                    {/* <div className="first">
                    <select>
                      <option value="">설정</option>
                    </select>
                  </div> */}
                    <div className="second">
                      <input
                        type="text"
                        name={"name"}
                        placeholder="이름"
                        defaultValue={item.name}
                        onChange={(e) => {
                          handleTicketDateTimeChange(e, index)
                        }}
                        readOnly={index === 0 ? true : false}
                      />
                    </div>
                    <div className="third">
                      <input
                        type="text"
                        name={"phone"}
                        placeholder="휴대전화 ‘-’제외"
                        defaultValue={item.phone}
                        onChange={(e) => {
                          handleTicketDateTimeChange(e, index)
                        }}
                        readOnly={index === 0 ? true : false}
                      />
                    </div>
                  </div>
                </div>
              )
            })}
          </div>

          <div className="price_box">
            <div className="name">{name}</div>
            <RowInfo
              textLeft={"금액"}
              textRight={
                isPay ? (
                  <NumberFormat
                    value={productPrice}
                    displayType={"text"}
                    thousandSeparator={true}
                    suffix="드림"
                  />
                ) : (
                  "무료"
                )
              }
            />
            <RowInfo
              textLeft={"수량(티켓)"}
              textRight={
                <>
                  <FontAwesomeIcon icon={faTimes} color={"#999"} />
                  <b style={{ marginLeft: 5 }}>
                    <NumberFormat
                      value={productCount}
                      displayType={"text"}
                      thousandSeparator={true}
                    />
                  </b>
                  개
                </>
              }
            />
          </div>
          <div className="price_total">
            <RowInfo
              total="total"
              textLeft={"총 금액"}
              textRight={
                <>
                  <b>
                    <NumberFormat
                      value={totalPrice}
                      displayType={"text"}
                      thousandSeparator={true}
                    />
                  </b>
                  드림
                </>
              }
            />
          </div>
        </div>
        <Col className="bottom">
          <Button
            type="button"
            onClick={onSubmit}
            color="primary"
            width="full"
            size="lg"
          >
            다음
          </Button>
        </Col>
      </form>
    </SCounter>
  )
}
export default ReservationCounter
