import { useState } from "react"
import { withRouter } from "react-router-dom"

import { Stepbox } from "../../../../../../styles/styles"
import PresentUser from "./SendUser"
import { ModalLayout } from "../../../../../Layouts/Layout"

function Modal({ history, match, location, isOpen, toggleModal }) {
  const [user, setUser] = useState(null)

  const userAdd = (user) => {
    setUser(user)
  }
  const userRemove = (user) => {
    setUser(null)
  }

  return (
    <ModalLayout
      isOpen={isOpen}
      toggleModal={toggleModal}
      title={"양도 받는분 선택"}
    >
      <Stepbox>
        <PresentUser
          user={user}
          setUser={setUser}
          userAdd={userAdd}
          userRemove={userRemove}
        />
      </Stepbox>
    </ModalLayout>
  )
}

export default withRouter(Modal)
