import React from "react"
import { withRouter } from "react-router-dom"
import { useQuery } from "@apollo/client"
import { ACCOUNT_ORDERS_QUERY } from "../../../Config/Queries"

import { Layout } from "../../../Components/Layouts/Layout"
import PageTitle from "../../../Components/Layouts/PageTitle"
import styled, { css } from "styled-components"
import {
  Ready,
  Container,
  MainTitle,
  MainText,
  ListTitle,
} from "../../../styles/styles"
import Item from "../../../Components/Order/Account/Order/List/Item"
import Pagination from "../../../Components/Pagination"
import SLoading from "../../../Components/Loading"
import qs from "qs"

const SContainer = styled(Container)`
  ${({ theme }) => {
    return css`
      padding: 0 ${theme.paddings.xl};
    `
  }}
`
const SItemlist = styled.div`
  ${({ theme }) => {
    return css`
      ul {
        width: 100%;
        li {
          margin-bottom: ${theme.margins.lg};
          a {
            display: block;
          }
        }
      }
    `
  }}
`

function List({ history, match, location }) {
  const queryString = qs.parse(location.search.substr(1))
  const page = queryString.page ? queryString.page : 1
  const blockSize = 5
  const first = 10
  const skip = first * (page - 1)

  const baseUrl = "?"

  const { data, loading } = useQuery(ACCOUNT_ORDERS_QUERY, {
    fetchPolicy: "network-only",
    variables: {
      orderBy: "id_DESC",
      skip: skip,
      first: first,
    },
  })

  return (
    <>
      <Layout isNav={true} isFooter={true}>
        <PageTitle title="구매내역" />
        <SContainer>
          <MainTitle>
            <MainText>구매한 티켓이</MainText>
            <MainText>
              <span className="TextBlue">
                {!loading && data.TicketAccountOrderList.count}
              </span>
              개 있어요.
            </MainText>
          </MainTitle>
          {!loading ? (
            <>
              {data.TicketAccountOrderList.count > 0 ? (
                <SItemlist>
                  <ul>
                    {data.TicketAccountOrderList.ticketOrders.map(
                      (item, index) => {
                        return (
                          <li key={index}>
                            <Item item={item} />
                          </li>
                        )
                      }
                    )}
                  </ul>
                  <Pagination
                    totalRecord={data.TicketAccountOrderList.count}
                    blockSize={blockSize}
                    pageSize={first}
                    currentPage={page}
                    baseUrl={baseUrl}
                  />
                </SItemlist>
              ) : (
                <Ready>
                  <div>등록된 티켓이 없습니다.</div>
                </Ready>
              )}
            </>
          ) : (
            <SLoading />
          )}
        </SContainer>
      </Layout>
    </>
  )
}

export default withRouter(List)
