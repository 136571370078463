import { useState } from "react"
import { withRouter } from "react-router-dom"

import styled, { css } from "styled-components"
import { Stepbox, SModal } from "../../../../../../styles/styles"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTimes } from "@fortawesome/pro-light-svg-icons"
import PresentUser from "./SendUser"
import { toast } from "react-toastify"
import { ModalLayout } from "../../../../../Layouts/Layout"

const SSModal = styled(SModal)`
  ${({ theme }) => {
    return css`
      .modal {
        display: none;
        position: fixed;
        background: rgba(0, 0, 0, 0.4);
        padding: ${theme.paddings.base};
        top: 0;
        .title {
          font-size: ${theme.fonts.size.sm};
          margin-bottom: ${theme.margins.sm};
        }
        section {
          header {
            position: relative;
            height: 50px;
            display: flex;
            flex-direction: row;
            .left {
              width: 50px;
              display: flex;
            }
            .title {
              flex: 1;
              display: flex;
              height: 50px;
              align-items: center;
              justify-content: center;
              font-size: ${theme.fonts.size.base};
            }
            button {
              display: flex;
              width: 50px;
              height: 50px;
            }
          }
          main {
            display: flex;
            flex-direction: column;
            border-bottom: 1px solid #dee2e6;
            border-top: 1px solid #dee2e6;
          }
        }
      }
      .modal.openModal {
        display: flex;
        align-items: center;
      }
      @keyframes modal-show {
        from {
          margin-top: -100px;
        }
        to {
          margin-top: 0;
        }
      }
    `
  }}
`

function Modal({ history, match, location, isOpen, toggleModal }) {
  const [user, setUser] = useState(null)

  const userAdd = (user) => {
    setUser(user)
  }
  const userRemove = (user) => {
    setUser(null)
  }

  return (
    <ModalLayout
      isOpen={isOpen}
      toggleModal={toggleModal}
      title={"양도 받는분 선택"}
    >
      <Stepbox>
        <PresentUser
          user={user}
          setUser={setUser}
          userAdd={userAdd}
          userRemove={userRemove}
        />
      </Stepbox>
    </ModalLayout>
  )
}

export default withRouter(Modal)
