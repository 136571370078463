import React, { useState, useEffect } from "react"
import { withRouter } from "react-router-dom"
import { useQuery } from "@apollo/client"
import { ORDERINIT_QUERY } from "../../Config/Queries"

import { Layout, ModalLayout } from "../../Components/Layouts/Layout"
import PageTitle from "../../Components/Layouts/PageTitle"
import styled, { css } from "styled-components"
import { useForm } from "react-hook-form"
import Button from "../../Components/Buttons"
import Loading from "../../Components/Loading"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faAngleRight, faTimes } from "@fortawesome/pro-light-svg-icons"
import {
  CheckboxGroup,
  AllCheckerCheckbox,
  Checkbox,
} from "@createnl/grouped-checkboxes"
import { Roundbox, FooterButton, Comment } from "../../styles/styles"
import { graphqlUrl, provision } from "../../Config/Env"
import renderHTML from "react-render-html"
import { toast } from "react-toastify"
import "react-dropdown/style.css"
import Product from "../../Components/Order/Init/Product"

const ProductAgreement = styled.div`
  ${({ theme }) => {
    return css`
      padding: ${theme.paddings.base} ${theme.paddings.xl};
      margin-bottom: ${theme.margins.xl};
      .checkList {
        padding: ${theme.paddings.base} 0;
        ul {
          margin-bottom: ${theme.margins.base};
        }
        li {
          display: flex;
          flex-direction: row;
          margin-bottom: ${theme.margins.base};
          font-size: ${theme.fonts.size.sm};
          :last-child {
            margin-bottom: 0;
          }
          label {
            flex: 1;
            display: flex;
            align-items: center;
            color: #666;
            font-size: 14px;
            input {
              position: relative;
            }
            input[type="checkbox"] {
              display: flex;
              align-items: center;
              justify-content: center;
              width: 24px;
              height: 20px;
              ::before {
                content: "\f00c";
                font-family: ${theme.fonts.family.fontAwesome};
                color: #ccc;
              }
              :checked {
                ::before {
                  color: ${theme.colors.primary};
                }
              }
            }
            span {
              display: flex;
              margin-left: ${theme.margins.sm};
            }
          }
          button {
            background-color: white;
            font-size: ${theme.fonts.size.xs};
            color: #999;
            border-bottom: solid 1px #eee;
            padding: 0;
          }
        }
      }
      .checkAll {
        background-color: ${theme.colors.lightgray};
        position: relative;
        display: flex;
        flex-direction: row;
        border-radius: 8px;
        label {
          flex: 1;
          display: flex;
          align-items: center;
          padding: ${theme.paddings.base};
          font-weight: ${theme.fonts.weight.medium};
          font-size: ${theme.fonts.size.lg};
          input[type="checkbox"] {
            background-color: white;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 24px;
            height: 24px;
            border-radius: 50%;
            border: solid 1px #e5e5e5;
            ::before {
              color: transparent;
              font-size: ${theme.fonts.size.base};
            }
            :checked {
              background-color: ${theme.colors.primary};
              border: solid 1px ${theme.colors.primary};
              ::before {
                content: "\f00c";
                font-family: ${theme.fonts.family.fontAwesome};
                color: white;
              }
            }
          }
          span {
            display: flex;
            margin-left: 1rem;
          }
        }
      }
    `
  }}
`
const ProvisionBox = styled.div`
  ${({ theme }) => {
    return css`
      height: 100%;
      overflow-x: auto;
      padding: 15px;
      font-size: ${theme.fonts.size.sm};
      color: ${theme.colors.black02};
    `
  }}
`

function Init({ history, match, location }) {
  const orderType = location?.state?.orderType
  const [users, setUsers] = useState(
    location?.state?.users ? location?.state?.users : []
  )
  const id = location?.state?.id
  const ticketProductDate = location?.state?.ticketProductDate
  const ticketProductDateTime = location?.state?.ticketProductDateTime
  const ticketDateTimePeoples = location?.state?.ticketDateTimePeoples
  const count = location?.state?.count

  const [allagreed, setAllagreed] = useState(false)

  const { data, loading, error } = useQuery(ORDERINIT_QUERY, {
    fetchPolicy: "network-only",
    variables: {
      id,
    },
  })

  const { register, handleSubmit } = useForm()

  const onCheckboxChange = (checkboxes) => {
    const result = checkboxes.filter((item) => item.checked === false)

    setAllagreed(result.length <= 0)
  }

  const onSubmit = async (data) => {
    try {
      if (!allagreed) {
        toast.error("약관에 동의해 주세요.")
        return
      }

      history.replace(`/order/authPassword`, {
        orderType: orderType,
        users,
        id,
        ticketProductDate,
        ticketProductDateTime,
        ticketDateTimePeoples,
        count,
        message: data.message,
      })

      return
    } catch (e) {
      console.log(e)
      var error = e.toString()
      error = error.replace("Error: GraphQL error:", "")
      toast.error(error)
    }
  }

  const [provisionSelecter, setProvisionSelecter] = useState("OrderAgree")
  const [provisionModalIsOpen, setProvisionModalIsOpen] = useState(false)
  const provisionModalToggle = () => {
    setProvisionModalIsOpen(!provisionModalIsOpen)
  }

  const handler = (event) => {
    event.preventDefault()
    event.returnValue = "페이지를 이동하시겠습니까?"
  }
  useEffect(() => {
    window.addEventListener("beforeunload", handler)
    return () => {
      window.removeEventListener("beforeunload", handler)
    }
  }, [])

  useEffect(() => {
    if (!loading) {
      if (orderType === "me") {
        const user = data.TicketOrderInit.account
        setUsers([
          {
            isMember: true,
            id: user.id,
            userId: user.userId,
            name: user.name,
            phone: user.phone,
            count: ticketDateTimePeoples.length,
          },
        ])
      }
    }
  }, [data, loading, orderType])
  useEffect(() => {
    if (!loading && error) {
      toast.error("티켓선택 후 구입할 수 있습니다.")
      history.push(`/home`)
      return
    }
  }, [loading, error, history])

  if (!loading && !error) {
    const {
      TicketOrderInit: { ticketProduct, account },
    } = data
    const trustline = account.trustlines.find(
      (trustline) => trustline.currency === "DRM"
    )
    const totalAmount = account.isCms
      ? trustline.amount + account.cmsValue
      : trustline.amount
    const totalPrice = ticketProduct.totalPrice * count
    return (
      <Layout isNav={false} isFooterButton={true}>
        <PageTitle title="선물하기" />
        <form onSubmit={handleSubmit(onSubmit)}>
          <Comment style={orderType === "me" ? { display: "none" } : {}}>
            <h4>
              {users[0]?.name}님
              {renderHTML(
                users.length > 1 ? `외 <b>${users.length - 1}</b>명` : ``
              )}
              에게 주는 티켓
            </h4>
            <div className="massge_write">
              <textarea
                name="message"
                placeholder="메시지를 입력해주세요."
                ref={register()}
              ></textarea>
            </div>
          </Comment>

          <Product
            orderType={orderType}
            users={users}
            ticketProductDate={ticketProductDate}
            ticketProductDateTime={ticketProductDateTime}
            count={count}
            data={data}
          />

          <ProductAgreement>
            <CheckboxGroup onChange={onCheckboxChange}>
              <div className="checkAll">
                <label>
                  <AllCheckerCheckbox />
                  <span>전체 동의하기</span>
                </label>
              </div>
              <div className="checkList">
                <ul>
                  <li>
                    <label>
                      <Checkbox />
                      <span>이용약관 동의</span>
                    </label>
                    <button
                      type="button"
                      color="white"
                      size="xs"
                      border="radius"
                      onClick={() => {
                        setProvisionSelecter("Provision")
                        provisionModalToggle()
                      }}
                    >
                      내용보기
                    </button>
                  </li>
                  <li>
                    <label>
                      <Checkbox />
                      <span>개인정보 제3자 제공 동의</span>
                    </label>
                    <button
                      type="button"
                      color="white"
                      size="xs"
                      border="radius"
                      onClick={() => {
                        setProvisionSelecter("Privacy")
                        provisionModalToggle()
                      }}
                    >
                      내용보기
                    </button>
                  </li>
                  <li>
                    <label>
                      <Checkbox />
                      <span>상품구매조건 확인 및 환불규정 동의</span>
                    </label>
                    <button
                      type="button"
                      color="white"
                      size="xs"
                      border="radius"
                      onClick={() => {
                        setProvisionSelecter("OrderAgree")
                        provisionModalToggle()
                      }}
                    >
                      내용보기
                    </button>
                  </li>
                </ul>
              </div>
            </CheckboxGroup>
          </ProductAgreement>
          {totalPrice > totalAmount ? (
            <FooterButton>
              <div className="inner">
                <Button color="lightdanger" width="full" disabled>
                  결제금액부족{account.isCms ? "(땡겨쓴돈 초과)" : ""}
                </Button>
              </div>
            </FooterButton>
          ) : (
            <FooterButton>
              <div className="inner">
                <Button type="submit" color="primary" width="full">
                  다음
                </Button>
              </div>
            </FooterButton>
          )}

          <ModalLayout
            isOpen={provisionModalIsOpen}
            toggleModal={provisionModalToggle}
            title={provision[provisionSelecter].title}
          >
            <ProvisionBox>{provision[provisionSelecter].content}</ProvisionBox>
          </ModalLayout>
        </form>
      </Layout>
    )
  } else {
    return <Loading />
  }
}

export default withRouter(Init)
