import React from "react"
import { withRouter, Link } from "react-router-dom"

import styled, { css } from "styled-components"
import { Row, Col, SOrderItem, Dot } from "../../../../../styles/styles"
import ReactMoment from "react-moment"
import moment from "moment"
import { graphqlUrl } from "../../../../../Config/Env"
import Label from "../../../../Labels"

import {
  dayOfWeekName,
  isPayName,
  placeTypeName,
  datesCounter,
} from "../../../../../Config/Util"

const SSOrderItem = styled(SOrderItem)`
  ${({ theme }) => {
    return css`
      .product_list {
        border-radius: 5px;
      }
      .state {
        font-weight: bold;
      }
    `
  }}
`

function Item({ history, match, location, item }) {
  const CoverImage =
    item.ticketProduct.coverImage === null
      ? "/assets/img/product/product-default.jpg"
      : `${graphqlUrl}${item.ticketProduct.coverImage}`

  const startDate =
    item.isReservation === true
      ? new Date(item.ticketProductDateTime?.ticketDateTime)
      : new Date(item.ticketProduct.applyStartDateTime)
  const endDate =
    item.isReservation === true
      ? moment(item.ticketProductDateTime?.ticketDateTime).add(
          parseInt(item.ticketProduct.minute),
          "minutes"
        )
      : new Date(item.ticketProduct.applyEndDateTime)

  const createdAtAfterCount = Math.ceil(
    datesCounter(new Date(item.createdAt), new Date())
  )
  const reservationBeforCount = Math.ceil(
    datesCounter(
      new Date(),
      new Date(item.ticketProductDateTime?.ticketDateTime)
    )
  )

  return (
    <>
      <SSOrderItem>
        <div className="item_content">
          <div className="product_list">
            <div className="img ">
              <img src={CoverImage} alt={item.ticketProduct.name} />
              {item.commonStatus === "S" && (
                <>
                  {new Date(item.ticketProduct?.applyEndDateTime) <=
                  new Date() ? (
                    <>
                      <div className="complete_icon">
                        <div>
                          예매기간
                          <br />
                          만료
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      {item.ticketProductDateTime?.ticketDateTime &&
                        new Date(item.ticketProductDateTime?.ticketDateTime) <=
                          new Date() && (
                          <div className="complete_icon">
                            <div>
                              예매기간
                              <br />
                              만료
                            </div>
                          </div>
                        )}
                    </>
                  )}
                </>
              )}
            </div>
            <div className="cont">
              <Row className="info">
                <span>
                  {/* {createdAtAfterCount} {reservationBeforCount}{" "}
                  {item.isReservation === true ? "예매" : "미예매"}{" "} */}
                  {item.ticketProduct.ticketCategory.name}
                  {item.ticketProduct.ticketCategory.parentCategory
                    ? ` > ${item.ticketProduct.ticketCategory.parentCategory.name}`
                    : ""}
                </span>
                <Dot />
                <span>
                  <ReactMoment format="YYYY.MM.DD ">
                    {item.createdAt}
                  </ReactMoment>{" "}
                </span>
              </Row>
              <strong>{item.ticketProduct.name}</strong>
              {item.isReservation === true && (
                <div className="date">
                  <ReactMoment format="YYYY.MM.DD">{startDate}</ReactMoment>(
                  {dayOfWeekName(startDate.getDay())}){" "}
                  <ReactMoment format="HH:mm">{startDate}</ReactMoment> ~{" "}
                  <ReactMoment format="HH:mm">{endDate}</ReactMoment>
                </div>
              )}
              {item.isReservation === false && (
                <div className="date">
                  <ReactMoment format="YYYY.MM.DD">{startDate}</ReactMoment>(
                  {dayOfWeekName(startDate.getDay())}) ~{" "}
                  <ReactMoment format="YYYY.MM.DD">{endDate}</ReactMoment>(
                  {dayOfWeekName(endDate.getDay())})
                </div>
              )}
            </div>
          </div>
        </div>
        <Row className="item_bottom">
          <Col left className="left">
            <span>구매자. {item.user.name}</span>
          </Col>
          <Col right className="right">
            {item.commonStatus === "S" && (
              <>
                <span>
                  <b>{item.count}</b>개
                </span>
                <Dot />
                {new Date(item.ticketProduct?.applyEndDateTime) <=
                new Date() ? (
                  <>
                    <Label color={"lightgray"} bg={"none"}>
                      예매기간 만료
                    </Label>
                  </>
                ) : (
                  <>
                    {item.ticketProductDateTime?.ticketDateTime &&
                    new Date(item.ticketProductDateTime?.ticketDateTime) <=
                      new Date() ? (
                      <>
                        <Label color={"lightdanger"} bg={"none"}>
                          예매일 만료
                        </Label>
                      </>
                    ) : (
                      <>
                        {item.isReservation === true && (
                          <Label color={"lightprimary"} bg={"none"}>
                            예매완료
                          </Label>
                        )}
                        {item.isReservation === false && (
                          <Label color={"lightgray"} bg={"none"}>
                            예매대기
                          </Label>
                        )}
                      </>
                    )}
                  </>
                )}
              </>
            )}
            {item.commonStatus === "C" && (
              <>
                {item.isRefund === true && (
                  <>
                    <Label color={"lightdanger"} bg={"none"}>
                      반환완료
                    </Label>
                  </>
                )}
                {item.isRefund === false && (
                  <>
                    <Label color={"lightprimary"} bg={"none"}>
                      사용완료
                    </Label>
                  </>
                )}
              </>
            )}
          </Col>
        </Row>
      </SSOrderItem>
    </>
  )
}
export default withRouter(Item)
