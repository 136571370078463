import React from "react"
import { withRouter } from "react-router-dom"

import styled, { css } from "styled-components"
import { SOrderItem, SOrderInfo } from "../../../styles/styles"
import Alter from "../../Alter"
import ReactMoment from "react-moment"
import moment from "moment"
import NumberFormat from "react-number-format"
import { graphqlUrl } from "../../../Config/Env"
import Labels from "../../Labels"
import { dayOfWeekName } from "../../../Config/Util"
import RowInfo from "../../RowInfo"

const SSOrderItem = styled(SOrderItem)`
  ${({ theme }) => {
    return css`
      padding: ${theme.paddings.base} ${theme.paddings.xl};
      border: 0;
      box-shadow: none;
      .product_list {
        border: 1px solid #eee;
        border-radius: 8px;
        .img {
          border-top-left-radius: 8px !important;
          border-bottom-left-radius: 8px !important;
          border-radius: 0px;
          width: 100px;
          height: 100px;
        }
      }
    `
  }}
`
const SSOrderInfo = styled(SOrderInfo)`
  ${({ theme }) => {
    return css`
      padding: 0 ${theme.paddings.xl} 0;
    `
  }}
`

function Product({
  history,
  match,
  location,
  orderType,
  users,
  ticketProductDate,
  ticketProductDateTime,
  count,

  data,
}) {
  const {
    TicketOrderInit: { ticketProduct, account },
  } = data

  const CoverImage =
    ticketProduct.coverImage === null
      ? "/assets/img/product/product-default.jpg"
      : `${graphqlUrl}${ticketProduct.coverImage}`

  let productPrice = ticketProduct.totalPrice

  const trustline = account.trustlines.find(
    (trustline) => trustline.currency === "DRM"
  )
  const totalAmount = account.isCms
    ? trustline.amount + account.cmsValue
    : trustline.amount
  const totalPrice =
    (ticketProduct.isDiscount
      ? ticketProduct.price - ticketProduct.discountPrice
      : ticketProduct.price) *
    count *
    users.length
  const startDate =
    orderType === "me" && new Date(ticketProductDateTime.ticketDateTime)
  const endDate =
    orderType === "me" &&
    moment(ticketProductDateTime.ticketDateTime).add(
      parseInt(ticketProduct.minute),
      "minutes"
    )

  return (
    <>
      <SSOrderItem>
        <div className="product_list">
          <div className="img">
            <img src={CoverImage} alt={ticketProduct.name} />
          </div>
          <div className="cont">
            <span className="category">{ticketProduct.store.name}</span>
            <strong>{ticketProduct.name}</strong>
            {orderType === "me" && (
              <div className="option">
                <ReactMoment format="YYYY.MM.DD">{startDate}</ReactMoment>(
                {dayOfWeekName(startDate.getDay())})
                <ReactMoment format="HH:mm">{startDate}</ReactMoment> ~{" "}
                <ReactMoment format="HH:mm">{endDate}</ReactMoment>
              </div>
            )}
          </div>
        </div>
      </SSOrderItem>
      <SSOrderInfo>
        <RowInfo
          textLeft={"보유금액"}
          border={"bottom"}
          textRight={
            <>
              {trustline.amount < 0 && (
                <Labels
                  color="yellow"
                  border="radius"
                  style={{ marginRight: 5 }}
                >
                  <b>땡겨쓴돈</b>
                </Labels>
              )}
              <b>
                <NumberFormat
                  value={Math.abs(trustline.amount)}
                  displayType={"text"}
                  thousandSeparator={true}
                />
              </b>
              드림
            </>
          }
        />
        <RowInfo
          style={orderType === "me" ? { display: "none" } : {}}
          textLeft={"수신자"}
          border={"bottom"}
          textRight={
            <>
              <b>{users.length}</b>명
            </>
          }
        />
        <RowInfo
          textLeft={"금액"}
          textRight={
            <>
              {ticketProduct.isPay ? (
                <>
                  <b>
                    <NumberFormat
                      value={productPrice}
                      displayType={"text"}
                      thousandSeparator={true}
                    />
                  </b>
                  드림
                </>
              ) : (
                `무료`
              )}
            </>
          }
        />
        <RowInfo
          textLeft={"수량"}
          border={"bottom"}
          textRight={
            <>
              <b>{count}</b>개
            </>
          }
        />
        <RowInfo
          textLeft={"결제금액"}
          border={"bottom"}
          textRight={
            <>
              <b>
                <NumberFormat
                  value={productPrice * count}
                  displayType={"text"}
                  thousandSeparator={true}
                />
              </b>
              드림
            </>
          }
        />
        <RowInfo
          textLeft={"결제 후 잔액"}
          border={"bottom"}
          total={"total"}
          textRight={
            <>
              {trustline.amount - productPrice * count < 0 && (
                <Labels
                  color="yellow"
                  border="radius"
                  style={{ marginRight: 5 }}
                >
                  <b>땡겨쓴돈</b>
                </Labels>
              )}
              <b>
                <NumberFormat
                  value={Math.abs(trustline.amount - productPrice * count)}
                  displayType={"text"}
                  thousandSeparator={true}
                />
              </b>
              드림
            </>
          }
        />
        <Alter border="radius" style={{ marginTop: 16 }}>
          <strong>땡겨쓴돈 안내</strong>
          <p>
            ① 정기충전회원분들은 매월 약정하신 금액에서 땡겨쓰기가 가능합니다.
          </p>
          <p>② 차월 정기충전일시에 맞추어 차감되어 충전됩니다.</p>
          <p>③ 정기충전이 아닌 분들은 땡겨쓰기 기능 사용이 불가능합니다.</p>
          <p>
            ex) 10만원 CMS 정기충전자가 9만드림를 '땡겨쓰기'를 했을 경우, 다음
            달 출금에서 9만드림 차감 후, 1만 드림만 충전
          </p>
        </Alter>
      </SSOrderInfo>
    </>
  )
}

export default withRouter(Product)
