import React from "react"
import styled, { css } from "styled-components"

const colorStyles = css`
  ${({ theme, color }) => {
    const selected = theme.colors[color]
    return css`
      background: ${selected};

      ${(props) =>
        props.color === "white" &&
        css`
          border: solid 1px #ddd;
          color: #555;
        `}
      ${(props) =>
        props.color === "yellow" &&
        css`
          color: #333 !important;
        `}
              ${(props) =>
        props.color === "lightgray" &&
        css`
          color: ${theme.colors.black04}!important;
        `}
            ${(props) =>
        props.color === "lightprimary" &&
        css`
          color: ${theme.colors.primary}!important;
        `}
            ${(props) =>
        props.color === "primary" &&
        css`
          color: ${theme.colors.white};
          &:active,
          &:hover {
            background: #13249a !important;
            color: ${theme.colors.white}!important;
          }
        `}
            ${(props) =>
        props.color === "warning" &&
        css`
          color: ${theme.colors.white}!important;
          &:active,
          &:hover {
            background: ${theme.colors.warning}!important;
            color: ${theme.colors.white}!important;
          }
        `}
            ${(props) =>
        props.color === "lightwarning" &&
        css`
          color: ${theme.colors.warning}!important;
          &:active,
          &:hover {
            background: ${theme.colors.lightwarning}!important;
            color: ${theme.colors.white}!important;
          }
        `}
            ${(props) =>
        props.color === "danger" &&
        css`
          color: ${theme.colors.white}!important;
          &:active,
          &:hover {
            background: #f46969 !important;
            color: ${theme.colors.white};
          }
        `}
            ${(props) =>
        props.color === "lightdanger" &&
        css`
          color: ${theme.colors.danger}!important;
          &:active,
          &:hover {
            background: ${theme.colors.danger}!important;
            color: ${theme.colors.black01}!important;
          }
        `}
    `
  }}
`
const borderStyles = css`
  ${({ theme, color }) => {
    const selected = theme.colors[color]
    return css`
      ${(props) =>
        props.border === "solid" &&
        css`
          background-color: white;
          border: solid 1px ${selected};
          color: ${selected};
        `}
      ${(props) =>
        props.border === "radius" &&
        css`
          border-radius: 3rem;
        `}
    `
  }}
`

const StyledLabel = styled.span`
  ${({ theme }) => {
    return css`
      padding: 5px 8px;
      font-family: ${theme.fonts.family.base};
      font-size: ${theme.fonts.size.sm};
      font-weight: ${theme.fonts.weight.medium};
      ${(props) =>
        props.bg === "none" &&
        css`
          background-color: transparent !important;
          border-color: transparent !important;
          padding: 0;
        `}
    `
  }}
  /* 공통 스타일 */
  border-radius: 3px;
  display: inline-flex;
  line-height: 1.2;

  /* 색상 */
  ${colorStyles}

  /*스타일 */
    ${borderStyles}
`

function Labels({ children, color, border, bg, ...rest }) {
  return (
    <StyledLabel border={border} color={color} bg={bg} {...rest}>
      {children}
    </StyledLabel>
  )
}
Labels.defaultProps = {
  color: "white",
}

export default Labels
