import { useState } from "react"
import { withRouter } from "react-router-dom"
import { useMutation } from "@apollo/client"
import { PRODUCTORDER_PUSH_MUTATION } from "../../../../../Config/Queries"

import styled, { css } from "styled-components"
import { Comment, Col } from "../../../../../styles/styles"
import { useForm } from "react-hook-form"
import FormError from "../../../../FormError"
import Button from "../../../../Buttons"
import PushUser from "./PushUser"
import { toast } from "react-toastify"
import { ModalLayout } from "../../../../Layouts/Layout"

function Modal({ history, match, location, isOpen, toggleModal, id }) {
  const [users, setUsers] = useState([])
  const [selectUsers, setSelectUsers] = useState([])

  const userSelect = (user) => {
    const userIds =
      users.indexOf(user.id) > -1
        ? [...users.filter((item) => item !== user.id)]
        : [...users, user.id]
    const userObjects =
      users.indexOf(user.id) > -1
        ? [...selectUsers.filter((item) => item.id !== user.id)]
        : [...selectUsers, user]

    setUsers(userIds)
    setSelectUsers(userObjects)
  }
  const userUnSelect = (user) => {
    const userIds = [...users.filter((item) => item !== user.id)]
    const userObjects = [...selectUsers.filter((item) => item.id !== user.id)]

    setUsers(userIds)
    setSelectUsers(userObjects)
  }

  const [productOrderPushMutation] = useMutation(PRODUCTORDER_PUSH_MUTATION)

  const { register, handleSubmit, errors, setValue } = useForm({
    mode: "onChange",
  })

  const onSubmit = async (data) => {
    try {
      if (users.length === 0) {
        toast.error(`선물 받는분을 추가해주세요.`)
        return
      }
      if (data.message === "") {
        toast.error("메시지를 입력해주세요.")
        return
      }
      const {
        data: { TicketProductOrderPush },
      } = await productOrderPushMutation({
        variables: {
          users: users,
          id: id,
          message: data.message,
        },
      })

      if (TicketProductOrderPush) {
        toast.success("쪼르기 메시지가 전달되었습니다.")
        setUsers([])
        setSelectUsers([])
        setValue("message", "")
        toggleModal()
        return
      }

      //   history.replace(`/order/authPassword`, {
      //     users,
      //     id,
      //     message: data.message,
      //   });

      return
    } catch (e) {
      console.log(e)
      var error = e.toString()
      error = error.replace("Error: GraphQL error:", "")
      toast.error(error)
    }
  }

  return (
    <ModalLayout isOpen={isOpen} toggleModal={toggleModal} title={"쪼르기"}>
      <PushUser
        users={users}
        setUsers={setUsers}
        selectUsers={selectUsers}
        setSelectUsers={setSelectUsers}
        userSelect={userSelect}
        userUnSelect={userUnSelect}
      />
      <form onSubmit={handleSubmit(onSubmit)}>
        <Comment>
          <h4>메시지 입력</h4>
          <div className="massge_write">
            <textarea
              name="message"
              placeholder="메시지를 입력해주세요."
              ref={
                register()
                //   { required: "메시지를 입력해주세요." }
              }
            ></textarea>
          </div>
          <FormError message={errors?.message?.message} />
        </Comment>
        <div
          style={{
            paddingLeft: 24,
            paddingRight: 24,
            paddingTop: 12,
            paddingBottom: 12,
          }}
        >
          <Button type="submit" color="primary" width="full" size={"lg"}>
            보내기
          </Button>
        </div>
      </form>
    </ModalLayout>
  )
}

export default withRouter(Modal)
