import React from "react"
import { withRouter } from "react-router-dom"
import { useQuery } from "@apollo/client"
import { ACCOUNT_QUERY, ACCOUNT_WISHLISTS_QUERY } from "../../Config/Queries"

import { Layout } from "../../Components/Layouts/Layout"
import PageTitle from "../../Components/Layouts/PageTitle"
import styled, { css } from "styled-components"
import { Ready, Container, MainTitle, MainText } from "../../styles/styles"
import Item from "../../Components/Wishlist/Account/Wishlist/Item"
import Pagination from "../../Components/Pagination"
import SLoading from "../../Components/Loading"
import qs from "qs"

const SContainer = styled(Container)`
  ${({ theme }) => {
    return css`
      padding: 0 ${theme.paddings.xl};
    `
  }}
`
const SItemlist = styled.div`
  ${({ theme }) => {
    return css`
      li {
        margin-bottom: ${theme.margins.base};
      }
    `
  }}
`

function Wishlist({ history, match, location }) {
  const queryString = qs.parse(location.search.substr(1))
  const page = queryString.page ? queryString.page : 1
  const blockSize = 5
  const first = 12
  const skip = first * (page - 1)

  const baseUrl = "?"

  const { data, loading } = useQuery(ACCOUNT_WISHLISTS_QUERY, {
    fetchPolicy: "network-only",
    variables: {
      orderBy: "id_DESC",
      skip,
      first,
    },
  })

  const Title = () => {
    const { data, loading } = useQuery(ACCOUNT_QUERY, {
      fetchPolicy: "network-only",
    })

    if (!loading) {
      const {
        TicketAccountUser: {
          user,
          ticketOrderProductsCount,
          ticketOrdersCount,
          ticketWishlistsCount,
        },
      } = data
      return (
        <MainTitle>
          <MainText>
            <span className="TextBlue">{user.name}님</span>의
          </MainText>
          <MainText>관심티켓 입니다.</MainText>
        </MainTitle>
      )
    } else {
      return (
        <>
          <SLoading />
        </>
      )
    }
  }

  return (
    <>
      <Layout isNav={true} isFooter={true}>
        <PageTitle title="위시리스트" />
        <SContainer>
          <Title />
          {!loading ? (
            <>
              {data.TicketAccountWishlistList.count > 0 ? (
                <>
                  <SItemlist>
                    {data.TicketAccountWishlistList.ticketWishlists.map(
                      (item, index) => {
                        return <Item item={item} />
                      }
                    )}
                  </SItemlist>
                  <Pagination
                    totalRecord={data.TicketAccountWishlistList.count}
                    blockSize={blockSize}
                    pageSize={first}
                    currentPage={page}
                    baseUrl={baseUrl}
                  />
                </>
              ) : (
                <Ready>
                  <div>등록된 티켓이 없습니다.</div>
                </Ready>
              )}
            </>
          ) : (
            <SLoading />
          )}
        </SContainer>
      </Layout>
    </>
  )
}

export default withRouter(Wishlist)
