import styled, { css } from "styled-components"
import { withRouter } from "react-router-dom"
import {
  Roundbox,
  Col,
  Select,
  InputGroup,
  UserList,
} from "../../../../../styles/styles"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPlus, faMinus, faTimes } from "@fortawesome/pro-regular-svg-icons"
import Button from "../../../../Buttons"
import { graphqlUrl } from "../../../../../Config/Env"
import NumberFormat from "react-number-format"

const SInputGroup = styled(InputGroup)`
  ${({ theme }) => {
    return css`
      flex: 0;
      input {
        width: 80px;
      }
    `
  }}
`
const SPresentCounter = styled.div`
  ${({ theme }) => {
    return css`
      .price_top {
        border-bottom: solid 5px #eee;
        padding: ${theme.paddings.base} ${theme.paddings.xl} 0;
      }
      .title {
        font-size: ${theme.fonts.size.sm};
        margin-bottom: ${theme.margins.sm};
      }
      .price_content {
        padding: ${theme.paddings.base} ${theme.paddings.xl};
        border-radius: 5px;
        .name {
          margin-bottom: ${theme.margins.sm};
          font-size: ${theme.fonts.size.lg};
        }
        .flextext {
          flex: 1;
          background-color: ${theme.colors.lightgray};
          border: solid 1px #eee;
          border-top-right-radius: 5px;
          border-top-left-radius: 5px;
          padding: ${theme.paddings.base};
          ul {
            li {
              display: flex;
              flex-direction: row;
              padding: ${theme.paddings.xs} 0;
              .left {
                flex: 1;
                font-size: 15px;
                color: ${theme.colors.black03};
                font-weight: ${theme.fonts.weight.medium};
              }
              .right {
                flex: 1;
                text-align: right;
                justify-content: flex-end;
                font-size: 15px;
                color: ${theme.colors.black02};
              }
            }
          }
        }
        .price_total {
          display: flex;
          flex-direction: row;
          background-color: white;
          border: solid 1px #eee;
          padding: ${theme.paddings.base};
          font-size: ${theme.fonts.size.base};
          justify-content: space-between;
          text-align: right;
          color: #444;
          border-bottom-right-radius: 5px;
          border-bottom-left-radius: 5px;
          b {
            padding-left: 0.5rem;
            font-weight: bold;
          }
        }
      }
      .bottom {
        padding: ${theme.paddings.base} ${theme.paddings.xl};
      }
      .check_list {
        margin-top: ${theme.margins.xs};
        overflow-x: scroll;
        overflow-y: hidden;
        white-space: nowrap;
        ::-webkit-scrollbar {
          display: none;
        }
        display: flex;
        flex-direction: row;
        li {
          display: flex;
          flex-direction: column;
          padding: ${theme.paddings.base};
          align-items: center;
          justify-content: center;
          p {
            margin-top: ${theme.margins.xs};
            font-size: ${theme.fonts.size.sm};
            font-weight: normal;
            color: ${theme.colors.black01};
          }
          .close {
            background: rgba(0, 0, 0, 0.5);
            position: absolute;
            z-index: 11;
            top: -7px;
            right: -7px;
            display: flex;
            width: 12px;
            height: 12px;
            border-radius: 16px;
            align-items: center;
            justify-content: center;
            :hover {
              background: rgba(0, 0, 0, 0.6);
            }
            ::before {
              content: "\f00d";
              font-family: ${theme.fonts.family.fontAwesomeL};
              font-size: 13px;
              line-height: 0;
              color: #fff;
            }
          }
        }
        .check_list_done {
          flex: 1;
          display: flex;
          flex-direction: row;
          align-items: center;
          p {
            margin: 0 0 0 0.5rem;
            font-size: 1.3rem;
            font-weight: normal;
            color: #777;
          }
        }
      }
    `
  }}
`
const SUserList = styled(UserList)`
  ${({ theme }) => {
    return css`
      border-bottom: 0;
      padding: 0;
      ul {
        padding: 0;
        height: 150px;
        overflow-x: hidden;
        overflow-y: scroll;
        white-space: nowrap;
        flex-direction: column;
        ::-webkit-scrollbar {
          display: none;
        }
        li {
          display: flex;
          flex-direction: row;
          padding: ${theme.paddings.sm} 0;
          align-items: center;
          justify-content: center;
          border-bottom: solid 1px #eee;
          .left {
            flex: 1;
            font-size: ${theme.fonts.size.sm};
            color: ${theme.colors.black01};
            span {
              display: block;
              color: ${theme.colors.black04};
              font-weight: ${theme.fonts.weight.base};
            }
          }
          .right {
            width: 160px;
            text-align: right;
            justify-content: flex-end;
          }
        }
      }
    `
  }}
`
function PresentCounter({
  history,
  match,
  location,
  users,
  activeModal,
  isPay,
  name,
  price,
  totalCount,
  totalPrice,
  minus,
  plus,
  onSubmit,
}) {
  return (
    <SPresentCounter
      style={
        activeModal === "Counter" ? { display: "block" } : { display: "none" }
      }
    >
      <div className="price_top">
        <SUserList>
          <ul>
            {users.length > 0 ? (
              <>
                {users.map((item, index) => {
                  const avatarImage =
                    item.avatar === ""
                      ? "/assets/img/product/product-default.jpg"
                      : `${graphqlUrl}${item.avatar}`
                  return (
                    <li key={index}>
                      <div className="left">
                        {item.isMember ? (
                          <>
                            <b>{item.name}님</b>
                            <span>{item.userId}</span>
                          </>
                        ) : (
                          <>
                            <b>{item.name}님</b>
                            <span>{item.phone}</span>
                          </>
                        )}
                      </div>
                      <div className="right">
                        <SInputGroup>
                          <button type="button" onClick={() => minus(index)}>
                            <FontAwesomeIcon icon={faMinus} />
                          </button>
                          <input value={item.count} readOnly={true} />
                          <button type="button" onClick={() => plus(index)}>
                            <FontAwesomeIcon icon={faPlus} />
                          </button>
                        </SInputGroup>
                      </div>
                    </li>
                  )
                })}
              </>
            ) : (
              <li className="check_list_done">
                <Roundbox>
                  <div className="icon"></div>
                </Roundbox>
                <div className="cont">
                  <p>수신자를 검색해주세요.</p>
                </div>
              </li>
            )}
          </ul>
        </SUserList>
      </div>
      <div className="price_content">
        <div className="flextext">
          <div className="name">{name}</div>
          <ul>
            <li>
              <div className="left">금액</div>
              <div className="right">
                {isPay ? (
                  <NumberFormat
                    value={price}
                    displayType={"text"}
                    thousandSeparator={true}
                    suffix="드림"
                  />
                ) : (
                  "무료"
                )}
              </div>
            </li>
            <li>
              <div className="left">수량</div>
              <div className="right">
                <FontAwesomeIcon icon={faTimes} color={"#999"} />{" "}
                <b>
                  <NumberFormat
                    value={totalCount}
                    displayType={"text"}
                    thousandSeparator={true}
                  />
                </b>
                개
              </div>
            </li>
          </ul>
        </div>
        <div className="price_total">
          <div className="left">총 금액</div>
          <div className="right">
            <b>
              <NumberFormat
                value={totalPrice}
                displayType={"text"}
                thousandSeparator={true}
              />
            </b>
            드림
          </div>
        </div>
      </div>
      <Col className="bottom">
        <Button onClick={onSubmit} color="primary" width="full" size="lg">
          선물하기
        </Button>
      </Col>
    </SPresentCounter>
  )
}

export default withRouter(PresentCounter)
