import React, { useEffect, useState, Fragment } from "react"
import { withRouter } from "react-router-dom"
import { useQuery } from "@apollo/client"
import { CATEGORIES_QUERY } from "../../../../Config/Queries"

import styled, { css } from "styled-components"
import qs from "qs"

import { isPayName } from "../../../../Config/Util"
import { faAngleDown, faAngleUp } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const SOptionTab = styled.div`
  ${({ theme }) => {
    return css`
      border-bottom: solid 1px #eee;
      .nav {
        display: flex;
        flex-direction: row;
        li {
          background-color: #fff;
          position: relative;
          display: flex;
          border-right: solid 1px #eee;
          flex: 1 0 50%;
          :first-child {
            border-right: solid 1px #f5f5f5;
          }
          button {
            display: flex;
            align-items: center;
            justify-content: space-between;
            background-color: #fff;
            padding: 0 15px;
            width: 100%;
            height: 40px;
            line-height: 40px;
            color: ${theme.colors.black04};
            font-size: 14px;
            font-weight: ${theme.fonts.weight.medium};
            text-align: left;
            &.active {
              background-color: #f5f5f5;
              color: ${theme.colors.black01};
              font-weight: ${theme.fonts.weight.bold};
            }
          }
        }
      }
      .tabpane {
        display: none;
        &.open {
          display: block;
        }
      }

      .redo-btn {
        background-color: #fff;
        border: solid 1px #eee;
        border-radius: 20px;
        color: ${theme.colors.black02};
        padding: ${theme.paddings.base};
        font-weight: ${theme.fonts.weight.medium};
        svg {
          margin-right: 5px;
        }
      }
    `
  }}
`
const InputGroup = styled.div`
  ${({ theme }) => {
    return css`
      display: flex;
      flex-direction: row;
      padding: 10px 5px;
      .form-control {
        background-color: white;
        width: 1%;
        height: 40px;
        border: solid 1px #eee;
        border-radius: 5px;
        font-size: 14px;
        flex: 1 0 auto;
        padding: 0 8px;
      }
      span {
        flex: 0 0 auto;
        margin: 0 5px;
        height: 40px;
        line-height: 40px;
        font-size: 18px;
        color: ${theme.colors.black04};
        text-align: center;
      }
      button {
        flex: 0 0 auto;
        margin-left: 5px;
        height: 40px;
        border-radius: 5px;
        color: ${theme.colors.black01};
      }
    `
  }}
`
const SCategory = styled.div`
  ${({ theme }) => {
    return css`
      background-color: ${theme.colors.lightgray};
      padding: 15px ${theme.paddings.lg};
      ul {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        li {
          display: flex;
          flex: 0 0 33.3%;
          padding: 0 ${theme.paddings.xs};
          margin-bottom: ${theme.margins.base};
          button {
            display: flex;
            padding: ${theme.paddings.sm};
            background-color: white;
            border: solid 1px #eee;
            overflow: hidden;
            border-radius: 20px;
            flex: 1;
            justify-content: center;
            align-items: center;
            text-align: center;
            line-height: 20px;
            font-size: ${theme.fonts.size.sm};
            color: ${theme.colors.black03};
            font-weight: ${theme.fonts.weight.medium};
            &.active {
              color: ${theme.colors.primary};
              border: solid 1px ${theme.colors.primary};
              font-weight: ${theme.fonts.weight.bold};
            }
          }
        }
      }
    `
  }}
`
function SearchOption({ history, match, location }) {
  const queryString = qs.parse(location.search.substr(1))
  const isPay = queryString.isPay ? queryString.isPay : null
  const [priceDisplayText, setPriceDisplayText] = useState("전체가격")
  const [categoryDisplayText, setCategoryDisplayText] = useState("전체분류")
  const depth1 = queryString.depth1 ? queryString.depth1 : null
  const depth2 = queryString.depth2 ? queryString.depth2 : null
  const orderBy = queryString.orderBy ? queryString.orderBy : null

  const [activeOption, setActiveOption] = useState("")
  const handleToggle = (index) => {
    if (activeOption === index) {
      setActiveOption(null)
    } else {
      setActiveOption(index)
    }
  }

  const { data, loading } = useQuery(CATEGORIES_QUERY, {
    fetchPolicy: "network-only",
    variables: {
      parentId: null,
    },
  })

  const isPayChange = ({ pay }) => {
    const uri =
      "?" +
      (pay ? "isPay=" + pay + "&" : "") +
      (depth1 ? "depth1=" + depth1 + "&" : "") +
      (depth2 ? "depth2=" + depth2 + "&" : "") +
      (orderBy ? "orderBy=" + orderBy + "&" : "")
    history.push(`/products${uri}`)
    setActiveOption("")
  }
  const isPayInit = () => {
    setPriceDisplayText(`전체가격`)

    const uri =
      "?" +
      (depth1 ? "depth1=" + depth1 + "&" : "") +
      (depth2 ? "depth2=" + depth2 + "&" : "") +
      (orderBy ? "orderBy=" + orderBy + "&" : "")
    history.push(`/products${uri}`)
    setActiveOption("")
  }
  const categoryChange = ({ category1, category2 }) => {
    if (category1 === null && category2 === null) {
      setCategoryDisplayText(`전체 분류`)
    }

    const uri =
      "?" +
      (isPay ? "isPay=" + isPay + "&" : "") +
      (category1 ? "depth1=" + category1 + "&" : "") +
      (category2 ? "depth2=" + category2 + "&" : "") +
      (orderBy ? "orderBy=" + orderBy + "&" : "")
    history.push(`/products${uri}`)
    setActiveOption("")
  }

  useEffect(() => {
    if (isPay) {
      setPriceDisplayText(isPayName(isPay))
    }
  }, [isPay])
  useEffect(() => {
    if (!loading) {
      if (depth1) {
        const category1 = data.TicketProductCategoryList.ticketCategories.find(
          (find) => find.id === depth1
        )
        if (category1.childrenCount === 0) {
          setCategoryDisplayText(`${category1.name}`)
        }
        if (category1.childrenCount > 0) {
          const category2 = category1.children.find(
            (find) => find.id === depth2
          )
          if (category2) {
            setCategoryDisplayText(`${category1.name} > ${category2.name}`)
          }
        }
      }
    }
  }, [data, loading, depth1, depth2])
  const tabs = [
    { id: "isPay", name: "전체 가격대" },
    { id: "Category", name: "전체 분류" },
  ]

  return (
    <SOptionTab>
      <ul className="nav">
        {tabs.map((item, index) => {
          return (
            <>
              <li>
                <button
                  className={`${activeOption === item.id ? "active" : ""}`}
                  onClick={() => handleToggle(item.id)}
                >
                  {item.name}
                  {activeOption === item.id ? (
                    <FontAwesomeIcon icon={faAngleUp} color="#333" size="lg" />
                  ) : (
                    <FontAwesomeIcon
                      icon={faAngleDown}
                      color="#999"
                      size="lg"
                    />
                  )}
                </button>
              </li>
            </>
          )
        })}
      </ul>
      <div className={`tabpane ${activeOption === "isPay" ? "open" : ""}`}>
        <SCategory>
          <ul>
            <li>
              <button
                className={`${isPay === null ? `active` : ``}`}
                onClick={() => {
                  isPayInit()
                }}
              >
                전체
              </button>
            </li>
            <li>
              <button
                className={`${isPay === "false" ? `active` : ``}`}
                onClick={() => {
                  isPayChange({ pay: "false" })
                }}
              >
                무료
              </button>
            </li>
            <li>
              <button
                className={`${isPay === "true" ? `active` : ``}`}
                onClick={() => {
                  isPayChange({ pay: "true" })
                }}
              >
                유료
              </button>
            </li>
          </ul>
        </SCategory>
      </div>
      <div className={`tabpane ${activeOption === "Category" ? "open" : ""}`}>
        {!loading && (
          <SCategory>
            <ul>
              <li>
                <button
                  className={`${
                    depth1 === null && depth2 === null ? `active` : ``
                  }`}
                  onClick={() => {
                    categoryChange({ category1: null, category2: null })
                  }}
                >
                  전체
                </button>
              </li>
              {data.TicketProductCategoryList.ticketCategories.map(
                (category1, index1) => {
                  return (
                    <Fragment key={index1}>
                      {category1.childrenCount === 0 && (
                        <li>
                          <button
                            className={`${
                              depth1 === category1.id && depth2 === null
                                ? `active`
                                : ``
                            }`}
                            onClick={() => {
                              categoryChange({
                                category1: category1.id,
                                category2: null,
                              })
                            }}
                          >
                            {category1.name}
                          </button>
                        </li>
                      )}
                      {category1.childrenCount > 0 &&
                        category1.children.map((category2, index2) => {
                          return (
                            <Fragment key={index2}>
                              {category2.childrenCount === 0 && (
                                <li>
                                  <button
                                    className={`${
                                      depth1 === category1.id &&
                                      depth2 === category2.id
                                        ? `active`
                                        : ``
                                    }`}
                                    onClick={() => {
                                      categoryChange({
                                        category1: category1.id,
                                        category2: category2.id,
                                      })
                                    }}
                                  >
                                    {category1.name} &gt; {category2.name}
                                  </button>
                                </li>
                              )}
                            </Fragment>
                          )
                        })}
                    </Fragment>
                  )
                }
              )}
            </ul>
          </SCategory>
        )}
      </div>
    </SOptionTab>
  )
}

export default withRouter(SearchOption)
