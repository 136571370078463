import React from "react"
import styled, { css } from "styled-components"
import { Link } from "react-router-dom"

const colorStyles = css`
  ${({ theme, color }) => {
    const selected = theme.colors[color]
    return css`
      background: ${selected};

      ${(props) =>
        props.color === "white" &&
        css`
          border: solid 1px #e5e5e5;
          color: ${theme.colors.black01};
          &:focus,
          &:active,
          &:hover {
            background: ${theme.colors.lightgray};
          }
        `}
      ${(props) =>
        props.color === "lightgray" &&
        css`
          color: ${theme.colors.black04};
          border: solid 1px #eee;
          &:focus,
          &:active,
          &:hover {
            background: #eee;
            color: ${theme.colors.black01};
          }
        `}
      ${(props) =>
        props.color === "lightprimary" &&
        css`
          background: ${theme.colors.lightprimary};
          color: ${theme.colors.primary};
          border: 0;
          &:focus,
          &:active,
          &:hover {
            background: ${theme.colors.primary};
            color: ${theme.colors.white};
          }
        `}
      ${(props) =>
        props.color === "primary" &&
        css`
          color: ${theme.colors.white};
          &:focus,
          &:active,
          &:hover {
            background: #173480;
            color: ${theme.colors.white};
          }
        `}
      ${(props) =>
        props.color === "warning" &&
        css`
          color: ${theme.colors.white};
          &:focus,
          &:active,
          &:hover {
            background: #db6d0f;
            color: ${theme.colors.white};
          }
        `}
      ${(props) =>
        props.color === "lightwarning" &&
        css`
          color: ${theme.colors.warning};
          &:focus,
          &:active,
          &:hover {
            background: ${theme.colors.warning};
            color: ${theme.colors.white};
          }
        `}
      ${(props) =>
        props.color === "danger" &&
        css`
          color: ${theme.colors.white};
          &:focus,
          &:active,
          &:hover {
            background: #f46969;
            color: ${theme.colors.white};
          }
        `}
      ${(props) =>
        props.color === "lightdanger" &&
        css`
          color: ${theme.colors.danger};
          &:focus,
          &:active,
          &:hover {
            background: ${theme.colors.danger};
            color: ${theme.colors.white};
          }
        `}
    `
  }}
`
const borderStyles = css`
  ${({ theme, color }) => {
    const selected = theme.colors[color]
    return css`
      ${(props) =>
        props.border === "solid" &&
        css`
          background-color: white;
          border: solid 1px ${selected};
          color: ${selected};
          &:focus,
          &:active,
          &:hover {
            background: ${selected};
          }
        `}
      ${(props) =>
        props.border === "radius" &&
        css`
          border-radius: 12px;
        `}
            ${(props) =>
        props.border === "round" &&
        css`
          background-color: transparent;
          border: solid 1px ${selected};
          color: ${selected};
          border-radius: 3rem;
          &:focus,
          &:active,
          &:hover {
            background: ${selected};
            color: white;
          }
        `}
    `
  }}
`

const widthStyles = css`
  ${(props) =>
    props.width === "full" &&
    css`
      width: 100%;
    `}
`

const sizeStyles = css`
  ${({ theme }) => {
    return css`
      ${(props) =>
        props.size === "xs" &&
        css`
          padding: ${theme.paddings.xs} ${theme.paddings.sm};
          font-size: 12px;
        `}

      ${(props) =>
        props.size === "sm" &&
        css`
          padding: ${theme.paddings.sm} ${theme.paddings.base};
          font-size: ${theme.fonts.size.sm};
        `}
            ${(props) =>
        props.size === "lg" &&
        css`
          padding: ${theme.paddings.lg};
          font-size: ${theme.fonts.size.base};
        `}
    `
  }}
`

const StyledButton = styled(Link)`
  ${({ theme }) => {
    return css`
      padding: ${theme.paddings.base};
      font-size: ${theme.fonts.size.base};
      font-weight: ${theme.fonts.weight.medium};
      border-radius: 4px;
    `
  }}
  /* 공통 스타일 */
  display: inline-flex;
  outline: none;
  line-height: 1.4;
  color: white;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  text-align: center;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  /* 크기 */
  ${sizeStyles}

  /* 색상 */
  ${colorStyles}

  /*넓이 */
  ${widthStyles}
  
  /*스타일 */
  ${borderStyles}


  & + & {
    margin-left: 5px;
  }
`

function Links({ children, color, size, width, border, ...rest }) {
  return (
    <StyledButton
      border={border}
      color={color}
      size={size}
      width={width}
      {...rest}
    >
      {children}
    </StyledButton>
  )
}
Links.defaultProps = {
  color: "default",
  size: "medium",
}

export default Links
