import React, { useState } from "react"
import { withRouter } from "react-router-dom"

import styled, { css } from "styled-components"
import { Dot, Row, Col, Rviewitem } from "../../../../../styles/styles"
import { graphqlUrl } from "../../../../../Config/Env"
import renderHTML from "react-render-html"
import nl2br from "react-nl2br"
import ReactMoment from "react-moment"
import Links from "../../../../Links"
import Button from "../../../../Buttons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faLockAlt } from "@fortawesome/pro-light-svg-icons"

const SRviewitem = styled(Rviewitem)`
  ${({ theme }) => {
    return css`
      .arrow {
        position: relative;
        font-size: 13px;
        color: ${theme.colors.black01};
        ::after {
          position: absolute;
          right: -20px;
          top: 50%;
          content: "\f106";
          font-family: ${theme.fonts.family.fontAwesomeL};
          font-size: ${theme.fonts.size.base};
          color: ${theme.colors.black01};
          transform: translateY(-50%);
        }
        &.open {
          color: ${theme.colors.black04};
          ::after {
            content: "\f107";
            color: ${theme.colors.black04};
          }
        }
      }
      .hide {
        display: none;
      }
    `
  }}
`
function Item({ history, match, location, item, uri, onDelete }) {
  const [isDetail, setIsDetail] = useState(false)
  const toggleView = () => {
    setIsDetail(!isDetail)
  }
  const reviewScore = (score) => {
    let string = ""
    for (var i = 0; i < score; i++) {
      string += "<span className='star on'></span>"
    }
    for (var j = 0; j < 5 - score; j++) {
      string += "<span className='star'></span>"
    }
    return string
  }

  const avatarImage =
    item.user.avatar === "" || item.user.avatar === null
      ? "/assets/img/user/user-default.jpg"
      : `${graphqlUrl}${item.user.avatar}`

  const storeLogoImage =
    item.ticketOrderProduct.ticketProduct.store.storeLogo === "" ||
    item.ticketOrderProduct.ticketProduct.store.storeLogo === null
      ? "/assets/img/user/user-default.jpg"
      : `${graphqlUrl}${item.ticketOrderProduct.ticketProduct.store.storeLogo}`

  return (
    <>
      <SRviewitem>
        <dl>
          <dt>
            <div className="img">
              <img src={avatarImage} alt={item.user.name} />
            </div>
          </dt>
          <dd>
            <Row className="userInfo">
              {renderHTML(reviewScore(item.score))}
              <Dot />
              <span>{item.user.name}</span>
              <Dot />
              <span>
                <ReactMoment format="YYYY.MM.DD">{item.createdAt}</ReactMoment>
              </span>
            </Row>
            <div className="review">
              {uri !== "/account/completeReviews" &&
              item.isSecret &&
              !item.isMe ? (
                <div className="text">
                  <FontAwesomeIcon icon={faLockAlt} />
                  &nbsp;비밀후기 입니다.
                </div>
              ) : (
                <div className="text">{nl2br(item.contents)}</div>
              )}
            </div>
            <Row>
              {uri === "/account/completeReviews" &&
                item.isComment === true && (
                  <span
                    onClick={toggleView}
                    className={`arrow ${!isDetail && `open`}`}
                  >
                    답글 보기
                  </span>
                )}
              {item.isMe && (
                <Col right className="group_btn">
                  <Links
                    color="white"
                    size="xs"
                    to={`/account/completeReviews/${item.id}/edit?redirect=${uri}`}
                  >
                    수정
                  </Links>
                  <Button
                    style={{ marginLeft: 5 }}
                    color="white"
                    size="xs"
                    onClick={() => onDelete(item.id)}
                  >
                    삭제
                  </Button>
                </Col>
              )}
            </Row>
          </dd>
        </dl>
        {uri === "/account/completeReviews" && item.isComment === true && (
          <>
            <div className={`reply ${!isDetail && `hide`}`}>
              <div className="review">
                <Row className="userInfo">
                  <span>
                    판매자(
                    {item.ticketOrderProduct.ticketProduct.store.name})
                  </span>
                  <Dot />
                  <span>
                    <ReactMoment format="YYYY.MM.DD">
                      {item.commentDate}
                    </ReactMoment>
                  </span>
                </Row>
                <div className="text">{nl2br(item.comment)}</div>
              </div>
            </div>
          </>
        )}
      </SRviewitem>
    </>
  )
}
export default withRouter(Item)
