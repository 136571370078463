import React from "react";
import { useQuery } from "@apollo/client";
import { MAIN_QUERY } from "../../Config/Queries";

import Presenter from "./Presenter";

function Container() {
  const { data, loading } = useQuery(MAIN_QUERY);

  return <Presenter loading={loading} data={data} />;
}

export default Container;
