import React from "react"

import { Layout } from "../../Components/Layouts/Layout"
import PageTitle from "../../Components/Layouts/PageTitle"
import { SProvision } from "../../styles/styles"
import { provision } from "../../Config/Env"

function Provision() {
  return (
    <Layout isNav={false} isFooter={true}>
      <PageTitle title={`${provision.Provision.title}`} />
      <SProvision>
        <h1>{provision.Provision.title}</h1>
        <div className="inner">{provision.Provision.content}</div>
      </SProvision>
    </Layout>
  )
}

export default Provision
