import { useState } from "react"
import { useMutation } from "@apollo/client"
import { NAME_BY_USERS_MUTATION } from "../../../../../Config/Queries"

import styled, { css } from "styled-components"
import {
  Roundbox,
  Col,
  Ready,
  SearchContainer,
  SearchList,
  UserList,
  FormBox,
  TabBars,
} from "../../../../../styles/styles"
import Tabs, { TabPane } from "rc-tabs"
import { useForm } from "react-hook-form"
import Button from "../../../../Buttons"
import theme from "../../../../../styles/Theme"
import { graphqlUrl } from "../../../../../Config/Env"
import { toast } from "react-toastify"
import { SearchNormal1 } from "iconsax-react"

const PresentUserBox = styled.div`
  ${({ theme }) => {
    return css`
      border-radius: 16px;
      background-color: white;
      .bottom {
        padding: ${theme.paddings.lg} ${theme.paddings.xl};
      }
    `
  }}
`
const renderTabBar = (props, DefaultTabBar) => (
  <TabBars>
    <DefaultTabBar {...props} />
  </TabBars>
)
function PresentUser({
  users,
  activeModal,
  setActiveModal,
  userAdd,
  userRemove,
}) {
  const [keyword, setKeyword] = useState("")
  const [searchUsers, setSearchUsers] = useState([])

  const [nameByUsersMutation, { loading }] = useMutation(NAME_BY_USERS_MUTATION)

  const { register, handleSubmit, errors, setValue } = useForm({
    mode: "onChange",
  })

  const onSearch = async () => {
    if (keyword === "") {
      toast.error(`수신자 이름을 입력해주세요.`)
      return
    }
    setKeyword("")

    try {
      const {
        data: {
          TicketProductUserSeach: { users },
        },
      } = await nameByUsersMutation({
        variables: {
          keyword: keyword,
        },
      })
      setSearchUsers(users)
      return
    } catch (e) {
      console.log(e)
    }
  }

  const onSubmit = async (data) => {
    if (users.find((item) => item.phone === data.phone)) {
      toast.error(`이미등록된 전화번호 입니다.`)
      return
    }

    const user = {
      isMember: false,
      id: null,
      userId: null,
      name: data.name,
      phone: data.phone,
      count: 1,
    }
    userAdd(user)
    setValue("name", "")
    setValue("phone", "")
  }

  const nextStep = () => {
    if (users.length === 0) {
      toast.error(`선물 받는분을 추가해주세요.`)
      return
    }
    setActiveModal("Counter")
  }

  return (
    <>
      <PresentUserBox
        style={
          activeModal === "User" ? { display: "block" } : { display: "none" }
        }
      >
        <UserList>
          <ul>
            {users.length > 0 ? (
              <>
                {users.map((item, index) => {
                  const avatarImage =
                    item.avatar === "" ||
                    item.avatar === null ||
                    item.avatar === undefined
                      ? "/assets/img/user/user-default.jpg"
                      : `${graphqlUrl}${item.avatar}`
                  return (
                    <li key={index}>
                      <Roundbox>
                        <Button
                          className="close"
                          onClick={() => userRemove(item)}
                        ></Button>
                        <div className="img">
                          <img src={avatarImage} alt={item.name} />
                        </div>
                      </Roundbox>
                      <p>
                        {item.isMember ? (
                          <>
                            {item.name}님 <span>{item.userId} </span>
                          </>
                        ) : (
                          <>
                            {item.name}님 <span>{item.phone} </span>
                          </>
                        )}
                      </p>
                    </li>
                  )
                })}
              </>
            ) : (
              <li className="check_list_done">
                <Roundbox>
                  <div className="icon"></div>
                </Roundbox>
                <div className="cont">
                  <p>수신자를 검색해주세요.</p>
                </div>
              </li>
            )}
          </ul>
        </UserList>

        <Tabs defaultActiveKey="1" renderTabBar={renderTabBar}>
          <TabPane tab={<span>회원</span>} key="1">
            <SearchContainer>
              <form>
                <div className="inner">
                  <button
                    type="button"
                    onClick={onSearch}
                    className="searchBtn"
                  >
                    <SearchNormal1
                      color={theme.colors.black02}
                      variant="Linear"
                      size={20}
                    />
                  </button>
                  <input
                    type="text"
                    name="keyword"
                    placeholder="이름 입력"
                    value={keyword}
                    onChange={(e) => {
                      setKeyword(e.target.value)
                    }}
                    onKeyPress={(e) => {
                      if (e.key === "Enter") {
                        e.preventDefault()
                        onSearch()
                      }
                    }}
                  />
                </div>
              </form>
            </SearchContainer>

            <SearchList>
              {searchUsers.length > 0 ? (
                <ul>
                  {searchUsers.map((item, index) => {
                    const avatarImage =
                      item.avatar === ""
                        ? "/assets/img/user/user-default.jpg"
                        : `${graphqlUrl}${item.avatar}`
                    return (
                      <li key={index}>
                        <label>
                          <Roundbox>
                            <div className="img">
                              <img src={avatarImage} alt={item.name} />
                            </div>
                          </Roundbox>
                          <input
                            type="checkbox"
                            onChange={() => {
                              const user = {
                                isMember: true,
                                id: item.id,
                                userId: item.userId,
                                name: item.name,
                                phone: item.phone,
                                count: 1,
                              }
                              userAdd(user)
                            }}
                            checked={users.find((user) => user.id === item.id)}
                          />
                          <p className="name">
                            {item.role === "User" && (
                              <>
                                {item.name}님<span>{item.userId}</span>
                              </>
                            )}
                            {item.role === "Store" && (
                              <>
                                {item.store.name} - {item.name}님
                                <span>({item.userId})</span>
                              </>
                            )}
                          </p>
                        </label>
                      </li>
                    )
                  })}
                </ul>
              ) : (
                <Ready>
                  <div>검색어를 입력해주세요.</div>
                </Ready>
              )}
            </SearchList>
          </TabPane>
          <TabPane tab={<span>비회원</span>} key="2">
            <FormBox>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="form_group">
                  <label>이름</label>
                  <input
                    type="text"
                    name="name"
                    placeholder="이름"
                    ref={register({
                      required: "이름를 입력해주세요.",
                    })}
                  />
                </div>
                <div className="form_group">
                  <label>전화번호</label>
                  <input
                    type="text"
                    name="phone"
                    placeholder="전화번호 ‘-’제외"
                    ref={register({
                      required: "전화번호를 입력해주세요.",
                    })}
                  />
                </div>
                <Button type="submit" color="primary" border="solid" size="sm">
                  추가
                </Button>
              </form>
            </FormBox>
          </TabPane>
        </Tabs>
        <div className="bottom">
          <Button onClick={nextStep} color="primary" width="full" size="lg">
            다음
          </Button>
        </div>
      </PresentUserBox>
    </>
  )
}

export default PresentUser
