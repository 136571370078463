import React from "react"
import { withRouter } from "react-router-dom"

import styled, { css } from "styled-components"
import { SOrderItem } from "../../../../../styles/styles"
import Label from "../../../../Labels"
import ReactMoment from "react-moment"
import moment from "moment"
import { graphqlUrl } from "../../../../../Config/Env"

import {
  dayOfWeekName,
  isPayName,
  placeTypeName,
  datesCounter,
} from "../../../../../Config/Util"

const SSOrderItem = styled(SOrderItem)`
  ${({ theme }) => {
    return css`
      .product_list {
        display: flex;
        flex-direction: row;
        padding: ${theme.paddings.base};
        .img {
          position: relative;
          background-color: ${theme.colors.lightgray};
          width: 72px;
          height: 72px;
          border-radius: 8px;
        }
        .cont {
          .info {
            span {
              font-size: 12px;
            }
          }
        }
      }
    `
  }}
`
function Item({ history, match, location, item }) {
  const CoverImage =
    item.ticketProduct.coverImage === null
      ? "/assets/img/product/product-default.jpg"
      : `${graphqlUrl}${item.ticketProduct.coverImage}`

  const startDate =
    item.isReservation === true
      ? new Date(item.ticketProductDateTime?.ticketDateTime)
      : new Date(item.ticketProduct.applyStartDateTime)
  const endDate =
    item.isReservation === true
      ? moment(item.ticketProductDateTime?.ticketDateTime).add(
          parseInt(item.ticketProduct.minute),
          "minutes"
        )
      : new Date(item.ticketProduct.applyEndDateTime)
  return (
    <>
      <SSOrderItem className="complete">
        <div className="product_list">
          <div className="img">
            <img src={CoverImage} alt={item.ticketProduct.name} />
          </div>
          <div className="cont">
            <span className="category">{item.ticketProduct.store.name}</span>
            <strong>{item.ticketProduct.name}</strong>
            {item.isReservation === true && (
              <div className="date">
                예매일 :
                <ReactMoment format="YYYY.MM.DD">{startDate}</ReactMoment>(
                {dayOfWeekName(startDate.getDay())}){" "}
                <ReactMoment format="HH:mm">{startDate}</ReactMoment> ~{" "}
                <ReactMoment format="HH:mm">{endDate}</ReactMoment>
              </div>
            )}
            {item.isReservation === false && (
              <div className="date">
                예매기간 :
                <ReactMoment format="YYYY.MM.DD">{startDate}</ReactMoment>(
                {dayOfWeekName(startDate.getDay())}) ~{" "}
                <ReactMoment format="YYYY.MM.DD">{endDate}</ReactMoment>(
                {dayOfWeekName(endDate.getDay())})
              </div>
            )}
            <div className="info">
              <span>From. {item.user.name}</span>
              <span>
                <ReactMoment format="YYYY.MM.DD HH:mm:ss">
                  {item.createdAt}
                </ReactMoment>
              </span>
            </div>
          </div>
        </div>
      </SSOrderItem>
    </>
  )
}
export default withRouter(Item)
