import React, { useState } from "react";
import styled, { css } from "styled-components";
import Button from "../../Buttons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleUp } from "@fortawesome/pro-light-svg-icons";
import renderHTML from "react-render-html";
import "../../../styles/tiny_mce.css";

const InfoProductContent = styled.div`
  ${({ theme }) => {
    return css`
      margin-bottom: ${theme.margins.base};
      img {
        max-width: 100%;
        height: auto;
        object-fit: cover;
      }
      padding: ${theme.paddings.base};
      .intropage {
        min-height: 300px;
      }
      .open {
        height: auto;
        overflow: auto;
      }
      .close {
        height: 300px;
        overflow: hidden;
      }
      iframe {
        width: 100% !important;
      }
    `;
  }}
`;
function Intro({ contents }) {
  return (
    <>
      <InfoProductContent>
        <div className={"intropage open"}>
          <div className="mce-content">{renderHTML(contents)}</div>
        </div>
      </InfoProductContent>
    </>
  );
}
export default Intro;
