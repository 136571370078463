import React from "react"

import styled, { css } from "styled-components"
import { Link } from "react-router-dom"
import ReactMoment from "react-moment"
import NumberFormat from "react-number-format"
import { graphqlUrl } from "../../../Config/Env"

import { dayOfWeekName, isPayName, placeTypeName } from "../../../Config/Util"
import { SItem } from "../../../styles/styles"

function index({ item }) {
  const CoverImage =
    item.coverImage === ""
      ? "/assets/img/user/user-default.jpg"
      : `${graphqlUrl}${item.coverImage}`
  const startDate = new Date(item.applyStartDateTime)
  const endDate = new Date(item.applyEndDateTime)
  return (
    <>
      <Link to={`/products/${item.id}`}>
        <SItem>
          <div className="img">
            <img src={CoverImage} alt={item.name} />
            <span>{placeTypeName(item.placeType)}</span>
          </div>
          <div className="cont">
            <span className="category">{item.store.name}</span>
            <h3>{item.name}</h3>
            <div className="price">
              {item.isPay ? (
                <>
                  <b>
                    <NumberFormat
                      value={
                        item.isDiscount
                          ? item.price - item.discountPrice
                          : item.price
                      }
                      displayType={"text"}
                      thousandSeparator={true}
                    />
                  </b>
                  드림
                </>
              ) : (
                <span className="free">{isPayName(String(item.isPay))}</span>
              )}
            </div>
          </div>
        </SItem>
      </Link>
    </>
  )
}

export default index
