import React from "react"
import { withRouter, Link } from "react-router-dom"

import styled, { css } from "styled-components"
import { Dot, Row, SOrderItem } from "../../../../styles/styles"
import NumberFormat from "react-number-format"
import { graphqlUrl } from "../../../../Config/Env"
import IsWishlist from "./IsWishlist"
import {
  dayOfWeekName,
  isPayName,
  placeTypeName,
} from "../../../../Config/Util"

const SSOrderItem = styled(SOrderItem)`
  ${({ theme }) => {
    return css`
      margin-bottom: ${theme.margins.base};
      .product_list {
        flex-direction: row;
        .img {
          width: 90px;
          height: 90px;
          border-radius: 8px;
        }
        .discount {
          margin-top: 10px;
          color: ${theme.colors.danger};
          font-size: 14px;
          span {
            color: ${theme.colors.black04};
            font-size: 13px;
            text-decoration: line-through;
          }
        }
        .cont {
          strong {
          }
        }
        .price {
          margin-top: ${theme.margins.base};
          font-size: 15px;
          color: #333;
          font-weight: 500;
        }
        .free {
          font-size: 13px;
          margin-bottom: ${theme.margins.xs};
          color: ${theme.colors.primary};
        }
      }
    `
  }}
`

function Item({ history, match, location, item }) {
  const CoverImage =
    item.ticketProduct.coverImage === null
      ? "/assets/img/product/product-default.jpg"
      : `${graphqlUrl}${item.ticketProduct.coverImage}`
  const Percent = parseInt(
    (item.ticketProduct.discountPrice / item.ticketProduct.price) * 100
  )

  return (
    <>
      <SSOrderItem>
        <div className="item_content">
          <div className="product_list">
            <Link to={`/products/${item.ticketProduct.id}${location.search}`}>
              <div className="img">
                <img
                  src={CoverImage}
                  alt={item.ticketProduct.ticketCategory.name}
                />
              </div>
              <div className="cont">
                <Row>
                  <span className="free">
                    {isPayName(String(item.ticketProduct.isPay))}
                  </span>
                  <Dot />
                  <span className="category">
                    {item.ticketProduct.ticketCategory.name}
                  </span>
                </Row>
                <strong>{item.ticketProduct.name}</strong>
                {item.ticketProduct.isPay && (
                  <div className="price">
                    <b>
                      <NumberFormat
                        value={
                          item.ticketProduct.isDiscount
                            ? item.ticketProduct.price -
                              item.ticketProduct.discountPrice
                            : item.ticketProduct.price
                        }
                        displayType={"text"}
                        thousandSeparator={true}
                      />
                    </b>
                    드림
                  </div>
                )}
              </div>
            </Link>
            <IsWishlist id={item.ticketProduct.id} />
          </div>
        </div>
      </SSOrderItem>
    </>
  )
}

export default withRouter(Item)
