import React from "react";
import styled, { css } from "styled-components";

const borderStyles = css`
  ${({ theme }) => {
    return css`
      ${(props) =>
        props.border === "bottom" &&
        css`
          border-bottom: solid 1px #eee;
        `}
    `;
  }}
`;
const StyledRow = styled.div`
  /* 공통 스타일 */
  ${({ theme }) => {
    return css`
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      ${(props) =>
        props.total === "total"
          ? css`
              padding: ${theme.paddings.base} 0;
            `
          : css`
              padding: ${theme.paddings.sm} 0;
            `}
      line-height: 1.4;
      .left {
        display: flex;
        ${(props) =>
          props.total === "total"
            ? css`
                font-size: ${theme.fonts.size.base};
                color: ${theme.colors.black01};
              `
            : css`
                font-size: 15px;
                color: ${theme.colors.black03};
              `}
        align-items: center;
        font-weight: ${theme.fonts.weight.medium};
      }
      .right {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        text-align: right;
        font-family: ${theme.fonts.family.base};
        font-weight: ${theme.fonts.weight.base};
        ${(props) =>
          props.total === "total"
            ? css`
                font-size: ${theme.fonts.size.base};
                color: ${theme.colors.black01};
              `
            : css`
                font-size: 15px;
                color: ${theme.colors.black02};
              `}
        b {
          font-weight: ${theme.fonts.weight.bold};
        }
      }
    `;
  }}
  /* 색상 */
  ${borderStyles}
`;

function RowInfo({ textLeft, textRight, border, total, ...rest }) {
  return (
    <StyledRow border={border} total={total} {...rest}>
      <div className="left">{textLeft}</div>
      <div className="right">{textRight}</div>
    </StyledRow>
  );
}
RowInfo.defaultProps = {
  color: "lightgray",
  size: "medium",
};

export default RowInfo;
