import React from "react"
import { withRouter, Link } from "react-router-dom"
import { useQuery } from "@apollo/client"
import { PRODUCTS_QUERY } from "../../Config/Queries"

import { Layout } from "../../Components/Layouts/Layout"
import PageTitle from "../../Components/Layouts/PageTitle"
import styled, { css } from "styled-components"
import { Ready, SearchContainer } from "../../styles/styles"
import { useForm } from "react-hook-form"
import FormError from "../../Components/FormError"
import Button from "../../Components/Buttons"
import ProductItem from "../../Components/Product/Product/List/Item"
import Pagination from "../../Components/Pagination"
import SLoading from "../../Components/Loading"
import qs from "qs"
import { SearchNormal1 } from "iconsax-react"
const SProductItemlist = styled.div`
  ${({ theme }) => {
    return css`
      display: flex;
      flex-wrap: wrap;
      padding: ${theme.paddings.base} ${theme.paddings.sm};
      ul {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        li {
          display: flex;
          flex: 0 0 50%;
          padding: 0 ${theme.paddings.sm};
          margin-bottom: ${theme.margins.lg};
          a {
            display: block;
            flex: 1;
          }
        }
      }
    `
  }}
`

function Result({ history, match, location }) {
  const queryString = qs.parse(location.search.substr(1))
  const productType = queryString.productType
    ? queryString.productType
    : "Product"
  const keyword = queryString.keyword ? queryString.keyword : null
  const page = queryString.page ? queryString.page : 1
  const blockSize = 5
  const first = 12
  const skip = first * (page - 1)
  const baseUrl =
    "?" +
    (productType ? "productType=" + productType + "&" : "") +
    (keyword ? "keyword=" + keyword + "&" : "")

  const { register, handleSubmit, errors } = useForm({
    defaultValues: {
      keyword: keyword,
    },
  })

  const { data, loading } = useQuery(PRODUCTS_QUERY, {
    fetchPolicy: "network-only",
    variables: {
      keyword: keyword,
      orderBy: "orderCount_DESC",
      skip,
      first,
    },
  })

  const setItem = async (text) => {
    const date = new Date()
    const newKeyword = { keyword: text, date: date.toString() }

    let keywords = await localStorage.getItem("keywords")

    if (keywords) {
      keywords = JSON.parse(keywords)

      let tempKeywords = keywords.filter((item) => text !== item.keyword)

      tempKeywords.map((item) => {
        return { keyword: item.keyword, date: item.date }
      })
      tempKeywords = tempKeywords ? [newKeyword, ...tempKeywords] : [newKeyword]
      await localStorage.removeItem("keywords")
      localStorage.setItem("keywords", JSON.stringify(tempKeywords))
    } else {
      const tempKeywords = [newKeyword]
      localStorage.setItem("keywords", JSON.stringify(tempKeywords))
    }
  }

  const onSubmit = (data) => {
    setItem(data.keyword)
    history.push(`/search/result?keyword=${data.keyword}`)
  }

  return (
    <Layout isNav={true} isFooter={true}>
      <PageTitle title="검색" />
      <SearchContainer>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="inner">
            <Button className="searchBtn">
              <SearchNormal1 color={"#333"} variant="Linear" size={20} />
            </Button>
            <input
              type="text"
              name="keyword"
              placeholder="검색어(상품, 가맹점명)를 입력해주세요."
              ref={register({
                required: "검색어(상품, 가맹점명)를 입력해주세요.",
              })}
              autoComplete="off"
              autoFocus
            />
          </div>
          <FormError message={errors?.keyword?.message} />
        </form>
      </SearchContainer>

      {!loading ? (
        <>
          {data.TicketProductList.count > 0 ? (
            <>
              {productType === "Product" && (
                <SProductItemlist>
                  <ul>
                    {data.TicketProductList.ticketProducts.map(
                      (item, index) => {
                        return (
                          <li key={index}>
                            <Link to={`/products/${item.id}`}>
                              <ProductItem item={item} />
                            </Link>
                          </li>
                        )
                      }
                    )}
                  </ul>
                </SProductItemlist>
              )}
              <Pagination
                totalRecord={data.TicketProductList.count}
                blockSize={blockSize}
                pageSize={first}
                currentPage={page}
                baseUrl={baseUrl}
              />
            </>
          ) : (
            <Ready>
              <div className="list_none">최근 검색 내역이 없습니다.</div>
            </Ready>
          )}
        </>
      ) : (
        <>
          <SLoading />
        </>
      )}
    </Layout>
  )
}

export default withRouter(Result)
