import { useState, useEffect } from "react"
import { withRouter, Link } from "react-router-dom"
import { useQuery } from "@apollo/client"
import { gql } from "@apollo/client"

import styled, { css } from "styled-components"
import { SModal, Stepbox, Stepbtn } from "../../../../../styles/styles"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronDown, faCheck } from "@fortawesome/pro-regular-svg-icons"
import Button from "../../../../Buttons"
import ReservationCalendar from "./ReservationCalendar"
import ReservationCounter from "./ReservationCounter"

import { datesCounter } from "../../../../../Config/Util"
import { toast } from "react-toastify"
import { ModalLayout } from "../../../../Layouts/Layout"

function Modal({
  history,
  match,
  location,
  isOpen,
  toggleModal,
  id,
  minute,
  limit,
  isPay,
  price,
  isDiscount,
  discountPrice,
  name,
  startDate,
  endDate,
  ticketProductDatesCount,
  ticketProductDates,
}) {
  const ACCOUNT_QUERY = gql`
    query TicketAccountUser {
      TicketAccountUser {
        user {
          avatar
          userId
          name
          email
          phone
          birthDate
          zipcode
          roadAddress
          jibunAddress
          addressDetail
          latitude
          longitude

          role
          visitedAt
          createdAt
          updatedAt
        }
      }
    }
  `

  const { data, loading } = useQuery(ACCOUNT_QUERY, {
    fetchPolicy: "network-only",
  })

  const [activeModal, setActiveModal] = useState("Calendar")

  const [excludeDates, setExcludeDates] = useState([])

  const [productCount, setProductCount] = useState(1)
  const [productPrice, setProductPrice] = useState(price)
  const [totalPrice, setTotalPrice] = useState(price)

  const [ticketProductDate, setTicketProductDate] = useState(null)
  const [ticketProductDateTime, setTicketProductDateTime] = useState(null)
  const [count, setCount] = useState(1)

  const [ticketDateTimePeoples, setTicketDateTimePeoples] = useState([
    { name: "", phone: "" },
  ])

  const handleTicketDateTimeAdd = () => {
    if (ticketDateTimePeoples.length >= 10) {
      return toast.error("10개 이상 추가 할 수 없습니다.")
    }
    setTicketDateTimePeoples([
      ...ticketDateTimePeoples,
      { name: "", phone: "" },
    ])
  }
  const handleTicketDateTimeRemove = (index) => {
    if (ticketDateTimePeoples.length <= 1) {
      return toast.error("1개 이하로 삭제 할 수 없습니다.")
    }
    const rows = ticketDateTimePeoples.filter((item, idx) => idx !== index)
    setTicketDateTimePeoples(rows)
  }
  const handleTicketDateTimeChange = (e, index) => {
    const { name, value } = e.target

    const rows = [...ticketDateTimePeoples]
    rows[index][name] = value
    setTicketDateTimePeoples(rows)
  }

  const minus = () => {
    if (count <= 1) return
    setCount(count - 1)

    setProductCount(count - 1)
    setTotalPrice((count - 1) * price)
    totalSum({
      price,
      count: count - 1,
    })
    handleTicketDateTimeRemove(ticketDateTimePeoples.length - 1)
  }
  const plus = () => {
    const currentCount =
      limit - ticketProductDateTime.ticketOrderProductPeoplesCount

    if (count >= currentCount) {
      return toast.error(`정원을 초과하여 예매할 수 없습니다.`)
    }

    // limit;

    setCount(count + 1)

    setProductCount(count + 1)
    setTotalPrice((count + 1) * price)
    totalSum({
      price,
      count: count + 1,
    })
    handleTicketDateTimeAdd()
  }
  const totalSum = ({ price, count }) => {
    const productPrice = price
    const totalPrice = price * count

    setProductPrice(productPrice)
    setTotalPrice(totalPrice)
  }

  const onSubmit = () => {
    for (var loop = 0; loop < ticketDateTimePeoples.length; loop++) {
      if (ticketDateTimePeoples[loop].name === "") {
        return toast.error(`동행자 ${loop + 1}번 이름을 입력해주세요.`)
      }
      if (ticketDateTimePeoples[loop].phone === "") {
        return toast.error(`동행자 ${loop + 1}번 휴대전화를 입력해주세요.`)
      }
    }

    history.push(`/order`, {
      orderType: "me",
      id,
      ticketProductDate,
      ticketProductDateTime,
      count,
      ticketDateTimePeoples,
    })
  }

  useEffect(() => {
    const productDates = ticketProductDates.map((item) => {
      return new Date(item.ticketDate)
    })

    const startAt = new Date(startDate)
    const endAt = new Date(endDate)
    const datesCount = datesCounter(startAt, endAt)
    let dates = []

    for (var loop = 0; loop <= datesCount; loop++) {
      const loopDate = new Date(
        startAt.getFullYear(),
        startAt.getMonth(),
        startAt.getDate() + loop,
        9,
        0,
        0
      )

      if (
        !productDates.some((item) => {
          return item.toString() === loopDate.toString()
        })
      ) {
        dates.push(loopDate)
      }
    }
    setExcludeDates(dates)
  }, [])
  useEffect(() => {
    if (!loading) {
      const {
        TicketAccountUser: { user },
      } = data
      setTicketDateTimePeoples([{ name: user.name, phone: user.phone }])
    }
  }, [data, loading])

  return (
    <ModalLayout
      isOpen={isOpen}
      toggleModal={toggleModal}
      title={"나에게 선물하기"}
    >
      <Stepbtn>
        <button
          onClick={() => setActiveModal("Calendar")}
          className={` ${activeModal === "Calendar" ? "active" : ""}`}
        >
          <span>일정정보</span>
        </button>
        <button className={` ${activeModal === "Counter" ? "active" : ""}`}>
          <span>옵션선택</span>
        </button>
      </Stepbtn>
      <Stepbox>
        <ReservationCalendar
          activeModal={activeModal}
          setActiveModal={setActiveModal}
          minute={minute}
          limit={limit}
          isPay={isPay}
          price={price}
          startDate={startDate}
          endDate={endDate}
          ticketProductDatesCount={ticketProductDatesCount}
          ticketProductDates={ticketProductDates}
          excludeDates={excludeDates}
          ticketProductDate={ticketProductDate}
          setTicketProductDate={setTicketProductDate}
          ticketProductDateTime={ticketProductDateTime}
          setTicketProductDateTime={setTicketProductDateTime}
          setProductPrice={setProductPrice}
          setTotalPrice={setTotalPrice}
        />
      </Stepbox>
      <Stepbox>
        <ReservationCounter
          activeModal={activeModal}
          setActiveModal={setActiveModal}
          isPay={isPay}
          productCount={productCount}
          productPrice={productPrice}
          totalPrice={totalPrice}
          count={count}
          name={name}
          minus={minus}
          plus={plus}
          ticketDateTimePeoples={ticketDateTimePeoples}
          handleTicketDateTimeChange={handleTicketDateTimeChange}
          onSubmit={onSubmit}
        />
      </Stepbox>
    </ModalLayout>
  )
}

export default withRouter(Modal)
