import styled, { css } from "styled-components"
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion"
import "react-accessible-accordion/dist/fancy-example.css"
import "../styles/module.css"

const SAccordion = styled(Accordion)`
  ${({ theme }) => {
    return css`
      width: 100%;
    `
  }}
`
const SAccordionItem = styled(AccordionItem)`
  ${({ theme }) => {
    return css`
      background-color: white;
    `
  }}
`
SAccordionItem.AccordionRole = "AccordionItem"
const SAccordionItemHeading = styled(AccordionItemHeading)`
  ${({ theme }) => {
    return css`
      position: relative;
      border-top: solid 5px #eee;
    `
  }}
`
SAccordionItemHeading.AccordionRole = "AccordionItemHeading"

const SAccordionItemPanel = styled(AccordionItemPanel)`
  ${({ theme }) => {
    return css`
      padding: 0 ${theme.paddings.xl} ${theme.paddings.xl};
    `
  }}
`
SAccordionItemPanel.AccordionRole = "AccordionItemPanel"
function Accordions({ preExpanded, items }) {
  return (
    <SAccordion
      preExpanded={preExpanded}
      allowMultipleExpanded={true}
      allowZeroExpanded={true}
    >
      {items &&
        items.map((item, index) => (
          <SAccordionItem key={index} uuid={item.uuid}>
            <SAccordionItemHeading>
              <AccordionItemButton>{item.heading}</AccordionItemButton>
            </SAccordionItemHeading>
            <SAccordionItemPanel>{item.content}</SAccordionItemPanel>
          </SAccordionItem>
        ))}
    </SAccordion>
  )
}

export default Accordions
