import React from "react";
import { withRouter, Link } from "react-router-dom";

import styled, { css } from "styled-components";
import ReactMoment from "react-moment";
import NumberFormat from "react-number-format";
import { graphqlUrl } from "../../../../Config/Env";
import { SItem } from "../../../../styles/styles";

import {
  dayOfWeekName,
  isPayName,
  placeTypeName,
} from "../../../../Config/Util";

function Item({ history, match, location, rank, item }) {
  const CoverImage =
    item.coverImage === null
      ? "/assets/img/product/product-default.jpg"
      : `${graphqlUrl}${item.coverImage}`;
  const startDate = new Date(item.applyStartDateTime);
  const endDate = new Date(item.applyEndDateTime);

  return (
    <SItem>
      <div
        className="img"
        //style={{ backgroundImage: "url(" + CoverImage + ")" }}
      >
        <img src={CoverImage} alt={item.name} />
        <span>{placeTypeName(item.placeType)}</span>
      </div>
      <div className="cont">
        <span className="category">{item.store.name}</span>
        <h3>{item.name}</h3>
        <span className="date">
          <ReactMoment format="MM.DD">{item.applyStartDateTime}</ReactMoment>(
          {dayOfWeekName(startDate.getDay())}) ~{" "}
          <ReactMoment format="MM.DD">{item.applyEndDateTime}</ReactMoment>(
          {dayOfWeekName(endDate.getDay())})
        </span>
        <div className="price">
          {item.isPay ? (
            <>
              <b>
                <NumberFormat
                  value={
                    item.isDiscount
                      ? item.price - item.discountPrice
                      : item.price
                  }
                  displayType={"text"}
                  thousandSeparator={true}
                />
              </b>
              드림
            </>
          ) : (
            <span className="free">{isPayName(String(item.isPay))}</span>
          )}
        </div>
      </div>
    </SItem>
  );
}
export default withRouter(Item);
