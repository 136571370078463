import styled, { createGlobalStyle, css } from "styled-components"
import reset from "styled-reset"
import "../styles/module.css"

import "swiper/swiper.min.css"
import "swiper/components/navigation/navigation.min.css"
import "swiper/components/pagination/pagination.min.css"

export const Dot = styled.div`
  ${({ theme }) => {
    return css`
      background-color: ${theme.colors.black03};
      width: 4px;
      height: 4px;
      margin: 0 5px;
      border-radius: 3px;
    `
  }}
`
export const Wrap = styled.div`
  ${({ theme }) => {
    return css`
      background-color: white;
      margin: 0 auto;
      width: 100%;
      display: flex;
      flex-direction: column;
      overflow: hidden;
      height: 100%;
      font-family: ${theme.fonts.family.base};
      ${theme.device.desktop} {
        max-width: 750px;
      }
    `
  }}
`
export const Container = styled.div`
  ${({ theme }) => {
    return css`
      display: flex;
      flex-direction: column;
      flex: 1 0 auto;
      position: relative;
      height: 100%;
      .title {
        font-weight: ${theme.fonts.weight.medium};
        font-size: ${theme.fonts.size.sm};
      }
    `
  }}
`
export const PageLocation = styled.div`
  ${({ theme }) => {
    return css`
      display: flex;
      ul {
        li {
          position: relative;
          float: left;
          font-size: 1.1rem;
          padding-left: ${theme.paddings.base};
          color: #999;
          ::before {
            position: absolute;
            top: 0;
            left: 3px;
            content: "\f105";
            font-family: ${theme.fonts.family.fontAwesomeL};
            font-size: 1.2rem;
          }
          :first-child {
            padding: 0;
            ::before {
              display: none;
            }
          }
        }
      }
    `
  }}
`
export const Ready = styled.div`
  ${({ theme }) => {
    return css`
      display: flex;
      text-align: center;
      justify-content: center;
      align-items: center;
      height: 100%;
      div {
        display: flex;
        width: 100%;
        min-height: 120px;
        justify-content: center;
        align-items: center;
        color: ${theme.colors.black04};
        font-size: ${theme.fonts.size.base};
      }
    `
  }}
`
export const FooterButton = styled.div`
  ${({ theme }) => {
    return css`
      position: fixed;
      width: 100%;
      right: 0;
      left: 0;
      bottom: 0;
      z-index: 99;
      div {
        background-color: white;
        box-shadow: 0px -4px 15px rgba(0, 0, 0, 0.1);
        flex-direction: row;
        display: flex;
        padding: 15px;
        a,
        button {
          height: 60px;
          line-height: 60px;
          :last-child {
            margin-left: 10px;
          }
        }
      }
    `
  }}
`
export const Select = styled.div`
  ${({ theme }) => {
    return css`
      position: relative;
      width: 100%;
      select {
        background-color: white;
        height: 40px;
        width: 100%;
        padding: 0 ${theme.paddings.sm};
        border: solid 1px #ddd;
        border-radius: 5px;
        color: ${theme.colors.black04};
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        color: #444;
        font-size: 1.35rem;
      }
      ::before {
        content: "\f107";
        font-family: ${theme.fonts.family.fontAwesome};
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
        color: #777;
        font-size: ${theme.fonts.size.sm};
        z-index: 999;
      }
    `
  }}
`
export const Textarea = styled.textarea`
  ${({ theme }) => {
    return css`
      width: 100%;
      padding: ${theme.paddings.sm};
      border: solid 2px #eee;
      border-radius: 5px;
    `
  }}
`
export const Input = styled.div`
  ${({ theme }) => {
    return css`
      input {
        height: 40px;
        width: 100%;
        padding: 0 ${theme.paddings.sm};
        border: solid 1px #eee;
        border-radius: 4px;
        font-size: ${theme.fonts.size.base};
        ::placeholder {
          color: ${theme.colors.black04};
        }
      }
    `
  }}
`
export const Row = styled.div`
  ${({ theme }) => {
    return css`
      flex-direction: row;
      display: flex;
      align-items: center;
      ${(props) =>
        props.type === "btn" &&
        css`
          padding: 0 ${theme.paddings.base};
        `}
    `
  }}
`
export const Col = styled.div`
  flex: 1;
  display: flex;
  align-items: center;

  ${(props) =>
    props.left &&
    css`
      justify-content: flex-start;
    `}
  ${(props) =>
    props.center &&
    css`
      justify-content: center;
    `}
  ${(props) =>
    props.right &&
    css`
      justify-content: flex-end;
    `}
`
export const SwiperSlideImg = styled.div`
  position: relative;
  overflow: hidden;
  height: 0;
  padding-bottom: 100%;
  :before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    border: 1px solid rgba(0, 0, 0, 0.05);
  }
  img {
    max-width: 100%;
    display: block;
    width: 100%;
    height: auto;
  }
`
export const Roundbox = styled.div`
  ${({ theme }) => {
    return css`
      position: relative;
      .icon {
        display: flex;
        justify-content: center;
        align-items: center;
        border: solid 1px #eee;
        width: 42px;
        height: 42px;
        border-radius: 16px;
        background-color: ${theme.colors.lightgray};
        ::before {
          content: "\f007";
          font-family: ${theme.fonts.family.fontAwesomeL};
          color: ${theme.colors.black04};
        }
      }
      .img {
        background-color: ${theme.colors.lightgray};
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 42px;
        height: 42px;
        border-radius: 16px;
        border: solid 1px #eee;
        img {
          height: 100%;
        }
      }
    `
  }}
`
export const Comment = styled.div`
  ${({ theme }) => {
    return css`
      background-color: ${theme.colors.lightgray};
      padding: ${theme.paddings.xl} ${theme.paddings.xl};
      h4 {
        margin-top: ${theme.margins.base};
        font-size: ${theme.fonts.size.base};
        color: ${theme.colors.black02};
        text-align: center;
      }
      .user_photo {
        display: flex;
        justify-content: center;
        li.img {
          ::before {
            display: none;
          }
        }
        li {
          background-color: white;
          float: left;
          margin-left: -10px;
          overflow: hidden;
          width: 50px;
          height: 50px;
          justify-content: center;
          text-align: center;
          border-radius: 32px;
          z-index: 99;
          ::before {
            content: "\f007";
            font-family: ${theme.fonts.family.fontAwesomeL};
            color: ${theme.colors.black04};
          }
          :first-child {
            margin-left: 0;
          }
        }
      }
      .user_text {
        margin-top: ${theme.margins.base};
        text-align: center;
        font-weight: ${theme.fonts.weight.base};
        font-size: ${theme.fonts.size.sm};
        color: ${theme.colors.black02};
        b {
          font-weight: ${theme.fonts.weight.bold};
        }
      }
      .massge_write {
        margin-top: ${theme.margins.base};
        textarea {
          width: 100%;
          height: 100px;
          background-color: ${theme.colors.white};
          border-radius: 8px;
          border: solid 1px #e4e7ed;
          padding: ${theme.paddings.base};
          border: 0;
          border-radius: 5px;
          text-align: center;
          font-size: ${theme.fonts.size.base};
          line-height: 1.4;
        }
        p {
          margin-bottom: ${theme.margins.base};
          text-align: center;
          font-size: ${theme.fonts.size.sm};
          font-weight: ${theme.fonts.weight.medium};
          color: ${theme.colors.black01};
        }
        .inner {
          width: 100%;
          height: 100px;
          background-color: ${theme.colors.white};
          padding: ${theme.paddings.base};
          border: 0;
          border-radius: 5px;
          text-align: center;
          font-size: ${theme.fonts.size.base};
          line-height: 1.4;
        }
      }
    `
  }}
`
export const SItem = styled.div`
  ${({ theme }) => {
    return css`
      .img {
        background-color: #fafafa;
        position: relative;
        overflow: hidden;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 8px;
        :before {
          content: "";
          display: block;
          padding-top: 100%;
        }
        :after {
          content: "";
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          border: solid 1px rgba(0, 0, 0, 0.05);
          border-radius: 5px;
        }
        img {
          position: absolute;
          padding: 0;
          overflow: hidden;
        }
        span {
          position: absolute;
          background-color: ${theme.colors.black02};
          left: 8px;
          top: 8px;
          border-radius: 4px;
          padding: 0 5px;
          line-height: 24px;
          font-size: 13px;
          color: white;
          font-weight: ${theme.fonts.weight.medium};
        }
      }
      .cont {
        width: 100%;
        margin-top: 10px;
        .category {
          color: ${theme.colors.black03};
          font-size: 13px;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          display: block;
        }
        h3 {
          margin: ${theme.margins.sm} 0;
          height: 40px;
          overflow: hidden;
          font-size: 15px;
          letter-spacing: -0.25px;
          color: ${theme.colors.black01};
          font-weight: ${theme.fonts.weight.bold};
          line-height: 1.3;
        }
        .date {
          color: ${theme.colors.black02};
          font-size: ${theme.fonts.size.sm};
          font-weight: ${theme.fonts.weight.medium};
        }
      }
      .price {
        margin-top: 8px;
        font-size: 15px;
        color: #333;
        .free {
          color: ${theme.colors.primary};
        }
      }
    `
  }}
`
export const SortOption = styled.div`
  ${({ theme }) => {
    return css`
      position: relative;
      display: flex;
      .Dropdown-arrow {
        display: none;
      }
      .Dropdown-root {
        .Dropdown-control {
          align-items: center;
          padding: 0;
          border: 0;
          :hover {
            box-shadow: none;
          }
          .Dropdown-placeholder {
            padding: 5px 10px;
            border: solid 1px #eee;
            border-radius: 24px;
            font-size: 1.35rem;
            color: #777;
            :hover {
              box-shadow: none;
              color: ${theme.colors.primary};
              ::after {
                color: ${theme.colors.primary};
                font-weight: ${theme.fonts.weight.medium};
              }
            }
            ::after {
              content: "\f107";
              font-size: 12px;
              font-family: ${theme.fonts.family.fontAwesome};
              color: ${theme.colors.black04};
              margin-left: 5px;
            }
          }
        }
        .Dropdown-menu {
          background-color: white;
          width: 100px;
          right: 0;
          border-radius: 5px;
          overflow: hidden;

          .Dropdown-option {
            display: flex;
            justify-content: center;
            font-size: 1.35rem;
            padding: 1rem 0.6rem;
            border-bottom: solid 1px #eee;
            color: #777;
            :last-child {
              border-bottom: 0;
            }
            &:hover {
              background-color: ${theme.colors.lightgray};
            }
          }
          .Dropdown-option.is-selected {
            background-color: ${theme.colors.lightgray};
            color: #333;
          }
        }
      }
      .Dropdown-root.is-open {
        .Dropdown-placeholder {
          font-size: 1.35rem;
          color: ${theme.colors.primary};
          font-weight: ${theme.fonts.weight.medium};
          ::before {
            color: ${theme.colors.primary};
          }
        }
      }
    `
  }}
`
export const ProductList = styled.div`
  ${({ theme }) => {
    return css`
      display: flex;
      padding: ${theme.paddings.base};
      border: solid 1px #eee;
      border-radius: 5px;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      .img {
        background-color: ${theme.colors.lightgray};
        width: 72px;
        height: 72px;
        border: solid 1px #eee;
        border-radius: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          max-width: 100%;
          height: 100%;
        }
      }

      .cont {
        flex: 1;
        display: flex;
        margin-left: ${theme.margins.sm};
        flex-direction: column;
        justify-content: center;
        .category {
          font-size: 1.1rem;
          color: ${theme.colors.black04};
        }
        .date {
          margin-top: ${theme.margins.sm};
          font-size: 1.1rem;
          color: #555;
        }
        .name {
          margin-top: ${theme.margins.sm};
          font-size: 1.1rem;
          color: #555;
        }
        strong {
          margin-top: ${theme.margins.sm};
          font-size: ${theme.fonts.size.base};
          font-weight: normal;
          color: ${theme.colors.black01};
        }
        .option {
          margin-top: ${theme.margins.base};
          font-size: 1.2rem;
          color: #777;
          span {
            margin-right: ${theme.margins.xs};
          }
        }
      }
    `
  }}
`
export const ProductDetail = styled.div`
  ${({ theme }) => {
    return css`
      border-bottom: solid 5px #eee;
      .swiper-banner {
        position: relative;
      }
      .complete_icon {
        position: absolute;
        background-color: rgba(255, 255, 255, 0.8);
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 99;
        display: flex;
        justify-content: center;
        align-items: center;
        div {
          position: relative;
          transform: rotate(-30deg);
          width: 64px;
          height: 64px;
          border: solid 2px #555;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 12px;
          color: #777;
          ::before {
            content: "";
            position: absolute;
            width: 54px;
            height: 54px;
            z-index: 99;
            border-radius: 50%;
            border: solid 1px #bbb;
          }
        }
      }
      .detail_top {
        padding: ${theme.paddings.xl} ${theme.paddings.base};
        .left {
          flex-direction: column;
        }
        .right {
          flex: 0;
          display: inline-flex;
        }
      }
      .category {
        color: ${theme.colors.black04};
        font-size: ${theme.fonts.size.sm};
        font-weight: 400;
      }
      h3 {
        margin-top: ${theme.paddings.sm};
        font-size: ${theme.fonts.size.lg};
        font-weight: ${theme.fonts.weight.medium};
        line-height: 1.4;
      }
      .cont {
        margin: 30px 0 ${theme.margins.lg};
        .discount {
          color: ${theme.colors.danger};
          font-size: ${theme.fonts.size.sm};
          span {
            margin-left: ${theme.margins.xs};
            color: ${theme.colors.black04};
            text-decoration: line-through;
          }
        }
        .price {
          margin: ${theme.margins.base} 0 0;
          font-size: ${theme.fonts.size.lg};
          color: #333;
        }
      }
    `
  }}
`
export const ProductContents = styled.div`
  ${({ theme }) => {
    return css`
      color: #666;
      .title {
        display: block;
        font-size: 15px;
        font-weight: ${theme.fonts.weight.bold};
        margin: ${theme.margins.base} 0;
        padding: ${theme.paddings.sm} 0;
        border-bottom: solid 2px #ddd;
      }
      .cont {
        padding: ${theme.paddings.base} 0;
        line-height: 1.4;
        border-bottom: dashed 1px #eee;
        strong {
          position: relative;
          display: flex;
          font-size: 14px;
          font-weight: ${theme.fonts.weight.medium};
          margin-bottom: ${theme.margins.sm};
          padding-left: ${theme.paddings.base};
          align-items: center;
          ::before {
            content: "";
            position: absolute;
            left: 0;
            background-color: #bbb;
            width: 4px;
            height: 4px;
          }
        }
        p {
          font-size: ${theme.fonts.size.sm};
        }
      }
      .table {
        padding: ${theme.paddings.sm};
        margin-bottom: ${theme.margins.base};
        div {
          flex: 1;
          align-items: center;
          justify-content: center;
          text-align: center;
          &.right {
            flex: 0 0 25%;
          }
        }
        .title {
          display: flex;
          flex-direction: row;
          margin: 0;
          background-color: ${theme.colors.lightgray};
          border-radius: 5px;
          font-size: 14px;
          font-weight: ${theme.fonts.weight.bold};
          div {
            padding: ${theme.paddings.sm} 0;
          }
        }
        .text {
          display: flex;
          flex-direction: row;
          font-size: 12px;
          border-bottom: solid 1px #eee;
          div {
            padding: ${theme.paddings.sm} 0;
          }
        }
      }
    `
  }}
`
export const ProductTab = styled.div`
  ${({ theme }) => {
    return css`
      .rc-tabs-tabpane {
        outline: none;
      }
      .rc-tabs-nav {
        z-index: 999;
        height: 40px;
      }
      .rc-tabs-nav-list {
        background-color: white;
        display: flex;
        justify-content: space-between;
        border-bottom: solid 1px #ddd;
        flex-direction: row;
      }
      .rc-tabs-tab {
        flex: 1;
        height: 40px;
        .rc-tabs-tab-btn {
          display: flex;
          outline: none;
          justify-content: center;
          align-items: center;
          span {
            position: relative;
            display: flex;
            height: 40px;
            align-items: center;
            color: #666;
            font-size: 14px;
            font-weight: ${theme.fonts.weight.medium};
          }
        }
      }
      .rc-tabs-tab-active {
        .rc-tabs-tab-btn {
          span {
            padding: 0 12px;
            color: ${theme.colors.black01};
            font-size: ${theme.fonts.size.sm};
            font-weight: ${theme.fonts.weight.bold};
            ::before {
              content: "";
              position: absolute;
              bottom: 0;
              left: 0;
              right: 0;
              border-bottom: solid 2px ${theme.colors.black01};
            }
          }
        }
      }
      .rc-tabs-ink-bar {
        display: none;
      }
    `
  }}
`
export const ProductInfo = styled.div`
  ${({ theme }) => {
    return css`
      padding: ${theme.paddings.base};
      .title {
        display: block;
        margin: ${theme.margins.base} 0;
        font-size: 1.3rem;
        font-weight: normal;
      }
      .flextext {
        margin: ${theme.margins.base} 0;
        ul {
          padding: ${theme.paddings.xs} 0;
          li {
            display: flex;
            flex-direction: row;
            height: 40px;
            align-items: center;
            padding: 0 ${theme.paddings.sm};
            border-bottom: solid 1px #e5e5e5;
            .left {
              flex: 1;
              display: flex;
              font-size: 14px;
              color: #777;
            }
            .right {
              flex: 1;
              display: flex;
              text-align: right;
              justify-content: flex-end;
              font-size: 14px;
              color: #444;
              b {
                font-weight: ${theme.fonts.weight.bold};
              }
            }
            .primary {
              color: ${theme.colors.primary};
            }
            &.total {
              color: ${theme.colors.black01};
              .left {
                font-weight: ${theme.fonts.weight.bold};
              }
              .right {
                font-size: 15px;
              }
            }
          }
        }
      }
    `
  }}
`
export const SAccordion = styled.div`
  ${({ theme }) => {
    return css`
      border-top: solid 5px #eee;
      display: flex;
      padding: ${theme.paddings.base} ${theme.paddings.xl};
      .saccordion_continer {
        width: 100%;
        button {
          width: 100%;
          position: relative;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          font-size: ${theme.fonts.size.base};
          font-weight: ${theme.fonts.weight.medium};
          background-color: transparent;
          align-items: center;
          outline: none;
          color: ${theme.colors.black01};
          padding: ${theme.paddings.base} 0;
          span {
            ::before {
              content: "\f106";
              font-size: 2rem;
              font-family: ${theme.fonts.family.fontAwesomeL};
              color: ${theme.colors.black04};
            }
            &.active {
              ::before {
                transform: rotate(-180deg);
              }
            }
          }
        }
        .saccordion_text {
          font-size: ${theme.fonts.size.sm};
          line-height: 1.4;
          color: #444;
        }
      }
      .hide {
        display: none;
      }
    `
  }}
`
export const TabsNav = styled.div`
  ${({ theme }) => {
    return css`
      background-color: ${theme.colors.lightgray};
      padding: 3px;
      border-radius: 35px;
      margin-bottom: ${theme.margins.base};
      ul {
        display: flex;
        justify-content: space-between;
        margin: 0;
        li {
          flex: 1 0 auto;
          a {
            height: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
            color: ${theme.colors.black04};
            font-size: ${theme.fonts.size.base};
          }
          &.active {
            a {
              background-color: white;
              border-radius: 35px;
              color: ${theme.colors.primary};
              font-weight: ${theme.fonts.weight.bold};
            }
          }
        }
      }
    `
  }}
`
export const TabBars = styled.div`
  ${({ theme }) => {
    return css`
      .rc-tabs-nav {
        z-index: 999;
        padding: ${theme.paddings.base} ${theme.paddings.xl};
      }
      .rc-tabs-nav-operations {
        display: none;
      }
      .rc-tabs-nav-list {
        background-color: ${theme.colors.lightgray};
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        border-radius: 30px;
        padding: 3px;
      }
      .rc-tabs-tab {
        display: flex;
        flex: 1;
        .rc-tabs-tab-btn {
          position: relative;
          display: flex;
          flex: 1;
          height: 40px;
          justify-content: center;
          align-items: center;
          span {
            display: flex;
            align-items: center;
            color: ${theme.colors.black03};
            font-size: 15px;
          }
        }
      }
      .rc-tabs-tab-active {
        background-color: white;
        border-radius: 30px;
        .rc-tabs-tab-btn {
          span {
            color: ${theme.colors.primary};
            font-weight: ${theme.fonts.weight.bold};
          }
        }
      }
      .rc-tabs-ink-bar {
        display: none;
      }
    `
  }}
`
export const SearchContainer = styled.div`
  ${({ theme }) => {
    return css`
      form {
        padding: ${theme.paddings.base} ${theme.paddings.xl};
        .inner {
          display: flex;
          flex-direction: row;
          align-items: center;
          background-color: ${theme.colors.lightgray};
          border-radius: 8px;
          .searchBtn {
            width: 40px;
            align-items: center;
            justify-content: center;
            background-color: transparent;
          }
          input {
            background-color: transparent;
            width: 100%;
            padding: 12px 10px 10px 0;
            justify-content: center;
            font-size: ${theme.fonts.size.sm};
            ::placeholder {
              color: ${theme.colors.black04};
              font-size: ${theme.fonts.size.sm};
            }
          }
        }
      }
    `
  }}
`
export const SearchList = styled.div`
  ${({ theme }) => {
    return css`
      ul {
        height: 180px;
        overflow-x: hidden;
        overflow-y: scroll;
        ::-webkit-scrollbar {
          display: none;
        }
        li {
          width: 100%;
          padding: 0 ${theme.paddings.xl};
          label {
            padding: ${theme.paddings.sm} 0;
            position: relative;
            display: flex;
            align-items: center;
            color: #666;
            border-bottom: solid 1px #eee;
            input {
              position: relative;
            }
            input[type="checkbox"] {
              position: absolute;
              right: 0;
              border: solid 1px #ddd;
              display: flex;
              align-items: center;
              justify-content: center;
              width: 24px;
              height: 24px;
              border-radius: 16px;
              ::after {
                color: #bbb;
              }
              :checked {
                background-color: ${theme.colors.primary};
                border: solid 1px ${theme.colors.primary};
                ::after {
                  content: "\f00c";
                  font-family: ${theme.fonts.family.fontAwesome};
                  color: white;
                }
              }
            }
            .name {
              margin-left: ${theme.margins.sm};
              font-size: ${theme.fonts.size.sm};
              font-weight: ${theme.fonts.weight.medium};
              color: ${theme.colors.black01};
              span {
                display: block;
                color: ${theme.colors.black04};
                font-size: ${theme.fonts.size.sm};
              }
            }
          }
        }
      }
      .bottom {
        padding: ${theme.paddings.base} ${theme.paddings.xl};
        a,
        button {
          margin-left: 0 !important;
          height: 60px;
          line-height: 60px;
        }
      }
    `
  }}
`
export const SOrderItem = styled.div`
  ${({ theme }) => {
    return css`
      background-color: white;
      overflow: hidden;
      border-radius: 8px;
      border: solid 1px #eee;
      font-family: ${theme.fonts.family.base};
      .title {
        margin: ${theme.margins.sm} 0;
        font-size: 14px;
        color: ${theme.colors.black01};
      }
      .item_top {
        display: flex;
        padding: ${theme.paddings.base} ${theme.paddings.base}
          ${theme.paddings.sm};
        align-items: center;
        .left {
          justify-content: flex-start;
          span {
            font-size: ${theme.fonts.size.sm};
            color: ${theme.colors.black03};
          }
        }
        .right {
          flex: 0 0 auto;
          justify-content: flex-end;
          text-align: right;
          a {
            font-size: 13px;
            color: ${theme.colors.primary};
          }
        }
      }
      .item_content {
        padding: ${theme.paddings.base};
      }
      .product_list {
        margin-top: 5px;
        display: flex;
        width: 100%;
        align-items: center;
        a {
          display: flex;
          width: 100%;
          flex-direction: row;
          align-items: center;
        }
        .img {
          position: relative;
          background-color: ${theme.colors.lightgray};
          width: 72px;
          height: 72px;
          border-radius: 5px;
          overflow: hidden;
          display: flex;
          flex: 0 0 auto;
          justify-content: center;
          align-items: center;
          img {
            max-width: 100%;
            height: auto;
          }
        }
        .complete_icon {
          position: absolute;
          background-color: rgba(0, 0, 0, 0.6);
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          z-index: 99;
          display: flex;
          justify-content: center;
          align-items: center;
          div {
            position: relative;
            width: 64px;
            height: 64px;
            border: solid 2px white;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            font-size: ${theme.fonts.size.sm};
            line-height: 1.1;
            color: white;
          }
        }
        .cont {
          display: flex;
          flex: 1;
          padding-left: ${theme.margins.base};
          flex-direction: column;
          justify-content: center;
          .date {
            font-size: 13px;
            color: ${theme.colors.black03};
          }
          .category {
            color: ${theme.colors.black03};
            font-size: 13px;
            margin-bottom: ${theme.margins.xs};
          }
          strong {
            display: block;
            width: 180px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            font-size: ${theme.fonts.size.base};
            color: ${theme.colors.black01};
          }
          .option {
            margin-top: ${theme.margins.sm};
            font-family: ${theme.fonts.family.base};
            font-size: 13px;
            color: #777;
            .text {
              display: inline-block;
              width: 150px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              margin-left: 5px;
            }
          }
          .info {
            margin-bottom: ${theme.margins.xs};
            span {
              position: relative;
              color: #999;
              font-size: 13px;
              &.category {
                color: ${theme.colors.black04};
              }
            }
          }
        }
      }
      .item_bottom {
        width: 100%;
        display: flex;
        align-items: center;
        flex-direction: row;
        padding: 0 ${theme.paddings.base} ${theme.paddings.base};
        .left {
          span {
            color: ${theme.colors.black03};
            font-weight: ${theme.fonts.weight.medium};
            font-size: 13px;
          }
        }
        .right {
          span {
            color: ${theme.colors.black02};
            font-size: ${theme.fonts.size.sm};
            font-weight: ${theme.fonts.weight.base};
            b {
              font-weight: ${theme.fonts.weight.bold};
            }
          }
        }
      }
    `
  }}
`
export const SOrderInfo = styled.div`
  ${({ theme }) => {
    return css`
      padding: ${theme.paddings.base};
      .title {
        margin: ${theme.margins.base} 0;
        font-size: 13px;
        font-weight: ${theme.fonts.weight.bold};
      }
      .flextext {
        ul {
          li {
            display: flex;
            flex-direction: row;
            height: 35px;
            align-items: center;
            border-bottom: solid 1px #eee;
            .left {
              width: 110px;
              font-size: 14px;
              color: #777;
            }
            .right {
              flex: 1;
              text-align: right;
              justify-content: flex-end;
              font-size: 14px;
              color: ${theme.colors.black01};
              b {
                font-weight: ${theme.fonts.weight.bold};
              }
            }
            &.total {
              .left {
                font-size: 15px;
                color: ${theme.colors.black01};
                font-weight: ${theme.fonts.weight.medium};
              }
              .right {
                font-size: 15px;
                color: ${theme.colors.black01};
              }
            }
          }
        }
      }
      .primary {
        color: ${theme.colors.primary};
      }
    `
  }}
`
export const SProvision = styled.div`
  ${({ theme }) => {
    return css`
      display: flex;
      flex-direction: column;
      height: 100%;
      background-color: ${theme.colors.lightgray};
      padding: ${theme.paddings.base};
      line-height: 1.4;
      h1 {
        font-size: ${theme.fonts.size.lg};
        text-align: center;
        padding: ${theme.paddings.base};
        color: ${theme.colors.black01};
      }
      .inner {
        flex: 1 0 auto;
        margin-top: ${theme.paddings.base};
        padding: ${theme.paddings.lg};
        background-color: white;
        font-size: 1.4rem;
        line-height: 1.4;
        color: #666;
      }
    `
  }}
`
export const SModal = styled.div`
  ${({ theme }) => {
    return css`
      .modal {
        display: none;
        position: fixed;
        bottom: 0;
        left: 0;
        right: -0;
        z-index: 9999;
        section {
          box-shadow: 0 -3px 3px rgba(0, 0, 0, 0.1);
          width: 100%;
          max-width: 750px;
          margin: 0 auto;
          border-radius: 0.3rem;
          background-color: #fff;
          /* 팝업이 열릴때 스르륵 열리는 효과 */
          animation: modal-show 0.3s;
          overflow: hidden;
          main {
          }
        }
        header {
          position: relative;
          border-bottom: solid 1px #ddd;
          button {
            width: 100%;
            height: 40px;
            font-weight: 700;
            justify-content: center;
            align-items: center;
            display: flex;
            font-size: 21px;
            font-weight: 700;
            border: 0;
            color: #999;
            background-color: transparent;
            &:hover {
              background-color: ${theme.colors.lightgray};
            }
          }
        }
        footer {
          height: 50px;
          flex: 1;
          display: flex;
          border-top: solid 1px #eee;
        }
        .price-total {
          padding: 1rem 0;
          b {
            font-weight: bold;
          }
        }
      }
      .modal.openModal {
        display: flex;
        align-items: center;
      }
      @keyframes modal-show {
        from {
          margin-top: -100px;
        }
        to {
          margin-top: 0;
        }
      }
    `
  }}
`
export const Stepbox = styled.div`
  ${({ theme }) => {
    return css`
      .btn {
        background-color: ${theme.colors.lightgray};
        display: flex;
        width: 100%;
        height: 45px;
        flex-direction: row;
        align-items: center;
        padding: 0 ${theme.paddings.base};
        border-bottom: solid 1px #eee;
        font-size: 1.4rem;
        color: ${theme.colors.black04};
        :disabled {
          background-color: ${theme.colors.lightgray};
        }
        span {
          flex: 1;
          text-align: left;
        }
        &:hover {
          font-weight: ${theme.fonts.weight.medium};
          color: ${theme.colors.primary};
          .icon {
            color: ${theme.colors.primary};
          }
        }
      }
      .btn.active {
        background-color: ${theme.colors.lightprimary};
        font-weight: ${theme.fonts.weight.medium};
        color: ${theme.colors.primary};
        .icon {
          color: ${theme.colors.primary};
        }
      }
    `
  }}
`
export const Stepbtn = styled.div`
  ${({ theme }) => {
    return css`
      display: flex;
      flex-direction: row;
      border-bottom: solid 1px #eee;
      button {
        position: relative;
        background-color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        padding: 1.2rem ${theme.paddings.sm};
        font-size: 1.4rem;
        color: #bbb;
        &.active {
          span {
            color: ${theme.colors.black01};
            font-size: ${theme.fonts.size.sm};
            font-weight: ${theme.fonts.weight.bold};
            ::before {
              content: "";
              position: absolute;
              left: 24px;
              right: 24px;
              bottom: -2px;
              border-bottom: solid 2px ${theme.colors.black01};
            }
          }
        }
      }
    `
  }}
`
export const InputGroup = styled.div`
  ${({ theme }) => {
    return css`
      height: 40px;
      flex: 1;
      display: flex;
      button {
        background-color: #e4e7ed;
        border: solid 1px #eee;
        width: 46px;
        height: 40px;
        color: ${theme.colors.black01};
        :first-child {
          border-right: 0;
          border-top-left-radius: 4px;
          border-bottom-left-radius: 4px;
        }
        :last-child {
          border-left: 0;
          border-top-right-radius: 4px;
          border-bottom-right-radius: 4px;
        }
      }
      input {
        flex: 1 1 auto;
        background-color: #fff;
        text-align: center;
        border: solid 1px #eee;
        font-size: ${theme.fonts.size.base};
      }
    `
  }}
`
export const MainTitle = styled.div`
  width: 100%;
  margin: 24px 0;
`
export const MainText = styled.div`
  ${({ theme }) => {
    return css`
      line-height: 30px;
      font-size: ${theme.fonts.size.xl};
      font-weight: ${theme.fonts.weight.bold};
      font-family: ${theme.fonts.family.base};
      color: ${theme.colors.black01};
      .TextBlue {
        color: ${theme.colors.primary};
      }
    `
  }}
`
export const SubText = styled.div`
  ${({ theme }) => {
    return css`
      margin-top: 12px;
      font-size: ${theme.fonts.size.sm};
      color: ${theme.colors.black02};
      text-align: center;
    `
  }}
`
export const ListTitle = styled.div`
  ${({ theme }) => {
    return css`
      margin: 20px 0;
      font-weight: ${theme.fonts.weight.medium};
      font-size: ${theme.fonts.size.lg};
      color: ${theme.colors.black02};
      span {
        color: ${theme.colors.warning};
        font-weight: ${theme.fonts.weight.bold};
      }
    `
  }}
`
export const Rviewitem = styled.div`
  ${({ theme }) => {
    return css`
      .star {
        margin-right: 3px;
        :last-child {
          margin-right: 0;
        }
        ::before {
          content: "\f005";
          color: #bbb;
          font-size: ${theme.fonts.size.sm};
          font-family: ${theme.fonts.family.fontAwesomeS};
        }
        &.on {
          ::before {
            content: "\f005";
            color: ${theme.colors.primary};
            font-size: ${theme.fonts.size.sm};
            font-family: ${theme.fonts.family.fontAwesomeS};
          }
        }
      }
      dl {
        display: flex;
        flex-direction: row;
        dt {
          .img {
            background-color: white;
            width: 44px;
            height: 44px;
            border-radius: 50%;
            overflow: hidden;
            img {
              max-width: 100%;
              height: 100%;
            }
          }
        }
        dd {
          width: 100%;
          margin-left: ${theme.margins.base};
          position: relative;
          .review {
            margin-top: ${theme.margins.base};
            .text {
              flex: 1;
              font-size: ${theme.fonts.size.sm};
              color: #555;
              line-height: 1.4;
            }
          }
        }
      }
      .userInfo {
        span {
          color: ${theme.colors.black03};
          font-size: ${theme.fonts.size.sm};
        }
      }
      .reply {
        margin: ${theme.margins.sm} 0 ${theme.margins.base};
        .review {
          background-color: #fbfbfb;
          border-left: solid 2px ${theme.colors.primary};
          padding: ${theme.paddings.base};
          .text {
            margin-top: 10px;
            flex: 1;
            font-size: ${theme.fonts.size.sm};
            color: #555;
            line-height: 1.4;
          }
        }
      }
      .group_btn {
        margin: ${theme.margins.sm} 0;
      }
    `
  }}
`
export const SDatePicker = styled.div`
  ${({ theme }) => {
    return css`
      padding: ${theme.paddings.base} ${theme.paddings.xl};
      border-bottom: solid 5px #eee;
      .react-datepicker {
        display: flex;
        flex: 1 0 auto;
        width: 100%;
        border: 0;
        justify-content: center;
        align-items: center;
        .react-datepicker__month-container {
          width: 100%;
          .react-datepicker__header {
            background-color: transparent;
            padding: 0;
            border: 0;
          }
        }
      }
      .react-datepicker__day-names {
        display: flex;
        justify-content: space-between;
        .react-datepicker__day-name {
          width: 40px;
          height: 40px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 14px;
          color: #666;
        }
      }
      .custom_header {
        display: flex;
        height: 40px;
        justify-content: center;
        flex-direction: row;
        align-items: center;
        .custom_header_month {
          flex: 1 1 auto;
          font-size: ${theme.fonts.size.base};
          font-weight: ${theme.fonts.weight.medium};
          color: ${theme.colors.black02};
        }
        .prev_button {
          width: 40px;
          background-color: transparent;
          :before {
            content: "\f104";
            font-family: ${theme.fonts.family.fontAwesome};
            font-size: ${theme.fonts.size.lg};
          }
        }
        .next_button {
          width: 40px;
          background-color: transparent;
          :before {
            content: "\f105";
            font-family: ${theme.fonts.family.fontAwesome};
            font-size: ${theme.fonts.size.lg};
          }
        }
      }
      .react-datepicker__month {
        margin: 0;
      }
      .react-datepicker__week {
        display: flex;
        justify-content: space-between;
        .react-datepicker__day {
          position: relative;
          display: flex;
          height: 40px;
          width: 40px;
          justify-content: center;
          align-items: center;
          font-size: 14px;
          color: ${theme.colors.black01};
          background-color: ${theme.colors.lightprimary};
          border-radius: 4px;
          &.react-datepicker__day--disabled,
          &.react-datepicker__day--outside-month {
            background-color: white;
            color: #ddd;
          }
          &.react-datepicker__day--excluded:hover {
            background-color: white;
            color: #ddd;
          }
          &.react-datepicker__day--disabled:hover {
            background-color: ${theme.colors.primary};
            color: white;
          }
          :hover,
          &.react-datepicker__quarter-text:hover,
          &.react-datepicker__month-text:hover,
          &.react-datepicker__year-text:hover {
            background-color: ${theme.colors.primary};
            color: white;
          }
          &.react-datepicker__day--selected {
            background-color: ${theme.colors.primary};
            color: white;
            border-radius: 4px;
          }
          &.react-datepicker__day--keyboard-selected {
            background-color: white;
          }
          &.react-datepicker__day--today {
          }
          .react-datepicker__day--keyboard-selected,
          .react-datepicker__month-text--keyboard-selected,
          .react-datepicker__quarter-text--keyboard-selected,
          .react-datepicker__year-text--keyboard-selected {
            border-radius: 4px;
            background-color: #aaa;
            color: ${theme.colors.primary};
          }
        }
      }
    `
  }}
`
export const TicketList = styled.div`
  ${({ theme }) => {
    return css`
      padding: ${theme.paddings.base} ${theme.paddings.xl};
      .title {
        font-size: ${theme.fonts.size.sm};
        color: ${theme.colors.black01};
        font-weight: ${theme.fonts.weight.bold};
      }
      ul {
        height: 180px;
        overflow-x: hidden;
        overflow-y: scroll;
        white-space: nowrap;
        z-index: 999;
        ::-webkit-scrollbar {
          display: none;
        }
      }
      .list_none {
        margin-bottom: ${theme.margins.sm};
        div {
          background-color: #f5f5f5;
          border-radius: 5px;
          margin-top: 6px;
          padding: 30px 0;
          text-align: center;
          font-size: 13px;
          color: #999;
        }
      }
      .bottom {
        padding: ${theme.paddings.base} 0;
      }
      .ticketItem {
        display: flex;
        flex-direction: row;
        border-bottom: solid 1px #eee;
        padding: ${theme.paddings.sm};
        align-items: center;
        strong {
          margin-bottom: ${theme.paddings.xs};
          font-size: ${theme.fonts.size.base};
          line-height: 1.3;
          font-weight: ${theme.fonts.weight.medium};
          color: ${theme.colors.black01};
        }
        .left {
          display: flex;
          flex-direction: column;
          margin-top: ${theme.margins.base};
          align-items: flex-start;
          span {
            font-size: ${theme.fonts.size.sm};
            color: ${theme.colors.black03};
            &.free {
              color: ${theme.colors.primary};
              font-weight: ${theme.fonts.weight.bold};
            }
          }
        }
        input[type="checkbox"] {
          border: solid 1px #ddd;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 24px;
          height: 24px;
          border-radius: 16px;
          ::after {
            color: #bbb;
          }
        }
        &.active {
          input[type="checkbox"] {
            background-color: ${theme.colors.primary};
            border: solid 1px ${theme.colors.primary};
            ::after {
              content: "\f00c";
              font-family: ${theme.fonts.family.fontAwesomeL};
              color: white;
              font-size: 14px;
            }
          }
        }
        &.soldout {
          background-color: ${theme.colors.lightgray};
          strong {
            color: #bbb;
          }
          .cont {
            color: #bbb;
          }
        }
      }
    `
  }}
`
export const FormBox = styled.div`
  ${({ theme }) => {
    return css`
      form {
        width: 100%;
        display: flex;
        padding: 0 ${theme.paddings.xl};
        flex-direction: column;
        justify-content: center;
        min-height: 165px;
        .inner {
          border: solid 1px #eee;
          border-radius: 8px;
          overflow: hidden;
        }
      }
      .form_group {
        display: flex;
        flex-direction: column;
        position: relative;
        margin-bottom: ${theme.margins.lg};
        label {
          color: ${theme.colors.black03};
          font-size: ${theme.fonts.size.xs};
          margin-bottom: ${theme.margins.xs};
        }
        input {
          height: 40px;
          padding: 0 10px;
          border: solid 1px #e5e5e5;
          border-radius: 4px;
          overflow: hidden;
          font-size: ${theme.fonts.size.sm};
        }
      }
      .form_btn {
        padding: ${theme.paddings.base} ${theme.paddings.xl};
      }
      .guest_btn {
        display: flex;
        flex: 0 0 auto;
        justify-content: center;
        margin-top: 20px;
        .link {
          font-size: 14px;
          border-bottom: solid 1px #ddd;
        }
      }
    `
  }}
`
export const UserList = styled.div`
  ${({ theme }) => {
    return css`
      border-bottom: solid 5px #eee;
      padding: ${theme.paddings.lg} 0;
      ul {
        padding: 0 ${theme.paddings.xl};
        height: 100px;
        overflow-x: scroll;
        overflow-y: hidden;
        white-space: nowrap;
        ::-webkit-scrollbar {
          display: none;
        }
        display: flex;
        flex-direction: row;
        li {
          display: flex;
          flex-direction: column;
          padding: ${theme.paddings.sm};
          align-items: center;
          justify-content: center;
          p {
            width: 50px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            margin-top: ${theme.margins.xs};
            font-size: ${theme.fonts.size.sm};
            font-weight: ${theme.fonts.weight.medium};
            color: ${theme.colors.black01};
            text-align: center;
            span {
              display: block;
              color: ${theme.colors.black04};
              font-size: ${theme.fonts.size.xs};
            }
          }
          .close {
            background: ${theme.colors.black01};
            position: absolute;
            z-index: 11;
            top: -7px;
            right: -7px;
            display: flex;
            width: 12px;
            height: 12px;
            border-radius: 16px;
            align-items: center;
            justify-content: center;
            ::before {
              content: "\f00d";
              font-family: ${theme.fonts.family.fontAwesomeL};
              font-size: 13px;
              line-height: 0;
              color: #fff;
            }
          }
        }
        .check_list_done {
          flex: 1;
          display: flex;
          align-items: center;
          p {
            margin-top: ${theme.margins.sm};
            font-size: 1.3rem;
            color: #777;
            width: auto;
          }
        }
      }
    `
  }}
`

export const GlobalStyles = createGlobalStyle`
    ${reset};
    ${({ theme }) => {
      return css`
        * {
          box-sizing: border-box;
        }
        html {
          font-size: 62.5%; //1rem = 10px;
          height: 100%;
        }
        body {
          height: 100%;
          font-family: ${theme.fonts.family.base}!importent;
          line-height: 1.4;
          ${theme.device.desktop} {
            background-color: #eee;
          }
          ${theme.device.mobile} {
            background-color: white;
          }
        }
        #root {
          display: flex;
          flex-direction: column;
          height: 100%;
        }
        textarea,
        input {
          font-family: ${theme.fonts.family.base};
          font-size: 14px;
          ::placeholder {
            color: ${theme.colors.black04};
          }
        }
        input {
          all: unset;
        }
        textarea {
          border: none;
          background-color: transparent;
          resize: none;
          outline: none;
        }
        button,
        a {
          cursor: pointer;
        }
        button {
          border: 0;
        }
        a {
          text-decoration: none;
          color: inherit;
        }
        .m-t {
          margin-top: 1rem;
        }
        img {
          max-width: 100%;
        }
        b,
        strong {
          font-weight: ${theme.fonts.weight.bold};
        }
      `
    }}
`
