import { useState } from "react"
import React from "react"
import { withRouter, Link } from "react-router-dom"

import styled, { css } from "styled-components"
import { Row, Col, SOrderItem, Dot } from "../../../../../styles/styles"
import SLabel from "../../../../Labels"
import ReactMoment from "react-moment"
import NumberFormat from "react-number-format"
import renderHTML from "react-render-html"
import ProductOrderProduct from "./ProductOrderProduct"
import { graphqlUrl } from "../../../../../Config/Env"
import {
  dayOfWeekName,
  isPayName,
  placeTypeName,
} from "../../../../../Config/Util"

const SSOrderItem = styled(SOrderItem)`
  ${({ theme }) => {
    return css`
      background-color: transparent;
      margin-bottom: 0px;
      border-radius: 0;
      border: 0;
      padding: 0;
      .item_content {
        border-radius: 8px;
        border: solid 1px #eee;
        background-color: white;
        .item_top {
          padding: 0 0 ${theme.paddings.sm};
        }
        .product_list {
          flex-direction: column;
          padding: 0;
        }
        .item_bottom {
          padding: ${theme.paddings.base} 0 0;
        }
      }
    `
  }}
`

function Product({ history, match, location, data }) {
  const {
    TicketAccountOrderDetail: {
      ticketOrderProducts,

      ticketProduct,

      isDiscount,
      discountPrice,
      price,

      count,
      totalCount,
      totalPrice,

      createdAt,
    },
  } = data

  const [isDetail, setIsDetail] = useState(false)
  const toggleView = () => {
    setIsDetail(!isDetail)
  }
  const CoverImage =
    ticketProduct.coverImage === null
      ? "../../assets/img/product/product-default.jpg"
      : `${graphqlUrl}${ticketProduct.coverImage}`
  const startDate = new Date(ticketProduct.applyStartDateTime)
  const endDate = new Date(ticketProduct.applyEndDateTime)

  return (
    <SSOrderItem>
      <div className={`item_content`}>
        <div className="item_top">
          <div className="left">
            <span>
              구매일자 :&nbsp;
              <ReactMoment format="YYYY. MM. DD HH:mm">{createdAt}</ReactMoment>
            </span>
          </div>
        </div>
        <div className="product_list">
          <Link to={`/products/${ticketProduct.id}`}>
            <div className="img">
              <img src={CoverImage} alt={ticketProduct.name} />
            </div>
            <div className="cont">
              <span className="category">{ticketProduct.store.name}</span>
              <strong>{ticketProduct.name}</strong>
              <div className="option">
                유효기간 :
                <ReactMoment format="YYYY.MM.DD">{startDate}</ReactMoment>(
                {dayOfWeekName(startDate.getDay())}) ~{" "}
                <ReactMoment format="YYYY.MM.DD">{endDate}</ReactMoment>(
                {dayOfWeekName(endDate.getDay())})
              </div>
            </div>
          </Link>
        </div>
        <Row className="item_bottom">
          <Col left className="left">
            <span>
              수신자. {ticketOrderProducts[0].name}님
              {renderHTML(
                ticketOrderProducts.length > 1
                  ? `외 <b>${ticketOrderProducts.length - 1}</b>명`
                  : ``
              )}
            </span>
          </Col>
          <Col right className="right">
            <span>
              <b>
                <NumberFormat
                  value={totalPrice}
                  displayType={"text"}
                  thousandSeparator={true}
                />
              </b>
              드림
            </span>
            <Dot />
            <span>
              <b>{totalCount}</b>개
            </span>
          </Col>
        </Row>
      </div>
    </SSOrderItem>
  )
}

export default withRouter(Product)
