import React, { useState, useEffect } from "react"
import { withRouter } from "react-router-dom"
import { useQuery, useMutation } from "@apollo/client"
import {
  ACCOUNT_REVIEW_QUERY,
  ACCOUNT_REVIEWEDIT_MUTATION,
  ACCOUNT_READYREVIEWS_QUERY,
} from "../../../Config/Queries"
import { useForm } from "react-hook-form"
import FormError from "../../../Components/FormError"

import { Layout } from "../../../Components/Layouts/Layout"
import PageTitle from "../../../Components/Layouts/PageTitle"
import styled, { css } from "styled-components"
import { Container, FooterButton } from "../../../styles/styles"
import ReactStar from "react-rating-stars-component"
import Button from "../../../Components/Buttons"
import Loadings from "../../../Components/Loading"
import qs from "qs"

const SContainer = styled(Container)`
  ${({ theme }) => {
    return css`
      padding: ${theme.paddings.xl};
    `
  }}
`
const Stars = styled.i`
  ${({ theme }) => {
    return css`
      padding: 0 0.2rem;
      ::before {
        content: "\f005";
        font-family: ${theme.fonts.family.fontAwesomeL};
        font-size: ${theme.fonts.size.title};
        color: #bbb;
      }
      ${(props) =>
        props.option === "active" &&
        css`
          ::before {
            content: "\f005";
            color: ${theme.colors.primary};
            font-family: ${theme.fonts.family.fontAwesomeS};
          }
        `}
    `
  }}
`
const SFormbox = styled.div`
  ${({ theme }) => {
    return css`
      background-color: white;
      overflow: hidden;
      flex: 1 0 auto;
      form {
        display: flex;
        flex-direction: column;
        .title {
          display: block;
          margin-bottom: ${theme.margins.base};
        }
        .form_group {
          label {
            display: flex;
            flex-direction: column;
            color: ${theme.colors.black03};
            font-size: ${theme.fonts.size.sm};
            margin-bottom: ${theme.margins.sm};
          }
          textarea {
            width: 100%;
            height: 200px;
            padding: ${theme.paddings.base};
            border: solid 1px #eee;
            border-radius: 8px;
            font-size: ${theme.fonts.size.base};
            font-family: ${theme.fonts.family.base};
          }
        }
        .button {
          margin-top: ${theme.margins.sm};
        }
      }
    `
  }}
`
const Scorebox = styled.div`
  ${({ theme }) => {
    return css`
      display: flex;
      flex-direction: column;
      padding: 30px 0;
      justify-content: center;
      align-items: center;
      .title {
        margin-bottom: ${theme.margins.lg};
        color: ${theme.colors.black01};
        font-size: ${theme.fonts.size.xl};
        font-weight: ${theme.fonts.weight.medium};
      }
      .text {
        margin-top: ${theme.margins.base};
        color: ${theme.colors.primary};
        font-size: 14px;
      }
    `
  }}
`

function Edit({ history, match, location }) {
  const id = match.params.id

  const queryString = qs.parse(location.search.substr(1))
  const redirect = queryString.redirect
    ? queryString.redirect
    : "/account/completeReviews"

  const [ticketProductId, setTicketProductId] = useState(null)
  const [score, setScore] = useState(0)

  const { data, loading } = useQuery(ACCOUNT_REVIEW_QUERY, {
    fetchPolicy: "network-only",
    variables: {
      id,
    },
  })

  const [reviewEditMutation, { loading: editLoading }] = useMutation(
    ACCOUNT_REVIEWEDIT_MUTATION,
    {
      refetchQueries: () => [
        {
          query: ACCOUNT_READYREVIEWS_QUERY,
          variables: {
            orderBy: "id_DESC",
            skip: 0,
            first: 10,
          },
        },
      ],
    }
  )

  const { register, handleSubmit, errors, setValue } = useForm()

  const onSubmit = async (data) => {
    try {
      const {
        data: { TicketAccountReviewEdit },
      } = await reviewEditMutation({
        variables: {
          id: id,
          score: score,
          contents: data.contents,
        },
      })

      if (TicketAccountReviewEdit) {
        let uri = ``
        if (redirect === "/products") {
          uri = `${redirect}/${ticketProductId}`
        }
        if (redirect === "/account/completeReviews") {
          uri = `${redirect}`
        }
        history.push(uri)
      }
    } catch (e) {
      console.log(e)
    }
  }
  useEffect(() => {
    if (!loading) {
      const {
        TicketAccountReviewDetail: { ticketProduct, score, contents },
      } = data
      setTicketProductId(ticketProduct.id)
      setScore(score)
      setValue("contents", contents)
    }
  }, [data, loading, setValue])

  if (!loading) {
    const {
      TicketAccountReviewDetail: { score },
    } = data
    return (
      <>
        <Layout isNav={false} isFooterButton={true}>
          <PageTitle title="상품리뷰하기" />
          <SContainer>
            <SFormbox>
              <Scorebox>
                <strong className="title">선물은 만족하셨나요?</strong>
                <ReactStar
                  value={score}
                  onChange={(newRating) => {
                    setScore(newRating)
                  }}
                  emptyIcon={<Stars />}
                  filledIcon={<Stars option="active" />}
                />
                <p className="text">별점을 선택해주세요.</p>
              </Scorebox>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="form_group">
                  <label for="contents">선물후기 수정</label>
                  <textarea
                    name="contents"
                    placeholder="선물후기를 입력해주세요."
                    ref={register({
                      required: "선물후기를 입력해주세요.",
                    })}
                  ></textarea>
                </div>
                <FormError message={errors?.contents?.message} />
                <FooterButton>
                  <div>
                    <Button
                      type="submit"
                      disabled={editLoading}
                      color="primary"
                      width="full"
                    >
                      {editLoading ? "처리중..." : "후기수정"}
                    </Button>
                  </div>
                </FooterButton>
              </form>
            </SFormbox>
          </SContainer>
        </Layout>
      </>
    )
  } else {
    return <Loadings desc="로딩..." />
  }
}
export default withRouter(Edit)
