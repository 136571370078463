import styled, { css } from "styled-components"
import { withRouter } from "react-router-dom"
import { useMutation } from "@apollo/client"
import { GUEST_LOGIN_MUTATION } from "../../Config/Queries"

import { useForm } from "react-hook-form"
import { FooterButton, MainText, MainTitle } from "../../styles/styles"
import { Layout } from "../../Components/Layouts/Layout"
import PageTitle from "../../Components/Layouts/PageTitle"
import Alter from "../../Components/Alter"
import Button from "../../Components/Buttons"
import FormError from "../../Components/FormError"
import { toast } from "react-toastify"

const FormBox = styled.div`
  ${({ theme }) => {
    return css`
      padding: ${theme.paddings.base} ${theme.paddings.lg};
      form {
        margin-top: 35px;
        .form_group {
          display: flex;
          flex-direction: column;
          position: relative;
          margin-bottom: ${theme.margins.lg};
          label {
            color: ${theme.colors.black04};
            font-size: 13px;
            margin-bottom: ${theme.margins.xs};
          }
          input {
            height: 44px;
            padding: 0 ${theme.paddings.base};
            border: solid 1px #eee;
            border-radius: 4px;
            font-size: ${theme.fonts.size.base};
            &::placeholder {
              font-size: 1.3rem;
              color: #bbb;
            }
          }
        }
      }
      .guest_btn {
        display: flex;
        flex: 0 0 auto;
        justify-content: center;
        margin-top: 20px;
        .link {
          font-size: 14px;
          border-bottom: solid 1px #ddd;
        }
      }
    `
  }}
`

function Login({ history, match, location }) {
  const [guestLoginMutation, { loading }] = useMutation(GUEST_LOGIN_MUTATION)
  const { register, handleSubmit, errors, formState } = useForm({
    mode: "onChange",
  })

  const onSubmit = async (data) => {
    try {
      if (loading) {
        return
      }

      const {
        data: { TicketGuestLogin: id },
      } = await guestLoginMutation({
        variables: {
          ticketNumber: data.ticketNumber,
          phone: data.phone,
        },
      })

      if (id) {
        history.replace(`/guest/ticketDetail`, { id })
      }
    } catch (e) {
      console.log(e)
      var error = e.toString()
      error = error.replace("Error:", "")
      toast.error(error)
    }
  }

  return (
    <Layout isNav={true}>
      <PageTitle title={"홈"} />
      <FormBox>
        <MainTitle>
          <MainText>
            <span className="TextBlue">티켓번호, 전화번호</span>를
          </MainText>
          <MainText>입력해주세요.</MainText>
        </MainTitle>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="form_group">
            <label>티켓번호</label>
            <input
              type="text"
              name="ticketNumber"
              placeholder="티켓번호를 입력해주세요."
              ref={register({
                required: "티켓번호를 입력해주세요.",
              })}
              autocomplete="off"
              autoFocus
            />
          </div>
          <FormError message={errors?.ticketNumber?.message} />
          <div className="form_group">
            <label>전화번호</label>
            <input
              type="text"
              name="phone"
              placeholder="‘-‘ 제외하고 전화번호를 입력해주세요."
              ref={register({
                required: "전화번호를 입력해주세요.",
              })}
              autocomplete="off"
            />
          </div>
          <FormError message={errors?.phone?.message} />
          <Alter border="radius" color="lightgray">
            <p>
              * 수신받은 문자메시지에 티켓번호, 휴대전화 번호를 입력해주세요.
            </p>
          </Alter>
          <FooterButton>
            <div>
              <Button
                type="submit"
                color="primary"
                width="full"
                disabled={!formState.isValid || loading}
              >
                {loading ? "처리중..." : "확인"}
              </Button>
            </div>
          </FooterButton>
        </form>
      </FormBox>
    </Layout>
  )
}
export default withRouter(Login)
