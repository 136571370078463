import styled, { css } from "styled-components"
import { useHistory } from "react-router-dom"
import Link from "../Components/Links"
import { Layout } from "../Components/Layouts/Layout"
import PageTitle from "../Components/Layouts/PageTitle"

const Notfound = styled.div`
  ${({ theme }) => {
    return css`
      display: flex;
      color: ${theme.colors.gray};
      font-size: ${theme.fonts.size.sm};
      text-align: center;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      height: 100%;
      .img {
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          height: auto;
        }
      }
      .cont {
        margin-top: 10px;
        h1 {
          font-size: ${theme.fonts.size.xl};
          color: #333;
        }
        .text {
          margin-top: 10px;
          font-size: 14px;
          color: #777;
        }
        .btn {
          margin-top: 20px;
        }
      }
    `
  }}
`

function NotFound() {
  let history = useHistory()
  function handleClick() {
    history.goBack()
  }

  return (
    <Layout isNav={true}>
      <PageTitle title="상품" />
      <Notfound>
        <div className="img">
          <img src="../assets/img/user/not-found.png" alt="Logo" />
        </div>
        <div className="cont">
          <h1>페이지를 찾을수 없습니다.</h1>
          <div className="text">
            잘못된 주소이거나 변경 혹은 삭제되어
            <br />
            요청하신 페이지를 찾을 수 없습니다.
          </div>
          <div className="btn">
            <Link color="primary" border="radius" to="/home">
              홈으로
            </Link>
            <Link color="primary" border="solid" onClick={handleClick}>
              이전 페이지
            </Link>
          </div>
        </div>
      </Notfound>
    </Layout>
  )
}
export default NotFound
