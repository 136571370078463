/*global kakao*/
import React, { useEffect } from "react";

const Location = ({ longitude, latitude }) => {
  useEffect(() => {
    try {
      var markerPosition = new kakao.maps.LatLng(latitude, longitude);
      // var markerPosition = new kakao.maps.LatLng(36.35069, 127.384787);

      var container = document.getElementById("map");
      var options = {
        center: markerPosition,
        level: 5,
      };
      var map = new kakao.maps.Map(container, options);

      var marker = new kakao.maps.Marker({
        position: markerPosition,
      });

      marker.setMap(map);
    } catch (e) {
      console.log.apply({ e });
    }
  }, [longitude, latitude]);

  return (
    <div>
      <div id="map" style={{ width: "100%", height: "200px" }}></div>
    </div>
  );
};

export default Location;
