import React, { useState, useEffect } from "react"
import { withRouter, Link } from "react-router-dom"
import { useMutation } from "@apollo/client"
import {
  GUEST_TICKETDETAIL_QUERY,
  GUEST_ORDERPRODUCTREFUND_MUTATION,
} from "../../../../../Config/Queries"

import styled, { css } from "styled-components"
import {
  Row,
  Col,
  Comment,
  FooterButton,
  SOrderItem,
  SOrderInfo,
  Dot,
} from "../../../../../styles/styles"
import SLabel from "../../../../Labels"
import SLink from "../../../../Links"
import Alter from "../../../../Alter"
import Button from "../../../../Buttons"
import ReservationModal from "../../../../OrderProduct/Guest/OrderProduct/Detail/Reservation/ReservationModal"
import SendModal from "../../../../OrderProduct/Guest/OrderProduct/Detail/Send/SendModal"
import UsageModal from "../../../../OrderProduct/Guest/OrderProduct/Detail/Usage/UsageModal"
import Accordions from "../../../../Accordions"
import ReactMoment from "react-moment"
import NumberFormat from "react-number-format"
import moment from "moment"
import { graphqlUrl, orderinfo } from "../../../../../Config/Env"
import { toast } from "react-toastify"

import {
  dayOfWeekName,
  isPayName,
  placeTypeName,
} from "../../../../../Config/Util"
import KakaoMap from "../../../../KakaoMap"
import RowInfo from "../../../../RowInfo"
import Labels from "../../../../Labels"

const TicketBox = styled.div`
  ${({ theme }) => {
    return css`
      position: relative;
      margin-bottom: 20px;
      overflow: hidden;
      .TicketBox_content {
        padding: 0 ${theme.paddings.xl};
      }
      .box_top {
        display: flex;
        padding: 15px;
        flex: 1;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        .img {
          margin: 20px 0 0;
          position: relative;
          max-width: 200px;
          overflow: hidden;
          border-radius: 5px;
          overflow: hidden;
          :before {
            content: "";
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            border: 1px solid rgba(0, 0, 0, 0.05);
            border-radius: 5px;
          }
          img {
            max-width: 100%;
            display: block;
            width: 100%;
            height: auto;
          }
        }
        .cont {
          margin: 10px 0 15px;
          .date {
            color: ${theme.colors.black04};
            font-size: ${theme.fonts.size.sm};
            font-weight: 400;
          }
          h3 {
            margin-top: ${theme.margins.base};
            overflow: hidden;
            font-size: 18px;
            color: ${theme.colors.black01};
            font-weight: ${theme.fonts.weight.bold};
            line-height: 1.4;
          }
        }
      }
      .box_content {
        .userInfo {
          span {
            display: block;
            font-size: ${theme.fonts.size.sm};
            font-weight: ${theme.fonts.weight.base};
            color: ${theme.colors.black04};
          }
        }
        .map_box {
          margin-top: ${theme.margins.sm};
          border-radius: 5px;
          overflow: hidden;
          border: solid 1px #eee;
          .map {
            min-height: 160px;
          }
          .txt {
            background: ${theme.colors.lightgray};
            padding: 8px 10px;
            text-align: center;
            font-size: 14px;
            color: #444;
          }
        }
      }
    `
  }}
`
const SSOrderItem = styled(SOrderItem)`
  ${({ theme }) => {
    return css`
      padding: ${theme.paddings.xl};
      border: 0;
      .product_list {
        flex-direction: column;
        padding: 10px;
        border: solid 1px #eee;
        border-radius: 8px;
      }
      .item_top {
        position: relative;
        height: 50px;
        line-height: 50px;
        padding: 0 10px !important;
        font-size: 15px;
        border-bottom: 0;
        cursor: pointer;
      }
      .item_content {
      }
      .item_bottom {
        width: 100%;
        margin-top: 10px;
        padding: 0;
      }
      .button {
        position: absolute;
        background-color: #333;
        top: 50%;
        right: 5px;
        width: 20px;
        height: 20px;
        line-height: 20px;
        text-align: center;
        margin-top: -10px;
        border-radius: 50%;
        z-index: 9;
        &.open {
          :before {
            content: "\f078";
            font-family: ${theme.fonts.family.fontAwesome};
            color: #fff;
            font-size: 11px;
          }
        }
        &:before {
          content: "\f077";
          font-family: ${theme.fonts.family.fontAwesome};
          color: #fff;
          font-size: 11px;
        }
      }
      .hide {
        display: none;
      }
    `
  }}
`
const SAccordion = styled(Accordions)`
  ${({ theme }) => {
    return css`
      padding: 0;
    `
  }}
`
const ReserveList = styled.div`
  ${({ theme }) => {
    return css`
      .reserve_content {
        ul {
          li {
            display: flex;
            flex-direction: row;
            padding: 10px 0;
            border-top: solid 1px #e5e5e5;
            &:first-child {
              margin-top: 0;
              border-top: 0;
            }
            .left {
              flex: 1;
              font-size: 14px;
            }
            .right {
              flex: 0 0 auto;
              text-align: right;
              justify-content: flex-end;
            }

            .check {
              flex: 1;
              display: flex;
              align-items: center;
              color: #666;
              font-size: 14px;
              input {
                position: relative;
              }
              input[type="checkbox"] {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 20px;
                height: 20px;
                border-radius: 50%;
                border: solid 1px #e5e5e5;
                ::before {
                  content: "\f00c";
                  font-family: ${theme.fonts.family.fontAwesome};
                  color: #ccc;
                }
                :checked {
                  background-color: ${theme.colors.primary};
                  border: solid 1px ${theme.colors.primary};
                  ::before {
                    font-family: ${theme.fonts.family.fontAwesome};
                    color: white;
                  }
                }
              }
              span {
                display: flex;
                margin-left: ${theme.margins.base};
              }
            }
          }
        }
      }
    `
  }}
`
const SAlter = styled(Alter)`
  ${({ theme }) => {
    return css`
      margin-top: ${theme.margins.base};
    `
  }}
`

function Product({ history, match, location, isApp, data }) {
  const {
    TicketGuestTicketDetail: {
      id,
      commonStatus,

      isReservation,
      isMember,
      user,
      name,
      phone,

      ticketProduct,
      ticketOrder,

      ticketProductDate,
      ticketProductDateTime,
      ticketOrderProductPeoples,

      isRefund,

      cashier,

      usedAt,
      createdAt,
    },
  } = data

  const [sendModalIsOpen, setSendModalIsOpen] = useState(false)
  const sendToggleModal = () => {
    setSendModalIsOpen(!sendModalIsOpen)
  }

  const [reservationModalIsOpen, setReservationModalIsOpen] = useState(false)
  const reservationToggleModal = () => {
    setReservationModalIsOpen(!reservationModalIsOpen)
  }

  const [orderProductRefundMutation] = useMutation(
    GUEST_ORDERPRODUCTREFUND_MUTATION,
    {
      refetchQueries: () => [
        {
          query: GUEST_TICKETDETAIL_QUERY,
          variables: {
            id,
          },
        },
      ],
    }
  )
  const onRefund = async () => {
    console.log("onRefund")
    if (!window.confirm("티켓을 구매자에게 반환(환불)하시겠습니까?")) {
      return
    }

    try {
      const {
        data: { TicketAccountOrderProductRefund },
      } = await orderProductRefundMutation({
        variables: { id },
      })

      if (TicketAccountOrderProductRefund) {
        history.replace(`/account/completeOrderProducts`)
        toast.success(`티켓을 구매자에게 반환(환불)했습니다.`)
        history.replace(`/home`)
      }
    } catch (e) {
      console.log(e)
    }
  }

  const [usageModalIsOpen, setUsageModalIsOpen] = useState(false)
  const usageToggleModal = () => {
    setUsageModalIsOpen(!usageModalIsOpen)
  }

  const CoverImage =
    ticketProduct.coverImage === null
      ? "/assets/img/product/product-default.jpg"
      : `${graphqlUrl}${ticketProduct.coverImage}`

  const startDate = new Date(
    isReservation === true
      ? ticketProductDateTime?.ticketDateTime
      : ticketProduct.applyStartDateTime
  )
  const endDate = new Date(
    isReservation === true
      ? moment(ticketProductDateTime?.ticketDateTime).add(
          parseInt(ticketProduct.minute),
          "minutes"
        )
      : ticketProduct.applyEndDateTime
  )

  function Receiver() {
    return (
      <div className="box_content">
        <RowInfo
          border="bottom"
          textLeft={"티켓번호"}
          textRight={<ReactMoment format="mmssSSS">{createdAt}</ReactMoment>}
        />
        <RowInfo border="bottom" textLeft={"수신자"} textRight={name} />
        <RowInfo border="bottom" textLeft={"연락처"} textRight={phone} />
      </div>
    )
  }
  function TicketInfo() {
    const startDate = new Date(ticketProductDateTime?.ticketDateTime)
    const endDate = moment(ticketProductDateTime?.ticketDateTime).add(
      parseInt(ticketProduct.minute),
      "minutes"
    )
    return (
      <div className="box_content">
        <RowInfo
          border="bottom"
          textLeft={"상태"}
          textRight={
            <>
              {isReservation === true && "예매완료"}
              {isReservation === false && "예매대기"}
            </>
          }
        />
        <RowInfo
          border="bottom"
          textLeft={"행사일시"}
          textRight={
            <>
              <ReactMoment format="YYYY.MM.DD">{startDate}</ReactMoment>(
              {dayOfWeekName(startDate.getDay())}){" "}
              <ReactMoment format="HH:mm">{startDate}</ReactMoment> ~{" "}
              <ReactMoment format="HH:mm">{endDate}</ReactMoment>
            </>
          }
        />
        <RowInfo
          border="bottom"
          textLeft={"시간"}
          textRight={
            <>
              <b>
                <NumberFormat
                  value={ticketProduct.minute}
                  displayType={"text"}
                  thousandSeparator={true}
                />
              </b>
              분
            </>
          }
        />
        <RowInfo
          border="bottom"
          textLeft={"장소"}
          textRight={
            <>
              {ticketProduct.placeType === "OffLine"
                ? `${ticketProduct.placeAddress} ${ticketProduct.placeAddressDetail}`
                : placeTypeName(ticketProduct.placeType)}
            </>
          }
        />
        <div className="map_box">
          <div className="map">
            <KakaoMap
              longitude={ticketProduct.placeLongitude}
              latitude={ticketProduct.placeLatitude}
            />
          </div>
        </div>
      </div>
    )
  }
  function Reserve() {
    return (
      <div className="box_content">
        {ticketOrderProductPeoples.map((item, index) => {
          return (
            <RowInfo
              border={"bottom"}
              textLeft={
                <>
                  <div className="userInfo">
                    <b>{item.name}</b>
                    <span>{item.phone}</span>
                  </div>
                </>
              }
              textRight={
                <>
                  {item.commonStatus === "S" && (
                    <SLabel color="lightgray">참여대기</SLabel>
                  )}
                  {item.commonStatus === "C" && (
                    <SLabel color="lightprimary">참여완료</SLabel>
                  )}
                  {item.commonStatus === "D" && (
                    <SLabel color="lightdanger">불참</SLabel>
                  )}
                </>
              }
            />
          )
        })}
      </div>
    )
  }
  const [items, setItems] = useState([
    {
      uuid: "0",
      heading: "수신자정보",
      content: <Receiver createdAt={createdAt} name={name} phone={phone} />,
    },
  ])
  useEffect(() => {
    if (commonStatus === "S") {
      if (isReservation) {
        setItems([
          {
            uuid: "0",
            heading: "수신자정보",
            content: <Receiver />,
          },
          {
            uuid: "1",
            heading: "티켓정보",
            content: <TicketInfo />,
          },
          {
            uuid: "2",
            heading: `참여자(${data.TicketGuestTicketDetail.ticketOrderProductPeoples.length}명)`,
            content: <Reserve />,
          },
        ])
      }
    }
    if (commonStatus === "C") {
      if (isReservation) {
        setItems([
          {
            uuid: "0",
            heading: "수신자정보",
            content: <Receiver />,
          },
          {
            uuid: "1",
            heading: "티켓정보",
            content: <TicketInfo />,
          },
          {
            uuid: "2",
            heading: `참여자(${data.TicketGuestTicketDetail.ticketOrderProductPeoples.length}명)`,
            content: <Reserve />,
          },
        ])
      }
    }
  }, [data])

  const [isDetail, setIsDetail] = useState(false)
  const toggleView = () => {
    setIsDetail(!isDetail)
  }
  return (
    <>
      <TicketBox>
        <SSOrderItem>
          <div className="product_list">
            <Link to={`/products/${ticketProduct.id}`}>
              <div className="img">
                <img src={CoverImage} alt={ticketProduct.name} />
              </div>
              <div className="cont">
                <span className="category">{ticketProduct.store.name}</span>
                <strong>{ticketProduct.name}</strong>
                <div className="date">
                  {commonStatus === "S" && (
                    <>
                      {isReservation === true && (
                        <div className="date">
                          예매일 :
                          <ReactMoment format="YYYY.MM.DD">
                            {startDate}
                          </ReactMoment>
                          ({dayOfWeekName(startDate.getDay())}){" "}
                          <ReactMoment format="HH:mm">{startDate}</ReactMoment>{" "}
                          ~ <ReactMoment format="HH:mm">{endDate}</ReactMoment>
                        </div>
                      )}
                      {isReservation === false && (
                        <div className="date">
                          예매기간 :
                          <ReactMoment format="YYYY.MM.DD">
                            {startDate}
                          </ReactMoment>
                          ({dayOfWeekName(startDate.getDay())}) ~{" "}
                          <ReactMoment format="YYYY.MM.DD">
                            {endDate}
                          </ReactMoment>
                          ({dayOfWeekName(endDate.getDay())})
                        </div>
                      )}
                    </>
                  )}
                </div>
              </div>
            </Link>
            <Row className="item_bottom">
              <Col left className="left">
                <span>구매자. {ticketOrder.user.name}</span>
              </Col>
              <Col right className="right">
                {ticketProductDateTime?.ticketDateTime &&
                  new Date(ticketProductDateTime?.ticketDateTime) <=
                    new Date() && (
                    <>
                      <Labels color="lightwarning" bg="none">
                        예매일 만료
                      </Labels>
                    </>
                  )}
                {new Date(ticketProduct?.applyEndDateTime) <= new Date() && (
                  <>
                    <Labels color="lightwarning" bg="none">
                      예매기간 만료
                    </Labels>
                  </>
                )}
                <Dot />
                {commonStatus === "S" && (
                  <>
                    {isReservation === true && (
                      <>
                        <span>
                          동행자 <b>{ticketOrder.count}</b>명
                        </span>
                        <Dot />
                        <Labels color="lightprimary" bg="none">
                          예매완료
                        </Labels>
                      </>
                    )}
                    {isReservation === false && (
                      <>
                        <span>예매대기</span>
                      </>
                    )}
                  </>
                )}
                {commonStatus === "C" && (
                  <>
                    {isRefund === true && (
                      <>
                        <Labels color="lightdanger" bg="none">
                          반환완료
                        </Labels>
                      </>
                    )}
                    {isRefund === false && (
                      <>
                        <Labels color="lightprimary" bg="none">
                          사용완료
                        </Labels>
                      </>
                    )}
                  </>
                )}
              </Col>
            </Row>
          </div>
        </SSOrderItem>
        <SAccordion
          preExpanded={isReservation === true ? ["0", "1", "2"] : ["0"]}
          items={items}
        />
        <div className="TicketBox_content">
          <SAlter border="radius">
            <strong>예매/취소 안내</strong>
            <p>
              - 구매 후 7일 이내라도 취소시점이 관람일로부터 10일 이내라면 그에
              해당하는 취소수수료가 부과됩니다.
              <br />- 관람일 전일 취소의 경우 티켓금액의 90%가 부과됩니다.
            </p>
          </SAlter>
          {commonStatus === "S" &&
            isRefund === false &&
            endDate >= new Date() && (
              <>
                <Row className="row" style={{ marginTop: 16 }}>
                  <Button
                    onClick={reservationToggleModal}
                    color="lightprimary"
                    width="full"
                  >
                    예매일설정
                  </Button>
                  <Button
                    onClick={sendToggleModal}
                    color="lightwarning"
                    width="full"
                  >
                    양도
                  </Button>
                  <Button onClick={onRefund} color="lightdanger" width="full">
                    반환
                  </Button>
                </Row>
                <SendModal
                  isOpen={sendModalIsOpen}
                  toggleModal={sendToggleModal}
                />
                <ReservationModal
                  isOpen={reservationModalIsOpen}
                  toggleModal={reservationToggleModal}
                  id={id}
                  isReservation={isReservation}
                  isMember={isMember}
                  user={user}
                  name={name}
                  phone={phone}
                  dTicketProduct={ticketProduct}
                  dTicketOrder={ticketOrder}
                  dTicketProductDate={ticketProductDate}
                  dTicketProductDateTime={ticketProductDateTime}
                  dTicketOrderProductPeoples={ticketOrderProductPeoples}
                />
              </>
            )}
        </div>
      </TicketBox>

      {commonStatus === "S" &&
        isReservation === true &&
        endDate > new Date() && (
          <>
            <FooterButton>
              <div>
                <Button
                  onClick={usageToggleModal}
                  color="lightprimary"
                  width="full"
                >
                  사용하기
                </Button>
              </div>
            </FooterButton>

            <UsageModal
              isOpen={usageModalIsOpen}
              toggleModal={usageToggleModal}
              id={id}
            />
          </>
        )}
    </>
  )
}

export default withRouter(Product)
