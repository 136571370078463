import React from "react"
import { withRouter } from "react-router-dom"
import { useQuery } from "@apollo/client"
import { ORDERINIT_QUERY } from "../../Config/Queries"

import { Layout } from "../../Components/Layouts/Layout"
import PageTitle from "../../Components/Layouts/PageTitle"
import styled, { css } from "styled-components"
import Link from "../../Components/Links"
import Loading from "../../Components/Loading"
import { Roundbox, FooterButton } from "../../styles/styles"
import renderHTML from "react-render-html"
import Product from "../../Components/Order/Result/Product"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheck } from "@fortawesome/pro-solid-svg-icons"

const ComplationBox = styled.div`
  ${({ theme }) => {
    return css`
      .complation_top {
        flex: 1;
        display: flex;
        flex-direction: column;
        padding: 32px 0;
        justify-content: center;
        align-items: center;
        .user_photo {
          ul {
            display: flex;
            justify-content: center;
            li {
              margin-left: -10px;
              border-radius: 16px;
              box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
              :first-child {
                margin-left: 0;
              }
            }
          }
        }
        .round_box {
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: ${theme.colors.lightprimary};
          width: 56px;
          height: 56px;
          border-radius: 12px;
          svg {
            color: ${theme.colors.primary};
          }
        }
        .text {
          text-align: center;
          margin-top: ${theme.margins.base};
          line-height: 1.3;
          font-size: ${theme.fonts.size.lg};
          color: ${theme.colors.black01};
          b {
          }
        }
      }
    `
  }}
`

function Result({ history, match, location }) {
  const orderType = location?.state?.orderType
  const users = location?.state?.users
  const id = location?.state?.id
  const ticketProductDate = location?.state?.ticketProductDate
  const ticketProductDateTime = location?.state?.ticketProductDateTime
  const ticketDateTimePeoples = location?.state?.ticketDateTimePeoples
  const count = location?.state?.count
  const message = location?.state?.message

  const { data, loading, error } = useQuery(ORDERINIT_QUERY, {
    variables: {
      id,
    },
  })

  if (!loading && !error) {
    return (
      <Layout isNav={false} isFooterButton={true}>
        <PageTitle title="선물하기" />
        <ComplationBox>
          {orderType === "me" && (
            <div className="complation_top">
              <div className="round_box">
                <FontAwesomeIcon icon={faCheck} size={"2x"} />
              </div>
              <div className="text">
                <b>예매완료</b> 되었습니다.
              </div>
            </div>
          )}

          {orderType === "present" && (
            <div className="complation_top">
              <div className="round_box">
                <FontAwesomeIcon icon={faCheck} size={"2x"} />
              </div>
              <div className="text">
                <b>
                  {users[0]?.name}님
                  {renderHTML(
                    users.length > 1 ? `외 <b>${users.length - 1}</b>명` : ``
                  )}
                </b>
                에게
                <br />
                티켓을 보냈습니다.
              </div>
            </div>
          )}

          <Product
            orderType={orderType}
            users={users}
            ticketProductDate={ticketProductDate}
            ticketProductDateTime={ticketProductDateTime}
            count={count}
            data={data}
          />

          <FooterButton>
            <div>
              <Link
                to="/account/orders"
                color="lightprimary"
                width="full"
                border="radius"
              >
                구매내역
              </Link>
              <Link to="/home" color="primary" width="full" border="radius">
                홈으로
              </Link>
            </div>
          </FooterButton>
        </ComplationBox>
      </Layout>
    )
  } else {
    return <Loading />
  }
}

export default withRouter(Result)
