import React from "react"
import { withRouter } from "react-router-dom"
import { useQuery } from "@apollo/client"
import { ACCOUNT_ORDERPRODUCT_QUERY } from "../../../Config/Queries"

import { Layout } from "../../../Components/Layouts/Layout"
import PageTitle from "../../../Components/Layouts/PageTitle"
import styled, { css } from "styled-components"
import { Container, Comment, PageLocation } from "../../../styles/styles"
import Product from "../../../Components/OrderProduct/Account/OrderProduct/Detail/Product"
import SLoading from "../../../Components/Loading"

const SContainer = styled(Container)`
  ${({ theme }) => {
    return css`
      .detail_top {
        padding: ${theme.paddings.base};
      }
      .detail_content {
        .orderinfo {
          padding: ${theme.paddings.base} 0;
          font-size: ${theme.fonts.size.sm};
        }
      }
    `
  }}
`
const SComment = styled(Comment)`
  padding: 10px 30px 15px;
  margin-bottom: 0;
  .pagelocation {
    margin-bottom: 0;
  }
`

function Detail({ history, match, location }) {
  const isApp = localStorage.getItem("isApp")
  const id = match.params.id

  const { data, loading } = useQuery(ACCOUNT_ORDERPRODUCT_QUERY, {
    fetchPolicy: "network-only",
    variables: {
      id,
    },
  })

  if (!loading) {
    const {
      TicketAccountOrderProductDetail: {
        id,
        commonStatus,

        isReservation,
        isMember,
        user,
        name,
        phone,

        ticketProduct,
        ticketOrder,

        isRefund,

        cashier,

        usedAt,
        createdAt,
      },
    } = data

    return (
      <Layout
        isNav={true}
        isFooterButton={commonStatus === "S" && isReservation === true}
      >
        <PageTitle title="받은 선물함" />
        <SContainer>
          {ticketOrder.message !== "" && (
            <SComment>
              <h4>{user.name}님의 선물</h4>
              <div className="massge_write">
                <div className="inner">{ticketOrder.message}</div>
              </div>
            </SComment>
          )}

          <Product isApp={isApp} data={data} />
        </SContainer>
      </Layout>
    )
  } else {
    return <SLoading />
  }
}

export default withRouter(Detail)
