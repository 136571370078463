import { gql } from "@apollo/client";

// 로그인
export const LOGIN_MUTATION = gql`
  mutation TicketAuthSignIn($userId: String!, $password: String!) {
    TicketAuthSignIn(userId: $userId, password: $password)
  }
`;

// 비회원 > 로그인
export const GUEST_LOGIN_MUTATION = gql`
  mutation TicketGuestLogin($ticketNumber: String!, $phone: String!) {
    TicketGuestLogin(ticketNumber: $ticketNumber, phone: $phone)
  }
`;
// 비회원 > 티켓정보
export const GUEST_TICKETDETAIL_QUERY = gql`
  query TicketGuestTicketDetail($id: String!) {
    TicketGuestTicketDetail(id: $id) {
      id
      commonStatus

      isReservation
      isMember
      user {
        id
        avatar
        name
      }
      name
      phone

      ticketProduct {
        id
        commonStatus
        coverImage

        store {
          id
          storeLogo
          name
        }

        managerName
        managerTel
        managerEmail

        minute
        limit
        isPay

        price
        isDiscount
        discountPrice
        totalPrice
        orderCount

        applyStartDateTime
        applyEndDateTime
        placeType

        placeZipcode
        placeAddress
        placeAddressDetail
        placeLongitude
        placeLatitude

        ticketCategory {
          id
          name
          parentCategory {
            id
            name
          }
        }
        name
        contents

        isSoldOut
        timestamp

        createdAt
        updatedAt

        ticketProductDates {
          id
          commonStatus
          ticketDate
          ticketProductDateTimes {
            id
            commonStatus
            isSoldOut
            ticketDateTime
            ticketOrderProductPeoplesCount
          }
        }
        ticketProductDatesCount
      }
      ticketOrder {
        id
        # ticketOrderProducts{
        #   id
        # }

        commonStatus
        user {
          id
          avatar
          userId
          name
        }

        isDiscount
        discountPrice
        price
        count

        totalCount
        totalPrice

        message
      }

      ticketProductDate {
        id
        commonStatus
        ticketDate
      }
      ticketProductDateTime {
        id
        commonStatus
        isSoldOut
        ticketDateTime
      }
      ticketOrderProductPeoples {
        id
        commonStatus
        name
        phone

        createdAt
        updatedAt
      }

      isRefund

      cashier {
        id
        userId
        name
      }

      usedAt
      createdAt
      updatedAt
    }
  }
`;
// 비회원 > 티켓정보 예매설정
export const GUEST_ORDERPRODUCTCONFIG_MUTATION = gql`
  mutation TicketGuestOrderProductConfig(
    $id: String!
    $ticketProductDate: String
    $ticketProductDateTime: String
    $ticketDateTimePeoples: [OrderTicketDateTimePeopleInput!]
  ) {
    TicketGuestOrderProductConfig(
      id: $id
      ticketProductDate: $ticketProductDate
      ticketProductDateTime: $ticketProductDateTime
      ticketDateTimePeoples: $ticketDateTimePeoples
    )
  }
`;
// 비회원 > 티켓정보 > 양도 회원검색
export const GUEST_NAME_BY_USERS_MUTATION = gql`
  mutation TicketGuestUserSeach($keyword: String, $skip: Int, $first: Int) {
    TicketGuestUserSeach(
      keyword: $keyword
      orderBy: "name_DESC"
      skip: $skip
      first: $first
    ) {
      users {
        id
        role
        avatar
        userId
        phone
        name
        store {
          id
          name
        }
      }
      count
    }
  }
`;
// 비회원 > 티켓정보 > 양도
export const GUEST_ORDERPRODUCTSEND_MUTATION = gql`
  mutation TicketGuestOrderProductSend($id: String!, $user: SendUserInput!) {
    TicketGuestOrderProductSend(id: $id, user: $user)
  }
`;
// 비회원 > 티켓정보 환불
export const GUEST_ORDERPRODUCTREFUND_MUTATION = gql`
  mutation TicketGuestOrderProductRefund($id: String!) {
    TicketGuestOrderProductRefund(id: $id)
  }
`;
// 비회원 > 티켓정보 사용
export const GUEST_ORDERPRODUCTUSAGE_MUTATION = gql`
  mutation TicketGuestOrderProductUsage(
    $ticketOrderProductId: String!
    $qrCodeStoreUserId: String!
  ) {
    TicketGuestOrderProductUsage(
      ticketOrderProductId: $ticketOrderProductId
      qrCodeStoreUserId: $qrCodeStoreUserId
    )
  }
`;

// 메인
export const MAIN_QUERY = gql`
  query TicketMain {
    TicketMain {
      ticketBanners {
        id
        coverImage
        title
      }
      ticketCategories {
        id
        iconImage
        name
        ticketProductsCount
      }
      monthTicketProductDates {
        id
        ticketProduct {
          id
          commonStatus

          coverImage

          store {
            id
            name
          }

          managerName
          managerTel
          managerEmail

          limit
          isPay

          price
          isDiscount
          discountPrice
          totalPrice

          applyStartDateTime
          applyEndDateTime
          placeType

          placeZipcode
          placeAddress
          placeAddressDetail
          placeLongitude
          placeLatitude

          ticketCategory {
            id
            name
            parentCategory {
              id
              name
            }
          }

          name
          contents

          isSoldOut

          createdAt
          updatedAt
        }
        ticketDate
        createdAt
        updatedAt
      }
      newTicketProducts {
        id
        commonStatus

        coverImage

        store {
          id
          name
        }

        managerName
        managerTel
        managerEmail

        limit
        isPay

        price
        isDiscount
        discountPrice
        totalPrice

        applyStartDateTime
        applyEndDateTime
        placeType

        placeZipcode
        placeAddress
        placeAddressDetail
        placeLongitude
        placeLatitude

        ticketCategory {
          id
          name
          parentCategory {
            id
            name
          }
        }

        name
        contents

        isSoldOut

        createdAt
        updatedAt
      }
    }
  }
`;

// 검색 > 초기화면 > 분류
export const CATEGORIES_QUERY = gql`
  query TicketProductCategoryList($parentId: String) {
    TicketProductCategoryList(parentId: $parentId) {
      ticketCategories {
        id
        commonStatus
        iconImage
        name
        parentId
        order
        createdAt
        updatedAt

        ticketProductsCount
        childrenCount
        children {
          id
          commonStatus
          iconImage
          name
          parentId
          order
          createdAt
          updatedAt

          ticketProductsCount
          childrenCount
        }
      }
      count
    }
  }
`;

// 상품 > 목록
export const PRODUCTS_QUERY = gql`
  query TicketProductList(
    $parentTicketCategory: String
    $ticketCategory: String
    $isPay: String
    $keyword: String
    $orderBy: String
    $skip: Int!
    $first: Int!
  ) {
    TicketProductList(
      parentTicketCategory: $parentTicketCategory
      ticketCategory: $ticketCategory
      isPay: $isPay
      keyword: $keyword
      orderBy: $orderBy
      skip: $skip
      first: $first
    ) {
      ticketProducts {
        id
        commonStatus
        coverImage

        store {
          id
          storeLogo
          name
        }

        managerName
        managerTel
        managerEmail

        limit
        isPay

        price
        isDiscount
        discountPrice
        totalPrice
        orderCount

        applyStartDateTime
        applyEndDateTime
        placeType

        placeZipcode
        placeAddress
        placeAddressDetail
        placeLongitude
        placeLatitude

        ticketCategory {
          id
          name
          parentCategory {
            id
            name
          }
        }
        name
        contents

        isSoldOut
        timestamp

        createdAt
        updatedAt
      }
      count
    }
  }
`;

// 상품 > 상세정보
export const PRODUCT_QUERY = gql`
  query TicketProductDetail($id: String!) {
    TicketProductDetail(id: $id) {
      id
      commonStatus
      coverImage

      store {
        id
        storeLogo
        name
      }

      managerName
      managerTel
      managerEmail

      minute
      limit
      isPay

      price
      isDiscount
      discountPrice
      totalPrice
      orderCount

      applyStartDateTime
      applyEndDateTime
      placeType

      placeZipcode
      placeAddress
      placeAddressDetail
      placeLongitude
      placeLatitude

      ticketCategory {
        id
        name
        parentCategory {
          id
          name
        }
      }
      name
      contents

      isSoldOut
      timestamp

      createdAt
      updatedAt

      ticketProductReviews {
        id
        ticketProduct {
          id
        }
        ticketOrderProduct {
          id
          ticketProduct {
            id
            store {
              id
              storeLogo
              name
            }
          }
        }
        user {
          id
          avatar
          name
        }
        commonStatus
        isSecret
        score
        contents
        comment
        createdAt
        updatedAt

        isMe
      }
      ticketProductReviewsCount
      ticketProductInquiries {
        id
        ticketProduct {
          id
        }
        user {
          id
          avatar
          name
        }
        commonStatus
        isSecret
        question
        answer
        createdAt
        updatedAt

        isMe
      }
      ticketProductInquiriesCount
      ticketProductDates {
        id
        commonStatus
        ticketDate
        ticketProductDateTimes {
          id
          commonStatus
          isSoldOut
          ticketDateTime
          ticketOrderProductPeoplesCount
        }
      }
      ticketProductDatesCount
      ticketProductDateTimesCount
    }
  }
`;

// 계정 > 위시리스트 > 상세정보 > 위시리스트
// 상품 > 상세정보 > 위시리스트
// 티켓 > 상세정보 > 위시리스트
export const TOGGLEWISHLIST_MUTATION = gql`
  mutation TicketProductToggleWishlist($id: String!) {
    TicketProductToggleWishlist(id: $id)
  }
`;

// 계정 > 위시리스트 > 상세정보 > 위시리스트 유무
// 상품 > 상세정보 > 위시리스트 유무
// 티켓 > 상세정보 > 위시리스트 유무
export const ISWISHLIST_QUERY = gql`
  query TicketProductIsWishlist($id: String!) {
    TicketProductIsWishlist(id: $id)
  }
`;

// 상품 > 상세정보 > 쪼르기
export const PRODUCTORDER_PUSH_MUTATION = gql`
  mutation TicketProductOrderPush(
    $users: [String!]
    $id: String!
    $message: String!
  ) {
    TicketProductOrderPush(users: $users, id: $id, message: $message)
  }
`;

// 상품 > 상세정보 > 선물후기 > 목록
// 티켓 > 상세정보 > 선물후기 > 목록
export const REVIEWS_QUERY = gql`
  query TicketProductReviewList(
    $ticketProduct: String!
    $orderBy: String
    $skip: Int!
    $first: Int!
  ) {
    TicketProductReviewList(
      ticketProduct: $ticketProduct
      orderBy: $orderBy
      skip: $skip
      first: $first
    ) {
      ticketProductReviews {
        id
        ticketProduct {
          id
        }
        ticketOrderProduct {
          id
          ticketProduct {
            id
            store {
              id
              storeLogo
              name
            }
          }
        }
        user {
          id
          avatar
          name
        }
        commonStatus
        isSecret
        score
        contents
        createdAt
        updatedAt

        isMe
      }
      count
    }
  }
`;

// 상품 > 상세정보 > 선물문의 > 목록
// 티켓 > 상세정보 > 선물문의 > 목록
export const INQUIRIES_QUERY = gql`
  query TicketProductInquiryList(
    $ticketProduct: String!
    $orderBy: String
    $skip: Int!
    $first: Int!
  ) {
    TicketProductInquiryList(
      ticketProduct: $ticketProduct
      orderBy: $orderBy
      skip: $skip
      first: $first
    ) {
      ticketProductInquiries {
        id
        ticketProduct {
          id
        }
        user {
          id
          avatar
          name
        }
        commonStatus
        isSecret
        question
        answer
        createdAt
        updatedAt

        isMe
      }
      count
    }
  }
`;

// 상품 > 상세정보 > 선물문의 > 작성
// 티켓 > 상세정보 > 선물문의 > 작성
// 계정 > 선물문의 > 작성
export const INQUIRYWRITE_MUTATION = gql`
  mutation TicketProductInquiryWrite(
    $ticketProduct: String!
    $isSecret: Boolean
    $question: String!
  ) {
    TicketProductInquiryWrite(
      ticketProduct: $ticketProduct
      isSecret: $isSecret
      question: $question
    )
  }
`;

// 상품 > 상세정보 > 선물문의 > 상세정보
// 티켓 > 상세정보 > 선물문의 > 상세정보
// 계정 > 선물문의 > 상세정보
export const INQUIRY_QUERY = gql`
  query TicketProductInquiryDetail($id: String!) {
    TicketProductInquiryDetail(id: $id) {
      id
      ticketProduct {
        id
      }
      commonStatus
      isSecret
      question
      answer
      createdAt
      updatedAt
    }
  }
`;

// 상품 > 상세정보 > 선물문의 > 수정
// 티켓 > 상세정보 > 선물문의 > 수정
// 계정 > 선물문의 > 수정
export const INQUIRYEDIT_MUTATION = gql`
  mutation TicketProductInquiryEdit(
    $id: String!
    $isSecret: Boolean
    $question: String!
  ) {
    TicketProductInquiryEdit(id: $id, isSecret: $isSecret, question: $question)
  }
`;

// 상품 > 상세정보 > 선물문의 > 삭제
// 티켓 > 상세정보 > 선물문의 > 삭제
// 계정 > 선물문의 > 삭제
export const INQUIRYDELETE_MUTATION = gql`
  mutation TicketProductInquiryDelete($id: String!) {
    TicketProductInquiryDelete(id: $id)
  }
`;

// 상품 > 선물하기 > 초기화면
// 티켓 > 선물하기 > 초기화면
export const ORDERINIT_QUERY = gql`
  query TicketOrderInit($id: String!) {
    TicketOrderInit(id: $id) {
      ticketProduct {
        id
        commonStatus
        coverImage

        store {
          id
          storeLogo
          name
        }

        managerName
        managerTel
        managerEmail

        minute
        limit
        isPay

        price
        isDiscount
        discountPrice
        totalPrice
        orderCount

        applyStartDateTime
        applyEndDateTime
        placeType

        placeZipcode
        placeAddress
        placeAddressDetail
        placeLongitude
        placeLatitude

        ticketCategory {
          id
          name
          parentCategory {
            id
            name
          }
        }
        name
        contents

        isSoldOut
        timestamp

        createdAt
        updatedAt

        ticketProductDatesCount
        ticketProductDates {
          id
          commonStatus
          ticketDate
          ticketProductDateTimes {
            id
            commonStatus
            isSoldOut
            ticketDateTime
          }
        }
      }
      account {
        id
        avatar
        userId
        name
        phone
        trustlines {
          id
          commonStatus
          currencyType
          currency
          amount
          createdAt
          updatedAt
        }

        isCms
        cmsValue
        cmsDay
      }
    }
  }
`;

// 상품 > 선물하기 > 처리
// 티켓 > 선물하기 > 처리
export const ORDERPROCESSING_MUTATION = gql`
  mutation TicketOrderProcessing(
    $authPassword: String!
    $orderType: String!
    $users: [OrderUserInput!]
    $id: String!
    $ticketProductDate: String
    $ticketProductDateTime: String
    $ticketDateTimePeoples: [OrderTicketDateTimePeopleInput!]
    $count: Int!
    $message: String!
  ) {
    TicketOrderProcessing(
      authPassword: $authPassword
      orderType: $orderType
      users: $users
      id: $id
      ticketProductDate: $ticketProductDate
      ticketProductDateTime: $ticketProductDateTime
      ticketDateTimePeoples: $ticketDateTimePeoples
      count: $count
      message: $message
    )
  }
`;

// 계정
export const ACCOUNT_QUERY = gql`
  query TicketAccountUser {
    TicketAccountUser {
      user {
        avatar
        userId
        name
        email
        phone
        birthDate
        zipcode
        roadAddress
        jibunAddress
        addressDetail
        latitude
        longitude

        role
        trustlines {
          id
          commonStatus
          currencyType
          currency
          amount
          createdAt
          updatedAt
        }
        wallet {
          id
        }
        store {
          id
          storeLogo
          name
          storeCEO
          storeNumber
          storeZipcode
          storeRoadAddress
          storeJibunAddress
          storeAddressDetail
          storeCategory {
            id
            name
          }
        }
        visitedAt
        createdAt
        updatedAt

        ticketOrderProductCount
      }
      ticketOrderProductsCount
      ticketOrdersCount
      ticketWishlistsCount
    }
  }
`;

// 계정 > 받은선물함 > 사용가능 목록
export const ACCOUNT_READYORDERPRODUCTS_QUERY = gql`
  query TicketAccountOrderProductReadyList(
    $orderBy: String
    $skip: Int
    $first: Int
  ) {
    TicketAccountOrderProductReadyList(
      orderBy: $orderBy
      skip: $skip
      first: $first
    ) {
      ticketOrderProducts {
        id
        commonStatus

        isReservation
        isMember
        user {
          id
          avatar
          name
        }
        name
        phone
        count

        isDiscount
        discountPrice
        price
        totalPrice

        ticketProduct {
          id
          commonStatus
          coverImage

          store {
            id
            storeLogo
            name
          }

          managerName
          managerTel
          managerEmail

          minute
          limit
          isPay

          price
          isDiscount
          discountPrice
          totalPrice
          orderCount

          applyStartDateTime
          applyEndDateTime
          placeType

          placeZipcode
          placeAddress
          placeAddressDetail
          placeLongitude
          placeLatitude

          ticketCategory {
            id
            name
            parentCategory {
              id
              name
            }
          }
          name
          contents

          isSoldOut
          timestamp

          createdAt
          updatedAt
        }
        ticketOrder {
          id
          isDiscount
          discountPrice
          price
          count

          totalCount
          totalPrice
        }

        ticketProductDate {
          id
          commonStatus
          ticketDate
        }
        ticketProductDateTime {
          id
          commonStatus
          isSoldOut
          ticketDateTime
        }

        isRefund
        meRefund

        usedAt
        createdAt
        updatedAt
      }
      count
    }
  }
`;

// 계정 > 받은선물함 > 읽기 처리
export const ACCOUNT_ORDERPRODUCT_ISREADSEDIT_MUTATION = gql`
  mutation TicketOrderProductIsReadsEdit($isRead: Boolean) {
    TicketOrderProductIsReadsEdit(isRead: $isRead)
  }
`;

// 계정 > 받은선물함 > 사용완료 목록
export const ACCOUNT_COMPLETEORDERPRODUCTS_QUERY = gql`
  query TicketAccountOrderProductCompleteList(
    $orderBy: String
    $skip: Int
    $first: Int
  ) {
    TicketAccountOrderProductCompleteList(
      orderBy: $orderBy
      skip: $skip
      first: $first
    ) {
      ticketOrderProducts {
        id
        commonStatus

        isReservation
        isMember
        user {
          id
          avatar
          name
        }
        name
        phone
        count

        isDiscount
        discountPrice
        price
        totalPrice

        ticketProduct {
          id
          commonStatus
          coverImage

          store {
            id
            storeLogo
            name
          }

          managerName
          managerTel
          managerEmail

          minute
          limit
          isPay

          price
          isDiscount
          discountPrice
          totalPrice
          orderCount

          applyStartDateTime
          applyEndDateTime
          placeType

          placeZipcode
          placeAddress
          placeAddressDetail
          placeLongitude
          placeLatitude

          ticketCategory {
            id
            name
            parentCategory {
              id
              name
            }
          }
          name
          contents

          isSoldOut
          timestamp

          createdAt
          updatedAt
        }
        ticketOrder {
          id
          isDiscount
          discountPrice
          price
          count

          totalCount
          totalPrice
        }

        ticketProductDate {
          id
          commonStatus
          ticketDate
        }
        ticketProductDateTime {
          id
          commonStatus
          isSoldOut
          ticketDateTime
        }

        isRefund
        meRefund

        usedAt
        createdAt
        updatedAt
      }
      count
    }
  }
`;

// 계정 > 받은선물함 > 상세정보
export const ACCOUNT_ORDERPRODUCT_QUERY = gql`
  query TicketAccountOrderProductDetail($id: String!) {
    TicketAccountOrderProductDetail(id: $id) {
      id
      commonStatus

      isReservation
      isMember
      user {
        id
        avatar
        name
      }
      name
      phone
      count

      ticketProduct {
        id
        commonStatus
        coverImage

        store {
          id
          storeLogo
          name
        }

        managerName
        managerTel
        managerEmail

        minute
        limit
        isPay

        price
        isDiscount
        discountPrice
        totalPrice
        orderCount

        applyStartDateTime
        applyEndDateTime
        placeType

        placeZipcode
        placeAddress
        placeAddressDetail
        placeLongitude
        placeLatitude

        ticketCategory {
          id
          name
          parentCategory {
            id
            name
          }
        }
        name
        contents

        isSoldOut
        timestamp

        createdAt
        updatedAt

        ticketProductDates {
          id
          commonStatus
          ticketDate
          ticketProductDateTimes {
            id
            commonStatus
            isSoldOut
            ticketDateTime
            ticketOrderProductPeoplesCount
          }
        }
        ticketProductDatesCount
      }
      ticketOrder {
        id
        isDiscount
        discountPrice
        price
        count

        totalCount
        totalPrice

        message
      }

      ticketProductDate {
        id
        commonStatus
        ticketDate
      }
      ticketProductDateTime {
        id
        commonStatus
        isSoldOut
        ticketDateTime
      }
      ticketOrderProductPeoples {
        id
        commonStatus
        name
        phone

        createdAt
        updatedAt
      }

      isRefund

      cashier {
        id
        userId
        name
      }

      usedAt
      createdAt
      updatedAt

      isMe
    }
  }
`;

// 계정 > 받은선물함 > 상세정보 일정/동행자 설정
export const ACCOUNT_ORDERPRODUCTCONFIG_MUTATION = gql`
  mutation TicketAccountOrderProductConfig(
    $id: String!
    $ticketProductDate: String
    $ticketProductDateTime: String
    $ticketDateTimePeoples: [OrderTicketDateTimePeopleInput!]
  ) {
    TicketAccountOrderProductConfig(
      id: $id
      ticketProductDate: $ticketProductDate
      ticketProductDateTime: $ticketProductDateTime
      ticketDateTimePeoples: $ticketDateTimePeoples
    )
  }
`;

// 계정 > 받은선물함 > 상세정보 양도
export const ACCOUNT_ORDERPRODUCTSEND_MUTATION = gql`
  mutation TicketAccountOrderProductSend($id: String!, $user: SendUserInput!) {
    TicketAccountOrderProductSend(id: $id, user: $user)
  }
`;

// 계정 > 받은선물함 > 상세정보 반환(환불)
export const ACCOUNT_ORDERPRODUCTREFUND_MUTATION = gql`
  mutation TicketAccountOrderProductRefund($id: String!) {
    TicketAccountOrderProductRefund(id: $id)
  }
`;

// 계정 > 받은선물함 > 상세정보 사용
export const ACCOUNT_ORDERPRODUCTUSAGE_MUTATION = gql`
  mutation TicketAccountOrderProductUsage(
    $ticketOrderProductId: String!
    $qrCodeStoreUserId: String!
  ) {
    TicketAccountOrderProductUsage(
      ticketOrderProductId: $ticketOrderProductId
      qrCodeStoreUserId: $qrCodeStoreUserId
    )
  }
`;

// 계정 > 받은선물함 > 상세정보 > 보내기 > 수신자 검색
// 상품 > 선물하기 > 수신자 검색
export const NAME_BY_USERS_MUTATION = gql`
  mutation TicketProductUserSeach($keyword: String, $skip: Int, $first: Int) {
    TicketProductUserSeach(
      keyword: $keyword
      orderBy: "name_DESC"
      skip: $skip
      first: $first
    ) {
      users {
        id
        role
        avatar
        userId
        phone
        name
        store {
          id
          name
        }
      }
      count
    }
  }
`;

// 계정 > 구매내역 > 목록
export const ACCOUNT_ORDERS_QUERY = gql`
  query TicketAccountOrderList($orderBy: String, $skip: Int, $first: Int) {
    TicketAccountOrderList(orderBy: $orderBy, skip: $skip, first: $first) {
      ticketOrders {
        id
        ticketOrderProducts {
          id
          commonStatus

          isReservation
          isMember
          user {
            id
            avatar
            userId
            name
          }
          name
          phone

          isRefund
          meRefund
        }
        commonStatus
        ticketProduct {
          id
          commonStatus
          coverImage

          store {
            id
            storeLogo
            name
          }

          managerName
          managerTel
          managerEmail

          limit
          isPay

          price
          isDiscount
          discountPrice
          totalPrice
          orderCount

          applyStartDateTime
          applyEndDateTime
          placeType

          placeZipcode
          placeAddress
          placeAddressDetail
          placeLongitude
          placeLatitude

          ticketCategory {
            id
            name
            parentCategory {
              id
              name
            }
          }
          name
          contents

          isSoldOut
          timestamp

          createdAt
          updatedAt
        }

        isDiscount
        discountPrice
        price

        # ticketProductDate {
        #   id
        #   commonStatus
        #   ticketDate
        # }
        # ticketProductDatePrice
        # ticketProductDateTime {
        #   id
        #   commonStatus
        #   isSoldOut
        #   ticketDateTime
        # }
        # ticketProductDateTimePrice

        count

        totalCount
        totalPrice

        createdAt
        updatedAt
      }
      count
    }
  }
`;

// 계정 > 구매내역 > 상세정보
export const ACCOUNT_ORDER_QUERY = gql`
  query TicketAccountOrderDetail($id: String!) {
    TicketAccountOrderDetail(id: $id) {
      id
      ticketOrderProducts {
        id
        commonStatus

        isReservation
        isMember
        user {
          id
          avatar
          userId
          name
        }
        name
        phone
        count

        ticketProductDate {
          id
          commonStatus
          ticketDate
        }
        ticketProductDateTime {
          id
          commonStatus
          isSoldOut
          ticketDateTime
        }

        isRefund
        meRefund
      }
      commonStatus
      user {
        id
        avatar
        name
      }
      ticketProduct {
        id
        commonStatus
        coverImage

        store {
          id
          storeLogo
          name
        }

        managerName
        managerTel
        managerEmail

        limit
        isPay
        minute

        price
        isDiscount
        discountPrice
        totalPrice
        orderCount

        applyStartDateTime
        applyEndDateTime
        placeType

        placeZipcode
        placeAddress
        placeAddressDetail
        placeLongitude
        placeLatitude

        ticketCategory {
          id
          name
          parentCategory {
            id
            name
          }
        }
        name
        contents

        isSoldOut
        timestamp

        createdAt
        updatedAt

        ticketProductDates {
          id
          commonStatus
          ticketDate
          ticketProductDateTimes {
            id
            commonStatus
            isSoldOut
            ticketDateTime
          }
        }
        ticketProductDatesCount
      }

      isDiscount
      discountPrice
      price

      count
      totalCount
      totalPrice

      message
      isRefund

      refundReceiver {
        id
      }
      refundTotalCount
      refundTotalPrice

      createdAt
      updatedAt
    }
  }
`;

// 계정 > 구매내역 > 상세정보 > 환불하기
export const ACCOUNT_ORDERREFUND_MUTATION = gql`
  mutation TicketAccountOrderRefund(
    $id: String!
    $ticketOrderProductIds: [String!]!
  ) {
    TicketAccountOrderRefund(
      id: $id
      ticketOrderProductIds: $ticketOrderProductIds
    )
  }
`;

// 계정 > 위시리스트 > 목록
export const ACCOUNT_WISHLISTS_QUERY = gql`
  query TicketAccountWishlistList($orderBy: String, $skip: Int!, $first: Int!) {
    TicketAccountWishlistList(orderBy: $orderBy, skip: $skip, first: $first) {
      ticketWishlists {
        id
        ticketProduct {
          id
          commonStatus
          coverImage

          store {
            id
            storeLogo
            name
          }

          managerName
          managerTel
          managerEmail

          limit
          isPay

          price
          isDiscount
          discountPrice
          totalPrice
          orderCount

          applyStartDateTime
          applyEndDateTime
          placeType

          placeZipcode
          placeAddress
          placeAddressDetail
          placeLongitude
          placeLatitude

          ticketCategory {
            id
            name
            parentCategory {
              id
              name
            }
          }
          name
          contents

          isSoldOut
          timestamp

          createdAt
          updatedAt
        }
        user {
          id
          avatar
          name
        }
        createdAt
        updatedAt
      }
      count
    }
  }
`;

// 계정 > 선물후기 > 작성가능한 상품 목록
export const ACCOUNT_READYREVIEWS_QUERY = gql`
  query TicketAccountReviewReadyList(
    $orderBy: String
    $skip: Int!
    $first: Int!
  ) {
    TicketAccountReviewReadyList(
      orderBy: $orderBy
      skip: $skip
      first: $first
    ) {
      ticketOrderProducts {
        id
        commonStatus
        user {
          id
          avatar
          name
        }
        ticketProduct {
          id
          commonStatus
          coverImage

          store {
            id
            storeLogo
            name
          }

          managerName
          managerTel
          managerEmail

          limit
          isPay

          price
          isDiscount
          discountPrice
          totalPrice
          orderCount

          applyStartDateTime
          applyEndDateTime
          placeType

          placeZipcode
          placeAddress
          placeAddressDetail
          placeLongitude
          placeLatitude

          ticketCategory {
            id
            name
            parentCategory {
              id
              name
            }
          }
          name
          contents

          isSoldOut
          timestamp

          createdAt
          updatedAt

          ticketProductDates {
            id
            commonStatus
            ticketDate
            ticketProductDateTimes {
              id
              commonStatus
              isSoldOut
              ticketDateTime
            }
          }
          ticketProductDatesCount
        }
        ticketProductReviews {
          id
          commonStatus
          isSecret
          score
          contents
          createdAt
          updatedAt
        }
        isDiscount
        discountPrice
        price

        ticketProductDate {
          id
          commonStatus
          ticketDate
        }
        ticketProductDateTime {
          id
          commonStatus
          isSoldOut
          ticketDateTime
        }

        totalPrice

        createdAt
        updatedAt
      }
      count
    }
  }
`;

// 계정 > 선물후기 > 작성가능한 상품 작성
export const ACCOUNT_REVIEWWRITE_MUTATION = gql`
  mutation TicketAccountReviewWrite(
    $ticketOrderProduct: String!
    $score: Int!
    $contents: String!
  ) {
    TicketAccountReviewWrite(
      ticketOrderProduct: $ticketOrderProduct
      score: $score
      contents: $contents
    )
  }
`;

// 계정 > 선물후기 > 작성완료한 상품후기 목록
export const ACCOUNT_COMPLETEREVIEWS_QUERY = gql`
  query TicketAccountReviewCompleteList(
    $orderBy: String
    $skip: Int!
    $first: Int!
  ) {
    TicketAccountReviewCompleteList(
      orderBy: $orderBy
      skip: $skip
      first: $first
    ) {
      ticketProductReviews {
        id
        ticketOrderProduct {
          id
          commonStatus
          user {
            id
            avatar
            name
          }
          ticketProduct {
            id
            commonStatus
            coverImage

            store {
              id
              storeLogo
              name
            }

            managerName
            managerTel
            managerEmail

            limit
            isPay

            price
            isDiscount
            discountPrice
            totalPrice
            orderCount

            applyStartDateTime
            applyEndDateTime
            placeType

            placeZipcode
            placeAddress
            placeAddressDetail
            placeLongitude
            placeLatitude

            ticketCategory {
              id
              name
              parentCategory {
                id
                name
              }
            }
            name
            contents

            isSoldOut
            timestamp

            createdAt
            updatedAt

            ticketProductDates {
              id
              commonStatus
              ticketDate
              ticketProductDateTimes {
                id
                commonStatus
                isSoldOut
                ticketDateTime
              }
            }
            ticketProductDatesCount
          }

          ticketProductDate {
            id
            commonStatus
            ticketDate
          }
          ticketProductDateTime {
            id
            commonStatus
            isSoldOut
            ticketDateTime
          }
        }
        user {
          id
          avatar
          name
        }
        commonStatus
        isSecret
        score
        contents

        isComment
        comment
        commentDate

        createdAt
        updatedAt

        isMe
      }
      count
    }
  }
`;

// 계정 > 선물후기 > 작성완료한 상품후기 상세정보
export const ACCOUNT_REVIEW_QUERY = gql`
  query TicketAccountReviewDetail($id: String!) {
    TicketAccountReviewDetail(id: $id) {
      id
      ticketProduct {
        id
      }
      commonStatus
      isSecret
      score
      contents
      createdAt
      updatedAt
    }
  }
`;

// 계정 > 선물후기 > 작성완료한 상품 후기수정
export const ACCOUNT_REVIEWEDIT_MUTATION = gql`
  mutation TicketAccountReviewEdit(
    $id: String!
    $score: Int!
    $contents: String!
  ) {
    TicketAccountReviewEdit(id: $id, score: $score, contents: $contents)
  }
`;

// 계정 > 선물후기 > 작성완료한 상품 후기삭제
export const ACCOUNT_REVIEWDELETE_MUTATION = gql`
  mutation TicketAccountReviewDelete($id: String!) {
    TicketAccountReviewDelete(id: $id)
  }
`;

// 계정 > 선물문의 > 목록
export const ACCOUNT_INQUIRIES_QUERY = gql`
  query TicketAccountInquiryList($orderBy: String, $skip: Int!, $first: Int!) {
    TicketAccountInquiryList(orderBy: $orderBy, skip: $skip, first: $first) {
      ticketProductInquiries {
        id
        ticketProduct {
          id
          commonStatus
          coverImage

          store {
            id
            storeLogo
            name
          }

          managerName
          managerTel
          managerEmail

          limit
          isPay

          price
          isDiscount
          discountPrice
          totalPrice
          orderCount

          applyStartDateTime
          applyEndDateTime
          placeType

          placeZipcode
          placeAddress
          placeAddressDetail
          placeLongitude
          placeLatitude

          ticketCategory {
            id
            name
            parentCategory {
              id
              name
            }
          }
          name
          contents

          isSoldOut
          timestamp

          createdAt
          updatedAt

          ticketProductDates {
            id
            commonStatus
            ticketDate
            ticketProductDateTimes {
              id
              commonStatus
              isSoldOut
              ticketDateTime
            }
          }
          ticketProductDatesCount
        }
        user {
          id
          avatar
          name
        }
        commonStatus
        isSecret
        question
        answer
        createdAt
        updatedAt

        isMe
      }
      count
    }
  }
`;

// 공지사항 > 목록
export const NOTICES_QUERY = gql`
  query TicketNoticeList($orderBy: String, $skip: Int!, $first: Int!) {
    TicketNoticeList(orderBy: $orderBy, skip: $skip, first: $first) {
      notices {
        id
        title
        contents
        readCount
        createdAt
        updatedAt
      }
      count
    }
  }
`;
