import React from "react";
import styled, { css } from "styled-components";

const LodingBox = styled.div`
  ${({ theme }) => {
    return css`
      position: absolute;
      left: 0;
      bottom: 0;
      right: 0;
      top: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: ${theme.paddings.base} 0;
      .text_box {
        font-size: ${theme.fonts.size.sm};
        color: #777;
      }
    `;
  }}
`;
const StyledSpinner = styled.svg`
  animation: rotate 1s linear infinite;
  margin: 40px;
  width: 50px;
  height: 50px;

  & .path {
    stroke: #1e37dd;
    stroke-linecap: round;
    animation: dash 1.5s ease-in-out infinite;
  }

  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }
  @keyframes dash {
    0% {
      stroke-dasharray: 1, 150;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -35;
    }
    100% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -124;
    }
  }
`;
function Loading({ desc }) {
  return (
    <LodingBox>
      <StyledSpinner viewBox="0 0 50 50">
        <circle
          className="path"
          cx="25"
          cy="25"
          r="20"
          fill="none"
          strokeWidth="2"
        />
      </StyledSpinner>
      <div className="text_box">로딩중입니다.</div>
    </LodingBox>
  );
}
export default Loading;
