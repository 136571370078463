import { useState, useEffect } from "react"
import { withRouter, Link } from "react-router-dom"
import { useQuery, useMutation } from "@apollo/client"
import { gql } from "@apollo/client"
import {
  ACCOUNT_ORDERPRODUCTCONFIG_MUTATION,
  ACCOUNT_ORDERPRODUCT_QUERY,
} from "../../../../../../Config/Queries"

import styled, { css } from "styled-components"
import {
  Select,
  Row,
  Col,
  SModal,
  Stepbox,
} from "../../../../../../styles/styles"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronDown, faCheck } from "@fortawesome/pro-regular-svg-icons"
import Button from "../../../../../Buttons"
import ReservationCalendar from "./ReservationCalendar"
import ReservationCounter from "./ReservationCounter"

import { datesCounter } from "../../../../../../Config/Util"
import { toast } from "react-toastify"
import { ModalLayout } from "../../../../../Layouts/Layout"

function Modal({
  history,
  match,
  location,
  isOpen,
  toggleModal,
  id,
  isReservation,
  count,

  dTicketProduct,
  dTicketOrder,
  dTicketProductDate,
  dTicketProductDateTime,
  dTicketOrderProductPeoples,
}) {
  const ACCOUNT_QUERY = gql`
    query TicketAccountUser {
      TicketAccountUser {
        user {
          avatar
          userId
          name
          email
          phone
          birthDate
          zipcode
          roadAddress
          jibunAddress
          addressDetail
          latitude
          longitude

          role
          visitedAt
          createdAt
          updatedAt
        }
      }
    }
  `

  const { data, loading } = useQuery(ACCOUNT_QUERY, {
    fetchPolicy: "network-only",
  })

  const [orderProductConfigMutation] = useMutation(
    ACCOUNT_ORDERPRODUCTCONFIG_MUTATION,
    {
      refetchQueries: () => [
        {
          query: ACCOUNT_ORDERPRODUCT_QUERY,
          variables: {
            id,
          },
        },
      ],
    }
  )

  const [excludeDates, setExcludeDates] = useState([])

  const [productCount, setProductCount] = useState(dTicketOrder.count)
  const [productPrice, setProductPrice] = useState(dTicketProduct.price)
  const [totalPrice, setTotalPrice] = useState(
    dTicketProduct.price * dTicketOrder.count
  )

  const [ticketProductDate, setTicketProductDate] = useState(dTicketProductDate)
  const [ticketProductDateTime, setTicketProductDateTime] = useState(
    dTicketProductDateTime
  )
  const [ticketDateTimePeoples, setTicketDateTimePeoples] = useState(null)

  const handleTicketDateTimeChange = (e, index) => {
    const { name, value } = e.target

    const rows = [...ticketDateTimePeoples]
    rows[index][name] = value
    setTicketDateTimePeoples(rows)
  }
  const startDate = new Date(dTicketProduct.applyStartDateTime)
  const endDate = new Date(dTicketProduct.applyEndDateTime)

  const onSubmit = async () => {
    if (!ticketProductDate || !ticketProductDateTime) {
      return toast.error(`일정의 시간을 선택해주세요.`)
    }
    for (var loop = 0; loop < ticketDateTimePeoples.length; loop++) {
      if (ticketDateTimePeoples[loop].name === "") {
        return toast.error(`동행자 ${loop + 1}번 이름을 입력해주세요.`)
      }
      if (ticketDateTimePeoples[loop].phone === "") {
        return toast.error(`동행자 ${loop + 1}번 휴대전화를 입력해주세요.`)
      }
    }

    try {
      const {
        data: { TicketAccountOrderProductConfig },
      } = await orderProductConfigMutation({
        variables: {
          id,
          ticketProductDate: ticketProductDate.id,
          ticketProductDateTime: ticketProductDateTime.id,
          ticketDateTimePeoples,
        },
      })

      if (TicketAccountOrderProductConfig) {
        toggleModal()
        // history.replace(`/account/completeOrderProducts`);
      }
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    const productDates = dTicketProduct.ticketProductDates.map((item) => {
      return new Date(item.ticketDate)
    })

    const datesCount = datesCounter(startDate, endDate)
    let dates = []

    for (var loop = 0; loop <= datesCount; loop++) {
      const loopDate = new Date(
        startDate.getFullYear(),
        startDate.getMonth(),
        startDate.getDate() + loop,
        9,
        0,
        0
      )

      if (
        !productDates.some((item) => {
          return item.toString() === loopDate.toString()
        })
      ) {
        dates.push(loopDate)
      }
    }
    setExcludeDates(dates)
  }, [])
  useEffect(() => {
    if (isReservation === true) {
      const rows = dTicketOrderProductPeoples.map((item) => ({
        name: item.name,
        phone: item.phone,
      }))
      setTicketDateTimePeoples(rows)
    } else {
      if (!loading) {
        const {
          TicketAccountUser: { user },
        } = data
        let rows = [{ name: user.name, phone: user.phone }]

        Array.from({ length: count - 1 }, (index) => {
          rows.push({ name: "", phone: "" })
        })

        setTicketDateTimePeoples(rows)
      }
    }
  }, [data, loading])

  return (
    <ModalLayout
      isOpen={isOpen}
      toggleModal={toggleModal}
      title={"예매일 설정"}
    >
      <Stepbox>
        <div>
          <ReservationCalendar
            count={count}
            dTicketProduct={dTicketProduct}
            dTicketOrder={dTicketOrder}
            dTicketProductDateTime={dTicketProductDateTime}
            excludeDates={excludeDates}
            ticketProductDate={ticketProductDate}
            setTicketProductDate={setTicketProductDate}
            ticketProductDateTime={ticketProductDateTime}
            setTicketProductDateTime={setTicketProductDateTime}
          />
        </div>
      </Stepbox>
      <Stepbox>
        <ReservationCounter
          count={count}
          ticketDateTimePeoples={ticketDateTimePeoples}
          handleTicketDateTimeChange={handleTicketDateTimeChange}
        />
      </Stepbox>
      <div style={{ paddingLeft: 24, paddingRight: 24, paddingBottom: 12 }}>
        <Button
          type="button"
          onClick={onSubmit}
          color="primary"
          width="full"
          size={"lg"}
        >
          확인
        </Button>
      </div>
    </ModalLayout>
  )
}

export default withRouter(Modal)
