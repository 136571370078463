import React, { useState, useEffect } from "react"
import { withRouter, Link } from "react-router-dom"
import { useMutation } from "@apollo/client"
import {
  ACCOUNT_ORDERPRODUCT_QUERY,
  ACCOUNT_ORDERPRODUCTREFUND_MUTATION,
} from "../../../../../Config/Queries"

import styled, { css } from "styled-components"
import {
  Row,
  Col,
  Comment,
  FooterButton,
  SOrderItem,
  SOrderInfo,
  Dot,
} from "../../../../../styles/styles"
import SLabel from "../../../../Labels"
import Alter from "../../../../Alter"
import Button from "../../../../Buttons"
import Accordions from "../../../../Accordions"
import ReservationModal from "../../../../OrderProduct/Account/OrderProduct/Detail/Reservation/ReservationModal"
import SendModal from "../../../../OrderProduct/Account/OrderProduct/Detail/Send/SendModal"
import UsageModal from "../../../../OrderProduct/Account/OrderProduct/Detail/Usage/UsageModal"
import ReactMoment from "react-moment"
import NumberFormat from "react-number-format"
import moment from "moment"
import { graphqlUrl, orderinfo } from "../../../../../Config/Env"
import { toast } from "react-toastify"

import {
  dayOfWeekName,
  isPayName,
  placeTypeName,
} from "../../../../../Config/Util"
import KakaoMap from "../../../../KakaoMap"
import RowInfo from "../../../../RowInfo"
import Labels from "../../../../Labels"

const TicketBox = styled.div`
  ${({ theme }) => {
    return css`
      position: relative;
      overflow: hidden;
      .TicketBox_content {
        padding: ${theme.paddings.base} ${theme.paddings.xl};
      }
      .box_top {
        display: flex;
        padding: 15px;
        flex: 1;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        :before {
          content: "";
          position: absolute;
          top: 0;
          width: 100%;
          height: 100px;
          background-color: #f2f4f7;
        }
        .img {
          margin: 20px 0 0;
          position: relative;
          max-width: 200px;
          overflow: hidden;
          border-radius: 5px;
          overflow: hidden;
          :before {
            content: "";
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            border: 1px solid rgba(0, 0, 0, 0.05);
            border-radius: 5px;
          }
          img {
            max-width: 100%;
            display: block;
            width: 100%;
            height: auto;
          }
        }
      }
      .box_content {
        .userInfo {
          span {
            display: block;
            font-size: ${theme.fonts.size.sm};
            font-weight: ${theme.fonts.weight.base};
            color: ${theme.colors.black04};
          }
        }
        .map_box {
          margin-top: ${theme.margins.sm};
          border-radius: 5px;
          overflow: hidden;
          border: solid 1px #eee;
          .map {
            min-height: 160px;
          }
          .txt {
            background: ${theme.colors.lightgray};
            padding: 8px 10px;
            text-align: center;
            font-size: 14px;
            color: #444;
          }
        }
      }
    `
  }}
`
const SSOrderItem = styled(SOrderItem)`
  ${({ theme }) => {
    return css`
      margin-bottom: 0px;
      border-radius: 0;
      border: 0;
      border-top: solid 5px #eee;
      padding: ${theme.paddings.lg};
      .item_content {
        border-radius: 8px;
        border: solid 1px #eee;
        .product_list {
          flex-direction: column;
          padding: 0;
        }
      }
      .item_bottom {
        padding: ${theme.paddings.base} 0 0;
      }
    `
  }}
`
const SAccordion = styled(Accordions)`
  ${({ theme }) => {
    return css`
      padding: 0;
    `
  }}
`
const SAlter = styled(Alter)`
  ${({ theme }) => {
    return css`
      margin-top: ${theme.margins.base};
    `
  }}
`

function Product({ history, match, location, isApp, data }) {
  console.log({ data })
  const {
    TicketAccountOrderProductDetail: {
      id,
      commonStatus,

      isReservation,
      isMember,
      user,
      name,
      phone,
      count,

      ticketProduct,
      ticketOrder,

      ticketProductDate,
      ticketProductDateTime,
      ticketOrderProductPeoples,

      isRefund,

      cashier,

      usedAt,
      createdAt,
    },
  } = data

  const [sendModalIsOpen, setSendModalIsOpen] = useState(false)
  const sendToggleModal = () => {
    setSendModalIsOpen(!sendModalIsOpen)
  }

  const [reservationModalIsOpen, setReservationModalIsOpen] = useState(false)
  const reservationToggleModal = () => {
    setReservationModalIsOpen(!reservationModalIsOpen)
  }
  const [orderProductRefundMutation] = useMutation(
    ACCOUNT_ORDERPRODUCTREFUND_MUTATION,
    {
      refetchQueries: () => [
        {
          query: ACCOUNT_ORDERPRODUCT_QUERY,
          variables: {
            id,
          },
        },
      ],
    }
  )
  const onRefund = async () => {
    console.log("onRefund")
    if (!window.confirm("티켓을 구매자에게 반환(환불)하시겠습니까?")) {
      return
    }

    try {
      const {
        data: { TicketAccountOrderProductRefund },
      } = await orderProductRefundMutation({
        variables: { id },
      })

      if (TicketAccountOrderProductRefund) {
        history.replace(`/account/completeOrderProducts`)
      }
    } catch (e) {
      console.log(e)
    }
  }

  const [usageModalIsOpen, setUsageModalIsOpen] = useState(false)
  const usageToggleModal = () => {
    setUsageModalIsOpen(!usageModalIsOpen)
  }

  const AvatarImage =
    user.avatar === null
      ? "/assets/img/product/product-default.jpg"
      : `${graphqlUrl}${user.avatar}`

  const CoverImage =
    ticketProduct.coverImage === null
      ? "/assets/img/product/product-default.jpg"
      : `${graphqlUrl}${ticketProduct.coverImage}`

  const startDate = new Date(
    isReservation === true
      ? ticketProductDateTime?.ticketDateTime
      : ticketProduct.applyStartDateTime
  )
  const endDate = new Date(
    isReservation === true
      ? moment(ticketProductDateTime?.ticketDateTime).add(
          parseInt(ticketProduct.minute),
          "minutes"
        )
      : ticketProduct.applyEndDateTime
  )

  console.log({ startDate, endDate, isRefund })

  function Receiver() {
    return (
      <div className="box_content">
        <RowInfo
          border={"bottom"}
          textLeft={"티켓번호"}
          textRight={
            <>
              <ReactMoment format="mmssSSS">{createdAt}</ReactMoment>
            </>
          }
        />
        <RowInfo border={"bottom"} textLeft={"이름"} textRight={name} />
        <RowInfo border={"bottom"} textLeft={"연락처"} textRight={phone} />
      </div>
    )
  }
  function TicketInfo() {
    const startDate = new Date(ticketProductDateTime?.ticketDateTime)
    const endDate = moment(ticketProductDateTime?.ticketDateTime).add(
      parseInt(ticketProduct.minute),
      "minutes"
    )
    return (
      <div className="box_content">
        <RowInfo
          border={"bottom"}
          textLeft={"상태"}
          textRight={
            <>
              {isReservation === true && "예매완료"}
              {isReservation === false && "예매대기"}
            </>
          }
        />
        <RowInfo
          border={"bottom"}
          textLeft={"행사일시"}
          textRight={
            <>
              <ReactMoment format="YYYY.MM.DD">{startDate}</ReactMoment>(
              {dayOfWeekName(startDate.getDay())}){" "}
              <ReactMoment format="HH:mm">{startDate}</ReactMoment> ~{" "}
              <ReactMoment format="HH:mm">{endDate}</ReactMoment>
            </>
          }
        />
        <RowInfo
          border={"bottom"}
          textLeft={"시간"}
          textRight={
            <>
              <NumberFormat
                value={ticketProduct.minute}
                displayType={"text"}
                thousandSeparator={true}
              />
              분
            </>
          }
        />
        <RowInfo
          border={"bottom"}
          textLeft={"장소"}
          textRight={
            <>
              {ticketProduct.placeType === "OffLine"
                ? `${ticketProduct.placeAddress} ${ticketProduct.placeAddressDetail}`
                : placeTypeName(ticketProduct.placeType)}
            </>
          }
        />
        {ticketProduct.placeType === "OffLine" && (
          <div className="map_box">
            <div className="map">
              <KakaoMap
                longitude={ticketProduct.placeLongitude}
                latitude={ticketProduct.placeLatitude}
              />
            </div>
          </div>
        )}
      </div>
    )
  }
  function Reserve() {
    return (
      <div className="box_content">
        {ticketOrderProductPeoples.map((item, index) => {
          return (
            <RowInfo
              border={"bottom"}
              textLeft={
                <>
                  <div className="userInfo">
                    <b>{item.name}</b>
                    <span>{item.phone}</span>
                  </div>
                </>
              }
              textRight={
                <>
                  {item.commonStatus === "S" && (
                    <SLabel color="lightgray">참여대기</SLabel>
                  )}
                  {item.commonStatus === "C" && (
                    <SLabel color="lightprimary">참여완료</SLabel>
                  )}
                  {item.commonStatus === "D" && (
                    <SLabel color="lightdanger">불참</SLabel>
                  )}
                </>
              }
            />
          )
        })}
      </div>
    )
  }
  function Refund() {
    return <div className="box_content">{orderinfo.Refund.content}</div>
  }
  const [items, setItems] = useState([
    {
      uuid: "0",
      heading: "수신자정보",
      content: <Receiver createdAt={createdAt} name={name} phone={phone} />,
    },
  ])
  useEffect(() => {
    if (commonStatus === "S") {
      if (isReservation) {
        setItems([
          {
            uuid: "0",
            heading: "수신자정보",
            content: <Receiver />,
          },
          {
            uuid: "1",
            heading: "티켓정보",
            content: <TicketInfo />,
          },
          {
            uuid: "2",
            heading: `참여자(${data.TicketAccountOrderProductDetail.ticketOrderProductPeoples.length}명)`,
            content: <Reserve />,
          },
          {
            uuid: "3",
            heading: `예매/취소 안내`,
            content: <Refund />,
          },
        ])
      }
    }
    if (commonStatus === "C") {
      setItems([
        {
          uuid: "0",
          heading: "수신자정보",
          content: <Receiver />,
        },
        {
          uuid: "1",
          heading: "티켓정보",
          content: <TicketInfo />,
        },
        {
          uuid: "2",
          heading: `참여자(${data.TicketAccountOrderProductDetail.ticketOrderProductPeoples.length}명)`,
          content: <Reserve />,
        },
        {
          uuid: "3",
          heading: `예매/취소 안내`,
          content: <Refund />,
        },
      ])
    }
  }, [data])

  const [isDetail, setIsDetail] = useState(false)
  const toggleView = () => {
    setIsDetail(!isDetail)
  }
  const toggleDetail = () => {
    setIsDetail(!isDetail)
  }
  return (
    <>
      <TicketBox>
        <SSOrderItem>
          <div className={`item_content `}>
            <div className="product_list">
              <Link to={`/products/${ticketProduct.id}`}>
                <div className="img">
                  <img src={CoverImage} alt={ticketProduct.name} />
                  {commonStatus === "S" && endDate < new Date() && (
                    <>
                      <div className="complete_icon">
                        <div>
                          예매기간
                          <br />
                          만료
                        </div>
                      </div>
                    </>
                  )}
                </div>
                <div className="cont">
                  <span className="category">{ticketProduct.store.name}</span>
                  <strong>{ticketProduct.name}</strong>
                  <div>
                    {commonStatus === "S" && (
                      <>
                        {isReservation === true && (
                          <div className="date">
                            <ReactMoment format="YYYY.MM.DD">
                              {startDate}
                            </ReactMoment>
                            ({dayOfWeekName(startDate.getDay())}){" "}
                            <ReactMoment format="HH:mm">
                              {startDate}
                            </ReactMoment>{" "}
                            ~{" "}
                            <ReactMoment format="HH:mm">{endDate}</ReactMoment>
                          </div>
                        )}
                        {isReservation === false && (
                          <div className="date">
                            <ReactMoment format="YYYY.MM.DD">
                              {startDate}
                            </ReactMoment>
                            ({dayOfWeekName(startDate.getDay())}) ~{" "}
                            <ReactMoment format="YYYY.MM.DD">
                              {endDate}
                            </ReactMoment>
                            ({dayOfWeekName(endDate.getDay())})
                          </div>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </Link>
              <Row className="item_bottom">
                <Col left className="left">
                  <span>구매자. {user.name}</span>
                </Col>
                <Col right className="right">
                  {commonStatus === "S" && (
                    <>
                      <span>
                        <b>{count}</b>개
                      </span>{" "}
                      <Dot />
                      {new Date(ticketProduct?.applyEndDateTime) <=
                      new Date() ? (
                        <>
                          <Labels color="lightwarning" bg="none">
                            예매기간 만료
                          </Labels>
                        </>
                      ) : (
                        <>
                          {ticketProductDateTime?.ticketDateTime &&
                          new Date(ticketProductDateTime?.ticketDateTime) <=
                            new Date() ? (
                            <>
                              <Labels color="lightwarning" bg="none">
                                예매일 만료
                              </Labels>
                            </>
                          ) : (
                            <>
                              {isReservation === true && (
                                <Labels color="lightprimary" bg="none">
                                  예매완료
                                </Labels>
                              )}
                              {isReservation === false && <span>예매대기</span>}
                            </>
                          )}
                        </>
                      )}
                    </>
                  )}
                  {commonStatus === "C" && (
                    <>
                      {isRefund === true && (
                        <>
                          <span className="ready">반환완료</span>
                        </>
                      )}
                      {isRefund === false && (
                        <>
                          <span className="ready">사용완료</span>
                        </>
                      )}
                    </>
                  )}
                </Col>
              </Row>
            </div>
          </div>
        </SSOrderItem>

        <SAccordion
          preExpanded={isReservation === true ? ["0", "1", "2"] : ["0"]}
          items={items}
        />

        <div className="TicketBox_content">
          {commonStatus === "S" &&
            isRefund === false &&
            endDate >= new Date() && (
              <>
                <Row className="row">
                  <Button
                    onClick={reservationToggleModal}
                    color="lightprimary"
                    width="full"
                  >
                    예매일 설정
                  </Button>
                  <Button
                    onClick={sendToggleModal}
                    color="lightwarning"
                    width="full"
                  >
                    양도
                  </Button>
                  <Button onClick={onRefund} color="lightdanger" width="full">
                    반환
                  </Button>
                </Row>
                <ReservationModal
                  isOpen={reservationModalIsOpen}
                  toggleModal={reservationToggleModal}
                  id={id}
                  isReservation={isReservation}
                  count={count}
                  dTicketProduct={ticketProduct}
                  dTicketOrder={ticketOrder}
                  dTicketProductDate={ticketProductDate}
                  dTicketProductDateTime={ticketProductDateTime}
                  dTicketOrderProductPeoples={ticketOrderProductPeoples}
                />
                <SendModal
                  isOpen={sendModalIsOpen}
                  toggleModal={sendToggleModal}
                />
              </>
            )}
        </div>
      </TicketBox>

      {commonStatus === "S" &&
        isReservation === true &&
        endDate >= new Date() && (
          <>
            <FooterButton>
              <div>
                <Button
                  onClick={() => {
                    if (isApp) {
                      const data = {
                        method: "payment",
                        data: { id },
                      }
                      window.ReactNativeWebView.postMessage(
                        JSON.stringify(data)
                      )
                    } else {
                      toast.error(
                        "티켓사용은 앱을 이용하여 사용할 수 있습니다."
                      )
                      return
                    }
                  }}
                  color="primary"
                  width="full"
                >
                  사용하기
                </Button>
              </div>
            </FooterButton>

            <UsageModal
              isOpen={usageModalIsOpen}
              toggleModal={usageToggleModal}
              id={id}
            />
          </>
        )}
    </>
  )
}

export default withRouter(Product)
