import React, { useState, useEffect } from "react"

import styled, { css } from "styled-components"
import {
  Col,
  Dot,
  Ready,
  Row,
  SDatePicker,
  TicketList,
} from "../../../../../../styles/styles"
import DatePicker, { registerLocale } from "react-datepicker"
import ko from "date-fns/locale/ko"
import "react-datepicker/dist/react-datepicker.css"
import Button from "../../../../../Buttons"
import ReactMoment from "react-moment"
import moment from "moment"
import NumberFormat from "react-number-format"
import { toast } from "react-toastify"
import { dayOfWeekName } from "../../../../../../Config/Util"
registerLocale("ko", ko)

function ReservationCalendar({
  dTicketProduct,
  dTicketOrder,
  dTicketProductDateTime,
  excludeDates,
  ticketProductDate,
  setTicketProductDate,
  ticketProductDateTime,
  setTicketProductDateTime,
}) {
  console.log({ dTicketProductDateTime })

  const startDate = new Date(dTicketProduct.applyStartDateTime)
  const endDate = new Date(dTicketProduct.applyEndDateTime)

  const toDate = new Date()
  // toDate.setDate(toDate.getDate() + 2);
  const minDate = startDate < toDate ? toDate : startDate
  const maxDate = endDate

  const [selectDate, setSelectDate] = useState(
    ticketProductDate ? new Date(ticketProductDate.ticketDate) : new Date()
  )
  const [selectDateTime, setSelectDateTime] = useState(new Date())
  const [selectTicketProductDate, setSelectTicketProductDate] = useState(null)

  useEffect(() => {
    if (selectDate) {
      const findTicketProductDate = dTicketProduct.ticketProductDates.find(
        (item) => {
          return (
            new Date(item.ticketDate).toString().substring(0, 10) ===
            selectDate.toString().substring(0, 10)
          )
        }
      )
      setSelectTicketProductDate(findTicketProductDate)
    }
  }, [selectDate, setSelectDate])

  return (
    <>
      <SDatePicker>
        <DatePicker
          renderCustomHeader={({ date, decreaseMonth, increaseMonth }) => (
            <div className="custom_header">
              <button className="prev_button" onClick={decreaseMonth}>
                <span></span>
              </button>
              <div className="custom_header_month">
                {date.getFullYear()}년 {date.getMonth() + 1}월
              </div>
              <button className="next_button" onClick={increaseMonth}>
                <span></span>
              </button>
            </div>
          )}
          selected={selectDate}
          onChange={(date) => {
            setSelectDate(date)
          }}
          dateFormat="YYYY-MM-DD"
          locale="ko"
          minDate={minDate}
          maxDate={maxDate}
          excludeDates={excludeDates}
          inline
        />
      </SDatePicker>
      <TicketList>
        <div className="title">
          시간선택(
          {selectTicketProductDate?.ticketProductDateTimes.length > 0
            ? selectTicketProductDate?.ticketProductDateTimes.length
            : 0}
          건)
        </div>
        {selectTicketProductDate ? (
          <ul>
            {selectTicketProductDate?.ticketProductDateTimes.map((item) => {
              const startDate = new Date(item.ticketDateTime)
              const endDate = moment(item.ticketDateTime).add(
                parseInt(dTicketProduct.minute),
                "minutes"
              )

              const isSoldOut =
                item.isSoldOut ||
                (dTicketProduct?.limit <=
                  item?.ticketOrderProductPeoplesCount + dTicketOrder.count &&
                  item?.id !== dTicketProductDateTime?.id)

              return (
                <li key={item.id}>
                  <a
                    onClick={() => {
                      if (!isSoldOut) {
                        setTicketProductDate(selectTicketProductDate)
                        setTicketProductDateTime(item)
                      }
                    }}
                    className={`ticketItem ${
                      item.id === ticketProductDateTime?.id ? "active" : ""
                    } ${isSoldOut ? "soldout" : ""}`}
                  >
                    <Col className="left">
                      <strong>
                        {isSoldOut && <span>[마감]</span>}{" "}
                        <ReactMoment format="YYYY.MM.DD">
                          {startDate}
                        </ReactMoment>
                        ({dayOfWeekName(startDate.getDay())}){" "}
                        <ReactMoment format="HH:mm">{startDate}</ReactMoment> ~{" "}
                        <ReactMoment format="HH:mm">{endDate}</ReactMoment>
                      </strong>
                      <Row className="cont">
                        <span>
                          {dTicketProduct.isPay ? (
                            <NumberFormat
                              value={dTicketProduct.totalPrice}
                              displayType={"text"}
                              thousandSeparator={true}
                              suffix="드림"
                            />
                          ) : (
                            `무료`
                          )}
                        </span>
                        <Dot />
                        <NumberFormat
                          value={item.ticketOrderProductPeoplesCount}
                          displayType={"text"}
                          thousandSeparator={true}
                          suffix="명"
                        />
                        /
                        <NumberFormat
                          value={dTicketProduct.limit}
                          displayType={"text"}
                          thousandSeparator={true}
                          suffix="명"
                        />
                      </Row>
                    </Col>
                    <input type="checkbox" />
                  </a>
                </li>
              )
            })}
          </ul>
        ) : (
          <Ready>등록된 시간이 없습니다.</Ready>
        )}
      </TicketList>
    </>
  )
}
export default ReservationCalendar
