import React, { useState, useEffect } from "react";
import { withRouter, Link } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { gql } from "@apollo/client";
import {
  ACCOUNT_ORDERPRODUCTUSAGE_MUTATION,
  ACCOUNT_ORDERPRODUCT_QUERY,
} from "../../../../../../Config/Queries";

import styled, { css } from "styled-components";
import {
  Select,
  Row,
  Col,
  SModal,
  Stepbox,
} from "../../../../../../styles/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faCheck } from "@fortawesome/pro-regular-svg-icons";
import Button from "../../../../../Buttons";

import QrReader from "react-qr-reader";
import { toast } from "react-toastify";

function Modal({ history, match, location, isOpen, toggleModal, id }) {
  const [delay, setDelay] = useState(500);

  const [orderProductConfigMutation] = useMutation(
    ACCOUNT_ORDERPRODUCTUSAGE_MUTATION,
    {
      refetchQueries: () => [
        {
          query: ACCOUNT_ORDERPRODUCT_QUERY,
          variables: {
            id,
          },
        },
      ],
    }
  );

  const handleScan = async (result) => {
    console.log({ result });
    if (result) {
      const dataSplit = result.split("-");
      const method = dataSplit[0];
      const qrCodeStoreUserId = dataSplit[1];

      try {
        const {
          data: { TicketAccountOrderProductUsage },
        } = await orderProductConfigMutation({
          variables: {
            ticketOrderProductId: id,
            qrCodeStoreUserId,
          },
        });

        if (TicketAccountOrderProductUsage) {
          toast.success("티켓팅이 완료되었습니다.");
          toggleModal();
        }
      } catch (e) {
        console.log(e);
        var error = e.toString();
        error = error.replace("Error: GraphQL error:", "");
        toast.error(error);
      }
    }
  };
  const handleError = (err) => {
    console.error({ err });
  };

  return (
    <SModal>
      <div className={isOpen ? "openModal modal" : "modal"}>
        {isOpen ? (
          <section>
            <header>
              <button onClick={toggleModal}>
                <FontAwesomeIcon icon={faChevronDown} />
              </button>
            </header>
            <main>
              <Stepbox>
                <div>
                  <QrReader
                    delay={delay}
                    style={{
                      height: "100%",
                      width: "100%",
                      display: "flex",
                      "justifty-content": "center",
                    }}
                    onError={handleError}
                    onScan={handleScan}
                  />
                </div>
              </Stepbox>
            </main>
          </section>
        ) : null}
      </div>
    </SModal>
  );
}

export default withRouter(Modal);
