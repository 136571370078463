import React, { useState, useEffect } from "react"
import { withRouter, Link } from "react-router-dom"
import { useMutation } from "@apollo/client"
import { gql } from "@apollo/client"
import {
  GUEST_ORDERPRODUCTUSAGE_MUTATION,
  GUEST_TICKETDETAIL_QUERY,
} from "../../../../../../Config/Queries"

import styled, { css } from "styled-components"
import QrReader from "react-qr-reader"
import { toast } from "react-toastify"
import { faClose } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const ModalContainer = styled.div`
  ${({ theme }) => {
    return css`
      .modal {
        display: none;
        position: fixed;
        bottom: 0;
        left: 0;
        right: -0;
        z-index: 999;
        ::before {
          content: "";
          position: fixed;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          background-color: rgba(0, 0, 0, 0.6);
          z-index: 11;
        }
        section {
          position: relative;
          box-shadow: 0 -3px 3px rgba(0, 0, 0, 0.1);
          width: 100%;
          max-width: 750px;
          margin: 0 auto;
          border-top-right-radius: 30px;
          border-top-left-radius: 30px;
          background-color: #fff;
          /* 팝업이 열릴때 스르륵 열리는 효과 */
          animation: modal-show 0.3s;
          z-index: 15;
          main {
            ${theme.device.mobile} {
              overflow-y: scroll;
              ::-webkit-scrollbar {
                display: none;
              }
            }
          }
        }
        header {
          position: relative;
          margin-top: 12px;
          height: 46px;
          font-weight: 700;
          justify-content: space-between;
          align-items: center;
          display: flex;
          flex-direction: row;
          .right {
            width: 46px;
            height: 46px;
          }
          .title {
            font-size: ${theme.fonts.size.base};
            font-weight: ${theme.fonts.weight.bold};
            align-items: center;
          }
          button {
            width: 46px;
            height: 46px;
            font-size: 21px;
            font-weight: 700;
            text-align: center;
            border: 0;
            color: #999;
            background-color: transparent;
          }
        }
        footer {
          height: 60px;
          flex: 1;
          display: flex;
          border-top: solid 1px #eee;
          a,
          button {
            margin-left: 0 !important;
            height: 60px;
            line-height: 60px;
          }
        }
      }
      .modal.openModal {
        display: flex;
        align-items: center;
        animation: modal-bg-show 0.3s;
      }
      @keyframes modal-show {
        from {
          transform: translateY(50%);
        }
        to {
          transform: translateY(0);
        }
      }
    `
  }}
`
function Modal({ history, match, location, isOpen, toggleModal, id }) {
  const [delay, setDelay] = useState(500)
  const [qrCodeStoreUserId, setQrCodeStoreUserId] = useState(null)

  const [orderProductConfigMutation] = useMutation(
    GUEST_ORDERPRODUCTUSAGE_MUTATION,
    {
      refetchQueries: () => [
        {
          query: GUEST_TICKETDETAIL_QUERY,
          variables: {
            id,
          },
        },
      ],
    }
  )

  const handleScan = async (result) => {
    console.log({ result })
    if (result) {
      const dataSplit = result.split("-")
      const method = dataSplit[0]
      const qrCodeStoreUserId = dataSplit[1]

      try {
        const {
          data: { TicketGuestOrderProductUsage },
        } = await orderProductConfigMutation({
          variables: {
            ticketOrderProductId: id,
            qrCodeStoreUserId,
          },
        })

        if (TicketGuestOrderProductUsage) {
          toggleModal()
        }
      } catch (e) {
        console.log(e)
        var error = e.toString()
        error = error.replace("Error: GraphQL error:", "")
        toast.error(error)
      }
    }
  }
  const handleError = (err) => {
    console.error({ err })
  }

  const onSubmit = async () => {}

  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  })
  useEffect(() => {
    window.onresize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      })
    }
  }, [isOpen])
  return (
    <ModalContainer>
      <div className={isOpen ? "openModal modal" : "modal"}>
        <section>
          <header>
            <div className="right" />
            <h3 className="title">티켓 사용하기</h3>
            <button onClick={toggleModal}>
              <FontAwesomeIcon icon={faClose} color={"#222"} size={24} />
            </button>
          </header>
          <main>
            <QrReader
              delay={delay}
              style={{
                height: "100%",
                width: "100%",
                display: "flex",
                "justifty-content": "center",
              }}
              onError={handleError}
              onScan={handleScan}
            />
          </main>
        </section>
      </div>
    </ModalContainer>
  )
}

export default withRouter(Modal)
