import React from "react"
import { withRouter, Link } from "react-router-dom"

import { Row, Col, SOrderItem, Dot } from "../../../../../styles/styles"
import Links from "../../../../Links"
import Label from "../../../../Labels"
import { graphqlUrl } from "../../../../../Config/Env"
import ReactMoment from "react-moment"
import moment from "moment"
import NumberFormat from "react-number-format"
import renderHTML from "react-render-html"
import {
  dayOfWeekName,
  isPayName,
  placeTypeName,
} from "../../../../../Config/Util"

function Item({ history, match, location, item }) {
  console.log({ item })
  const CoverImage =
    item.ticketProduct.coverImage === null
      ? "/assets/img/product/product-default.jpg"
      : `${graphqlUrl}${item.ticketProduct.coverImage}`
  const startDate = new Date(item.ticketProduct.applyStartDateTime)
  const endDate = new Date(item.ticketProduct.applyEndDateTime)

  return (
    <SOrderItem>
      <Row className="item_top">
        <Col className="left">
          <span className="date">
            구매일자 :{" "}
            <ReactMoment format="YYYY.MM.DD HH:mm">
              {item.createdAt}
            </ReactMoment>
          </span>
        </Col>
        <Col className="right">
          <Link to={`/account/orders/${item.id}`}>상세정보</Link>
        </Col>
      </Row>
      <div className="item_content" style={{ paddingTop: 0 }}>
        <div className="product_list">
          <Link to={`/products/${item.ticketProduct.id}`}>
            <div className="img">
              <img src={CoverImage} alt={item.ticketProduct.name} />
            </div>
            <div className="cont">
              <span className="category">{item.ticketProduct.store.name}</span>
              <strong>{item.ticketProduct.name}</strong>
              <div className="option">
                예매기간 :{" "}
                <ReactMoment format="YYYY.MM.DD">{startDate}</ReactMoment>(
                {dayOfWeekName(startDate.getDay())}) ~{" "}
                <ReactMoment format="YYYY.MM.DD">{endDate}</ReactMoment>(
                {dayOfWeekName(endDate.getDay())})
              </div>
            </div>
          </Link>
        </div>
      </div>
      <Row className="item_bottom">
        <Col left className="left">
          <span>
            수신자. {item.ticketOrderProducts[0]?.name}님
            {renderHTML(
              item.ticketOrderProducts.length > 1
                ? `외 <b>${item.ticketOrderProducts.length - 1}</b>명`
                : ``
            )}
          </span>
        </Col>
        <Col right className="right">
          {item.ticketProduct.isPay ? (
            <>
              <span>
                <b>
                  <NumberFormat
                    value={item.totalPrice}
                    displayType={"text"}
                    thousandSeparator={true}
                  />
                </b>
                드림
              </span>
            </>
          ) : (
            <span>
              <b>{isPayName(String(item.ticketProduct.isPay))}</b>
            </span>
          )}
          <Dot />
          <span>
            <b>{item.totalCount}</b>개
          </span>
        </Col>
      </Row>
    </SOrderItem>
  )
}
export default withRouter(Item)
