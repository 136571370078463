import React, { useState, useEffect } from "react"
import { withRouter } from "react-router-dom"
import { useQuery, useMutation } from "@apollo/client"
import {
  INQUIRY_QUERY,
  INQUIRYEDIT_MUTATION,
  INQUIRIES_QUERY,
} from "../../Config/Queries"

import { Layout } from "../../Components/Layouts/Layout"
import PageTitle from "../../Components/Layouts/PageTitle"
import styled, { css } from "styled-components"
import { Container, FooterButton } from "../../styles/styles"
import { useForm } from "react-hook-form"
import FormError from "../../Components/FormError"
import Alter from "../../Components/Alter"
import Button from "../../Components/Buttons"
import { Checkbox } from "@createnl/grouped-checkboxes"
import SLoading from "../../Components/Loading"
import qs from "qs"

const SContainer = styled(Container)`
  ${({ theme }) => {
    return css`
      background-color: ${theme.colors.lightgray};
      padding: ${theme.paddings.base};
    `
  }}
`
const SAlter = styled(Alter)`
  ${({ theme }) => {
    return css`
      border-radius: 0;
      p {
        margin-bottom: 0;
      }
    `
  }}
`
const SFormbox = styled.div`
  ${({ theme }) => {
    return css`
      background-color: white;
      overflow: hidden;
      border-radius: 5px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
      form {
        .form_group {
          border-bottom: solid 1px #e5e5e5;
          font-size: 14px;
          div {
            display: flex;
          }
          label {
            display: flex;
            color: #555;
            textarea {
              background-color: #fafafa;
              width: 100%;
              padding: ${theme.paddings.base};
            }
          }
        }
      }
      .checkList {
        background-color: white;
        border-bottom: solid 1px #e5e5e5;
        li {
          flex: 1;
          display: flex;
          label {
            flex: 1;
            display: flex;
            padding: ${theme.paddings.base};
            flex-direction: row;
            align-items: center;
            justify-content: center;
            color: #666;
            span {
              flex: 1;
              display: flex;
              justify-content: flex-start;
              color: #666;
              font-size: ${theme.fonts.size.base};
            }
            .check {
              display: flex;
              justify-content: flex-end;
            }
            input {
              display: flex;
              position: relative;
              justify-content: flex-end;
            }
            input[type="checkbox"] {
              background-color: transparent;
              border: solid 1px #ddd;
              display: flex;
              align-items: center;
              justify-content: center;
              width: 24px;
              height: 24px;
              border-radius: 50%;
              :checked {
                background-color: ${theme.colors.primary};
                border: solid 1px ${theme.colors.primary};
                ::before {
                  content: "\f00c";
                  font-family: ${theme.fonts.family.fontAwesome};
                  color: #fff;
                }
              }
            }
            a {
              display: flex;
              align-items: center;
              font-size: ${theme.fonts.size.sm};
              color: #999;
              svg {
                margin-left: 0.5rem;
              }
            }
          }
        }
      }
    `
  }}
`

function Edit({ history, match, location }) {
  const id = match.params.id
  const inquiryId = match.params.inquiryId

  const queryString = qs.parse(location.search.substr(1))
  const redirect = queryString.redirect ? queryString.redirect : "/products"

  const [ticketProductId, setTicketProductId] = useState(null)

  const { data, loading } = useQuery(INQUIRY_QUERY, {
    fetchPolicy: "network-only",
    variables: {
      id: inquiryId,
    },
  })

  const [inquiryEditMutation, { editLoading }] = useMutation(
    INQUIRYEDIT_MUTATION,
    {
      refetchQueries: () => [
        {
          query: INQUIRIES_QUERY,
          variables: {
            ticketProduct: id,
            orderBy: "id_DESC",
            skip: 0,
            first: 10,
          },
        },
      ],
    }
  )
  const { register, handleSubmit, errors, setValue } = useForm()

  const onSubmit = async (data) => {
    console.log(data)
    try {
      const {
        data: { TicketProductInquiryEdit },
      } = await inquiryEditMutation({
        variables: {
          id: inquiryId,
          isSecret: data.isSecret,
          question: data.question,
        },
      })

      if (TicketProductInquiryEdit) {
        let uri = ``
        if (redirect === "/products") {
          uri = `${redirect}/${ticketProductId}`
        }
        if (redirect === "/account/inquiries") {
          uri = `${redirect}`
        }
        history.push(uri)
      }
    } catch (e) {
      console.log(e)
    }
  }
  useEffect(() => {
    if (!loading) {
      const {
        TicketProductInquiryDetail: { ticketProduct, isSecret, question },
      } = data
      setTicketProductId(ticketProduct.id)
      setValue("isSecret", isSecret)
      setValue("question", question)
    }
  }, [data, loading, setValue])

  if (!loading) {
    const {
      TicketProductInquiryDetail: { isSecret },
    } = data
    return (
      <>
        <Layout isNav={false}>
          <PageTitle title="상품문의하기" />
          <SContainer>
            <SFormbox>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="form_group">
                  <label>
                    <textarea
                      name="question"
                      placeholder="문의내용을 입력해주세요."
                      ref={register({
                        required: "문의내용을 입력해주세요.",
                      })}
                      rows="10"
                    ></textarea>
                  </label>
                </div>
                <FormError message={errors?.question?.message} />

                <div className="checkList">
                  <ul>
                    <li>
                      <label>
                        <span>비밀글</span>
                        <Checkbox
                          name="isSecret"
                          className="check"
                          ref={register()}
                          checked={isSecret}
                        />
                      </label>
                    </li>
                  </ul>
                </div>

                <SAlter color="lightgray">
                  <p>
                    * 문의하신 내용은 상품판매 가게에서 빠른시일 이내 확인 후
                    답변드립니다.
                  </p>
                </SAlter>
                <FooterButton>
                  <div>
                    <Button
                      type="submit"
                      disabled={editLoading}
                      color="primary"
                      width="full"
                    >
                      {editLoading ? "처리중..." : "문의수정"}
                    </Button>
                  </div>
                </FooterButton>
              </form>
            </SFormbox>
          </SContainer>
        </Layout>
      </>
    )
  } else {
    return <SLoading />
  }
}

export default withRouter(Edit)
