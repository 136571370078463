import React from "react"
import { withRouter, Link } from "react-router-dom"
import { useMutation } from "@apollo/client"
import { LOGIN_MUTATION } from "../../Config/Queries"

import styled, { css } from "styled-components"
import { Layout } from "../../Components/Layouts/Layout"
import PageTitle from "../../Components/Layouts/PageTitle"
import { Wrap } from "../../styles/styles"
import { logUserIn } from "../../apollo"
import { useForm } from "react-hook-form"
import FormError from "../../Components/FormError"
import Button from "../../Components/Buttons"
import { toast } from "react-toastify"

const Wrapper = styled.div`
  ${({ theme }) => {
    return css`
      flex: 1;
      display: flex;
      padding: ${theme.paddings.base} ${theme.paddings.lg};
      justify-content: center;
      flex-direction: column;
    `
  }}
`
const LoginBox = styled.div`
  ${({ theme }) => {
    return css`
      margin-bottom: 10px;
      .logo {
        display: flex;
        flex: 1 1 auto;
        justify-content: center;
        align-items: center;
        img {
          height: auto;
        }
      }
      form {
        margin-top: 35px;
        .form_group {
          display: flex;
          flex-direction: column;
          position: relative;
          margin-bottom: ${theme.margins.lg};
          label {
            color: ${theme.colors.black04};
            font-size: 13px;
            margin-bottom: ${theme.margins.s};
          }
          input {
            height: 46px;
            padding: 0 ${theme.paddings.base};
            border: solid 1px #eee;
            border-radius: 4px;
            font-size: ${theme.fonts.size.base};
            &::placeholder {
              font-size: 1.3rem;
              color: #bbb;
            }
          }
        }
      }
      .guest_btn {
        display: flex;
        flex: 0 0 auto;
        justify-content: center;
        margin-top: 20px;
        .link {
          font-size: 14px;
          border-bottom: solid 1px #ddd;
        }
      }
    `
  }}
`
const Footer = styled.footer`
  ${({ theme }) => {
    return css`
      width: 100%;
      flex: 0.3;
      display: flex;
      align-items: center;
      flex-direction: column;
      margin-top: 4rem;
      ul {
        margin-bottom: ${theme.margins.base};
        li {
          color: ${theme.colors.black01};
          position: relative;
          float: left;
          padding: 0 ${theme.paddings.sm};
          font-size: 1.3rem;
          ::before {
            content: "";
            position: absolute;
            top: 50%;
            left: 0;
            background-color: #ddd;
            width: 1px;
            height: 10px;
            transform: translateY(-50%);
          }
          :first-child {
            padding-left: 0;
            ::before {
              display: none;
            }
          }
          a {
            color: #555;
            &:hover {
              text-decoration: underline;
            }
          }
        }
      }
      .copytext {
        font-size: ${theme.fonts.size.sm};
        color: #bbb;
      }
    `
  }}
`

function Login({ history, match, location }) {
  const [logInMutation, { loading }] = useMutation(LOGIN_MUTATION)
  const { register, handleSubmit, errors, formState } = useForm({
    mode: "onChange",
  })

  const onSubmit = async (data) => {
    try {
      if (loading) {
        return
      }

      const {
        data: { TicketAuthSignIn: token },
      } = await logInMutation({
        variables: {
          userId: data.userId,
          password: data.password,
        },
      })

      if (token) {
        logUserIn(token)
      }
    } catch (e) {
      console.log(e)
      var error = e.toString()
      error = error.replace("Error:", "")
      toast.error(error)
    }
  }

  return (
    <Layout isNav={true}>
      <PageTitle title="로그인" />
      <Wrap>
        <Wrapper>
          <LoginBox>
            <div className="logo">
              <img src="../assets/img/logo/app-logo.png" alt="Logo" />
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="form_group">
                <label>아이디</label>
                <input
                  type="text"
                  name="userId"
                  placeholder="아이디"
                  ref={register({
                    required: "아이디를 입력해주세요.",
                  })}
                  autocomplete="off"
                  autoFocus
                />
                <FormError message={errors?.userId?.message} />
              </div>
              <div className="form_group">
                <label>비밀번호</label>
                <input
                  type="password"
                  name="password"
                  placeholder="비밀번호"
                  ref={register({
                    required: "비밀번호를 입력해주세요.",
                  })}
                  autocomplete="off"
                />
                <FormError message={errors?.password?.message} />
              </div>
              <Button
                style={{ marginTop: 15 }}
                color="primary"
                type="submit"
                width="full"
                size="lg"
                disabled={!formState.isValid || loading}
              >
                {loading ? "처리중..." : "로그인"}
              </Button>
            </form>
            <div className="guest_btn">
              <Link to="/guest/login" className="link">
                비회원 티켓조회
              </Link>
            </div>
          </LoginBox>
          <Footer>
            <ul>
              <li>
                <Link to="/provision/provision">이용약관</Link>
              </li>
              <li>
                <Link to="/provision/privacy">개인정보처리방침</Link>
              </li>
              <li>
                <Link to="/provision/thirdParty">제3자 정보제공</Link>
              </li>
            </ul>
            <div className="copytext">
              Copyright © hbpay. All rights reserved.
            </div>
          </Footer>
        </Wrapper>
      </Wrap>
    </Layout>
  )
}
export default withRouter(Login)
