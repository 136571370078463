import { Route, Link } from "react-router-dom"
import styled, { css } from "styled-components"
import Menu from "./Menu"
import { Sticky } from "react-sticky-17"

import { useQuery } from "@apollo/client"
import { ACCOUNT_QUERY } from "../../Config/Queries"
import SLoading from "../Loading"

const SNav = styled.nav`
  ${({ theme }) => {
    return css`
      font-size: 14px;
      width: 100%;
      background-color: white;
      border-bottom: solid 1px #eee;
      overflow-x: scroll;
      overflow-y: hidden;
      white-space: nowrap;
      z-index: 999;
      ::-webkit-scrollbar {
        display: none;
      }
      ul {
        display: flex;
        justify-content: space-between;
        li {
          flex: 1 0 20%;
          text-align: center;
          flex-shrink: 0;
          a {
            position: relative;
            display: inline-flex;
            padding: 0 ${theme.paddings.lg};
            height: 40px;
            line-height: 40px;
            border-bottom: solid 2px #fff;
            color: ${theme.colors.black03};
            font-size: ${theme.fonts.size.base};
            font-family: ${theme.fonts.family.base};
            font-weight: ${theme.fonts.weight.medium};
            &:hover,
            &:focus,
            &:active {
              border-bottom: solid 2px ${theme.colors.black01};
            }
            &.active {
              color: ${theme.colors.black01};
              border-bottom: solid 2px ${theme.colors.black01};
              font-weight: ${theme.fonts.weight.bold};
            }
          }
        }
      }
    `
  }}
`
const RoundDot = styled.div`
  ${({ theme }) => {
    return css`
      position: absolute;
      right: -3px;
      top: 9px;
      width: 4px;
      height: 4px;
      background-color: ${theme.colors.danger};
      border-radius: 5px;
    `
  }}
`

function MypageButton({ match, item }) {
  return (
    <>
      <Link className={match ? "active" : ""} to={item.path[0]}>
        {item.title}
      </Link>
    </>
  )
}
function Nav() {
  return (
    <>
      <Sticky topOffset={100}>
        {({ style }) => (
          <SNav style={style}>
            <ul>
              {Menu.map((item, index) => {
                if (index !== 3) {
                  return (
                    <Route
                      key={index}
                      path={item.path}
                      children={({ match }) => {
                        return (
                          <li>
                            <Link
                              className={match ? "active" : ""}
                              to={item.path[0]}
                            >
                              {item.title}
                            </Link>
                          </li>
                        )
                      }}
                    />
                  )
                }
                if (index === 3) {
                  return (
                    <Route
                      key={index}
                      path={item.path}
                      children={({ match }) => {
                        return (
                          <li>
                            <MypageButton item={item} match={match} />
                          </li>
                        )
                      }}
                    />
                  )
                }
              })}
            </ul>
          </SNav>
        )}
      </Sticky>
    </>
  )
}
export default Nav
