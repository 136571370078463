import styled, { css } from "styled-components"
import { Col, Ready } from "../../styles/styles"
import { Layout } from "../../Components/Layouts/Layout"
import PageTitle from "../../Components/Layouts/PageTitle"

import { Link } from "react-router-dom"

import Banner from "../../Components/Banner/Home"
import Category from "../../Components/Category/Home/Category"
import Product from "../../Components/Product/Home"
import SLoading from "../../Components/Loading"
import { Swiper, SwiperSlide } from "swiper/react"
import { ArrowRight2 } from "iconsax-react"

const MainSection = styled.div`
  ${({ theme }) => {
    return css`
      padding: ${theme.paddings.base} 0;
    `
  }}
`
const Sections = styled.section`
  ${({ theme }) => {
    return css`
      padding: ${theme.paddings.lg} 0;
      .category {
        margin: ${theme.margins.lg} 0 0;
      }
      .main-ticket .swiper-slide {
        flex-shrink: 0;
        float: left;
        width: 152px !important;
      }
    `
  }}
`
const STitle = styled.div`
  ${({ theme }) => {
    return css`
      display: flex;
      flex-direction: row;
      height: 40px;
      justify-content: space-between;
      align-items: center;
      padding: ${theme.paddings.base} ${theme.paddings.lg} 0;
      .left {
      }
      .right {
        justify-content: flex-end;
      }
      h3 {
        font-size: 18px;
        font-family: ${theme.fonts.family.base};
        font-weight: ${theme.fonts.weight.bold};
        b {
          color: ${theme.colors.primary};
        }
      }
      .more {
        display: flex;
        align-items: center;
        position: relative;
        font-size: 13px;
        color: ${theme.colors.black02};
      }
    `
  }}
`
const SSwiper = styled(Swiper)`
  ${({ theme }) => {
    return css`
      width: auto;
      margin-top: 10px;
      padding: 0 ${theme.paddings.base};
    `
  }}
`
const SSwiperSlide = styled(SwiperSlide)`
  ${({ theme }) => {
    return css``
  }}
`
function Home({ loading, data }) {
  return (
    <Layout isNav={true} isFooter={true}>
      <PageTitle title={"홈"} />
      {!loading ? (
        <>
          <Banner ticketBanners={data?.TicketMain?.ticketBanners} />
          <MainSection>
            <Sections className="category">
              <Category ticketCategories={data?.TicketMain?.ticketCategories} />
            </Sections>
            <Sections>
              <STitle>
                <Col className="left">
                  <h3>이번 달, 모두가 주목한 행사</h3>
                </Col>
                <Link
                  to={`/products?orderBy=applyEndDateTime_DESC`}
                  className="more"
                >
                  더보기
                  <ArrowRight2 size="14" color="#777" />
                </Link>
              </STitle>

              {data?.TicketMain?.monthTicketProductDates.length > 0 ? (
                <SSwiper className="main-ticket" spaceBetween={15}>
                  {data?.TicketMain?.monthTicketProductDates.map(
                    (item, index) => (
                      <SwiperSlide key={index}>
                        <Product
                          item={item.ticketProduct}
                          ticketDate={item.ticketDate}
                        />
                      </SwiperSlide>
                    )
                  )}
                </SSwiper>
              ) : (
                <Ready>
                  <div className="list_none">등록된 티켓이 없습니다.</div>
                </Ready>
              )}
            </Sections>
            <Sections>
              <STitle>
                <Col className="left">
                  <h3>신규 행사</h3>
                </Col>
                <Link to={`/products?orderBy=id_DESC`} className="more">
                  더보기
                  <ArrowRight2 size="14" color="#777" />
                </Link>
              </STitle>

              {data?.TicketMain?.newTicketProducts.length > 0 ? (
                <SSwiper className="main-ticket" spaceBetween={10}>
                  {data?.TicketMain?.newTicketProducts.map((item, index) => (
                    <SwiperSlide key={index}>
                      <Product item={item} />
                    </SwiperSlide>
                  ))}
                </SSwiper>
              ) : (
                <Ready>
                  <div className="list_none">등록된 티켓이 없습니다.</div>
                </Ready>
              )}
            </Sections>
          </MainSection>
        </>
      ) : (
        <SLoading />
      )}
    </Layout>
  )
}
export default Home
