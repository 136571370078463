import { useState } from "react"
import { withRouter } from "react-router-dom"

import styled, { css } from "styled-components"
import { SModal, Stepbox, Stepbtn } from "../../../../../styles/styles"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronDown, faCheck } from "@fortawesome/pro-regular-svg-icons"
import PresentUser from "./PresentUser"
import PresentCounter from "./PresentCounter"
import { toast } from "react-toastify"
import { ModalLayout } from "../../../../Layouts/Layout"

function Modal({
  history,
  match,
  location,
  isOpen,
  toggleModal,
  id,
  isPay,
  name,
  isDiscount,
  discountPrice,
  price,
  ticketProductDatesCount,
  ticketProductDates,
}) {
  const [activeModal, setActiveModal] = useState("User")

  const [users, setUsers] = useState([])
  const [totalCount, setTotalCount] = useState(1)
  const [totalPrice, setTotalPrice] = useState(price)

  const userAdd = (user) => {
    const rows = users.find(
      (item) =>
        (item.isMember && item.id === user.id) ||
        (!item.isMember && item.phone === user.phone)
    )
      ? [
          ...users.filter(
            (item) =>
              (item.isMember && item.id !== user.id) ||
              (!item.isMember && item.phone !== user.phone)
          ),
        ]
      : [...users, user]

    setUsers(rows)
    totalSum({ rows })
  }
  const userRemove = (user) => {
    const rows = [
      ...users.filter(
        (item) =>
          (item.isMember && item.id !== user.id) ||
          (!item.isMember && item.phone !== user.phone)
      ),
    ]
    setUsers(rows)
    totalSum({ rows })
  }

  const minus = (index) => {
    const rows = [...users]
    const rowCount = rows[index][`count`]

    if (rowCount <= 1) return

    rows[index][`count`] = rowCount - 1

    setUsers(rows)
    totalSum({ rows })
  }
  const plus = (index) => {
    const rows = [...users]
    const rowCount = rows[index][`count`]

    rows[index][`count`] = rowCount + 1
    setUsers(rows)
    totalSum({ rows })
  }
  const totalSum = ({ rows }) => {
    const productCount = rows
      .map((item) => item.count)
      .reduce((prev, curr) => prev + curr, 0)

    const productPrice = price * productCount

    setTotalCount(productCount)
    setTotalPrice(productPrice)
  }

  const onSubmit = () => {
    history.push(`/order`, {
      orderType: "present",
      users,
      id,
      count: totalCount,
    })
  }

  return (
    <ModalLayout isOpen={isOpen} toggleModal={toggleModal} title={"선물하기"}>
      <Stepbtn>
        <button
          onClick={() => setActiveModal("User")}
          className={` ${activeModal === "User" ? "active" : ""}`}
        >
          <span>수신자(복수선택)</span>
        </button>
        <button className={` ${activeModal === "Counter" ? "active" : ""}`}>
          <span>수량선택</span>
        </button>
      </Stepbtn>
      <Stepbox>
        <PresentUser
          users={users}
          activeModal={activeModal}
          setActiveModal={setActiveModal}
          userAdd={userAdd}
          userRemove={userRemove}
        />
      </Stepbox>
      <Stepbox>
        <PresentCounter
          users={users}
          activeModal={activeModal}
          isPay={isPay}
          name={name}
          price={price}
          totalCount={totalCount}
          totalPrice={totalPrice}
          minus={minus}
          plus={plus}
          onSubmit={onSubmit}
        />
      </Stepbox>
    </ModalLayout>
  )
}

export default withRouter(Modal)
