import React, { useState, useEffect } from "react"

import styled, { css } from "styled-components"
import {
  Col,
  Dot,
  Row,
  SDatePicker,
  TicketList,
} from "../../../../../styles/styles"
import DatePicker, { registerLocale } from "react-datepicker"
import ko from "date-fns/locale/ko"
import "react-datepicker/dist/react-datepicker.css"
import Button from "../../../../Buttons"
import ReactMoment from "react-moment"
import moment from "moment"
import NumberFormat from "react-number-format"
import { toast } from "react-toastify"
import { dayOfWeekName } from "../../../../../Config/Util"
import { Link } from "react-router-dom"
registerLocale("ko", ko)

function ReservationCalendar({
  activeModal,
  setActiveModal,
  minute,
  limit,
  isPay,
  price,
  startDate,
  endDate,
  ticketProductDatesCount,
  ticketProductDates,
  excludeDates,
  ticketProductDate,
  setTicketProductDate,
  ticketProductDateTime,
  setTicketProductDateTime,
  setProductPrice,
  setTotalPrice,
}) {
  const nextStep = () => {
    if (!ticketProductDate || !ticketProductDateTime) {
      return toast.error(`일정의 시간을 선택해주세요.`)
    }
    setActiveModal("Counter")
  }

  const toDate = new Date()
  // toDate.setDate(toDate.getDate() + 2);
  const minDate = startDate < toDate ? toDate : startDate
  const maxDate = endDate

  const [selectDate, setSelectDate] = useState(new Date())
  const [selectDate9, setSelectDate9] = useState(new Date())
  const [selectTicketProductDate, setSelectTicketProductDate] = useState(null)

  useEffect(() => {
    if (selectDate) {
      const findTicketProductDate = ticketProductDates.find((item) => {
        return (
          new Date(item.ticketDate).toISOString().substring(0, 10) ===
          selectDate9.toISOString().substring(0, 10)
        )
      })

      setSelectTicketProductDate(findTicketProductDate)
    }
  }, [selectDate, setSelectDate])

  return (
    <>
      <SDatePicker
        style={
          activeModal === "Calendar"
            ? { display: "block" }
            : { display: "none" }
        }
      >
        <DatePicker
          renderCustomHeader={({ date, decreaseMonth, increaseMonth }) => (
            <div className="custom_header">
              <button className="prev_button" onClick={decreaseMonth}>
                <span></span>
              </button>
              <div className="custom_header_month">
                {date.getFullYear()}년 {date.getMonth() + 1}월
              </div>
              <button className="next_button" onClick={increaseMonth}>
                <span></span>
              </button>
            </div>
          )}
          selected={selectDate}
          onChange={(date) => {
            setSelectDate(date)
            setSelectDate9(new Date(Date.parse(date) + 1000 * 60 * 60 * 9))
          }}
          dateFormat="YYYY-MM-DD"
          locale="ko"
          minDate={minDate}
          maxDate={maxDate}
          excludeDates={excludeDates}
          inline
        />
      </SDatePicker>
      <TicketList
        style={
          activeModal === "Calendar"
            ? { display: "block" }
            : { display: "none" }
        }
      >
        <div className="title">
          시간선택(
          {selectTicketProductDate?.ticketProductDateTimes.length > 0
            ? selectTicketProductDate?.ticketProductDateTimes.length
            : 0}
          건)
        </div>
        {selectTicketProductDate ? (
          <ul>
            {selectTicketProductDate?.ticketProductDateTimes.map((item) => {
              const startDate = new Date(item.ticketDateTime)
              const endDate = moment(item.ticketDateTime).add(
                parseInt(minute),
                "minutes"
              )
              const isSoldOut =
                item.isSoldOut ||
                limit <= item.ticketOrderProductPeoplesCount ||
                startDate <= new Date()

              // console.log({
              //   ticketDateTime: item.ticketDateTime,
              //   startDate,
              //   endDate,
              // });

              return (
                <li key={item.id}>
                  <a
                    onClick={() => {
                      if (!isSoldOut) {
                        setTicketProductDate(selectTicketProductDate)
                        setTicketProductDateTime(item)

                        setProductPrice(price)
                        setTotalPrice(price)
                      }
                    }}
                    className={`ticketItem ${
                      ticketProductDateTime?.id === item.id ? "active" : ""
                    } ${isSoldOut ? "soldout" : ""}`}
                  >
                    <Col className="left">
                      <strong>
                        {isSoldOut && <span>[마감]</span>}{" "}
                        <ReactMoment format="YYYY.MM.DD">
                          {startDate}
                        </ReactMoment>
                        ({dayOfWeekName(startDate.getDay())}){" "}
                        <ReactMoment format="HH:mm">{startDate}</ReactMoment> ~{" "}
                        <ReactMoment format="HH:mm">{endDate}</ReactMoment>
                      </strong>
                      <Row className="cont">
                        {isPay ? (
                          <span>
                            <b>
                              <NumberFormat
                                value={price + item.price}
                                displayType={"text"}
                                thousandSeparator={true}
                              />
                            </b>
                            드림
                          </span>
                        ) : (
                          <span className="free">무료</span>
                        )}
                        <Dot />
                        <NumberFormat
                          value={item.ticketOrderProductPeoplesCount}
                          displayType={"text"}
                          thousandSeparator={true}
                          suffix="명"
                        />
                        /
                        <NumberFormat
                          value={limit}
                          displayType={"text"}
                          thousandSeparator={true}
                          suffix="명"
                        />
                      </Row>
                    </Col>
                    <input type="checkbox" />
                  </a>
                </li>
              )
            })}
          </ul>
        ) : (
          <div className="list_none">
            <div>행사일정이 없습니다.</div>
          </div>
        )}
        <Col className="bottom">
          <Button
            type="button"
            onClick={nextStep}
            color="primary"
            width="full"
            size="lg"
          >
            다음
          </Button>
        </Col>
      </TicketList>
    </>
  )
}
export default ReservationCalendar
