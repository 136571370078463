import React from "react"
import { Link } from "react-router-dom"

import { Layout } from "../../../Components/Layouts/Layout"
import PageTitle from "../../../Components/Layouts/PageTitle"
import styled, { css } from "styled-components"
import { Row, Col, Ready, SortOption } from "../../../styles/styles"
import Dropdown from "react-dropdown"
import "react-dropdown/style.css"
import SLoading from "../../../Components/Loading"
import SearchOption from "../../../Components//Product/Product/List/SearchOption"
import Item from "../../../Components/Product/Product/List/Item"
import Pagination from "../../../Components/Pagination"

const Sort = styled(Row)`
  ${({ theme }) => {
    return css`
      padding: ${theme.paddings.base} ${theme.paddings.xl};
      font-size: ${theme.fonts.size.base};
      .left {
        align-items: center;
        span {
          font-weight: ${theme.fonts.weight.medium};
          color: ${theme.colors.black01};
          b {
            color: ${theme.colors.primary};
          }
        }
      }
      .right {
        flex: 0 0 auto;
        justify-content: flex-end;
      }
    `
  }}
`
const SItemlist = styled.div`
  ${({ theme }) => {
    return css`
      display: flex;
      flex-wrap: wrap;
      padding: 0 ${theme.paddings.lg};
      ul {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        li {
          display: flex;
          flex: 0 0 50%;
          padding: 0 ${theme.paddings.sm};
          margin-bottom: ${theme.margins.lg};
          a {
            display: block;
            flex: 1;
          }
        }
      }
    `
  }}
`

function List({
  loading,
  data,
  page,
  orderByOption,
  orderByDefault,
  orderByChange,
  blockSize,
  first,
  baseUrl,
}) {
  return (
    <>
      <Layout isNav={true} isFooter={true}>
        <PageTitle title="상품" />
        <SearchOption />
        <Sort>
          <Col className="left">
            <span>
              상품 <b>{!loading && data.TicketProductList.count}</b>건
            </span>
          </Col>
          <Col className="right">
            <SortOption>
              <Dropdown
                options={orderByOption}
                value={orderByDefault}
                onChange={(item) => {
                  orderByChange(item)
                }}
              />
            </SortOption>
          </Col>
        </Sort>
        <>
          {!loading ? (
            <>
              {data.TicketProductList.count > 0 ? (
                <>
                  <SItemlist>
                    <ul>
                      {data.TicketProductList.ticketProducts.map(
                        (item, index) => {
                          return (
                            <li key={index}>
                              <Link to={`/products/${item.id}`}>
                                <Item item={item} />
                              </Link>
                            </li>
                          )
                        }
                      )}
                    </ul>
                  </SItemlist>
                  <Pagination
                    totalRecord={data.TicketProductList.count}
                    blockSize={blockSize}
                    pageSize={first}
                    currentPage={page}
                    baseUrl={baseUrl}
                  />
                </>
              ) : (
                <Ready>
                  <div className="list_none">등록된 티켓이 없습니다.</div>
                </Ready>
              )}
            </>
          ) : (
            <>
              <SLoading />
            </>
          )}
        </>
      </Layout>
    </>
  )
}

export default List
