import React, { useState, useEffect } from "react"
import { Wrap } from "../../styles/styles"
import Header from "./Header"
import Nav from "./Nav"
import Footer from "./Footer"
import styled, { css } from "styled-components"
import { StickyContainer } from "react-sticky-17"
import { faClose } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const StickyContainers = styled(StickyContainer)`
  flex: 1 0 auto;
`
const Main = styled.main`
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
`
const FooterButton = styled.div`
  flex: 1 0 auto;
  height: 100px;
`

export const Layout = ({ isNav, isFooter, isFooterButton, children }) => {
  const isApp = localStorage.getItem("isApp")
  // const token = localStorage.getItem("token");
  return (
    <StickyContainers>
      <Wrap>
        {!isApp && <Header title={"드림티켓"} />}
        {isNav && <Nav />}
        {/* isApp:{isApp}, token:{token} */}
        <Main>{children}</Main>
        {isFooter && <Footer />}
        {isFooterButton && <FooterButton />}
      </Wrap>
    </StickyContainers>
  )
}
const ModalContainer = styled.div`
  ${({ theme }) => {
    return css`
      .modal {
        display: none;
        position: fixed;
        bottom: 0;
        left: 0;
        right: -0;
        z-index: 999;
        ::before {
          content: "";
          position: fixed;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          background-color: rgba(0, 0, 0, 0.6);
          z-index: 11;
        }
        section {
          position: relative;
          box-shadow: 0 -3px 3px rgba(0, 0, 0, 0.1);
          width: 100%;
          max-width: 750px;
          margin: 0 auto;
          border-top-right-radius: 30px;
          border-top-left-radius: 30px;
          background-color: #fff;
          /* 팝업이 열릴때 스르륵 열리는 효과 */
          animation: modal-show 0.3s;
          z-index: 15;
          main {
            ${theme.device.mobile} {
              overflow-y: scroll;
              ::-webkit-scrollbar {
                display: none;
              }
            }
          }
        }
        header {
          position: relative;
          margin-top: 12px;
          height: 46px;
          font-weight: 700;
          justify-content: space-between;
          align-items: center;
          display: flex;
          flex-direction: row;
          .right {
            width: 46px;
            height: 46px;
          }
          .title {
            font-size: ${theme.fonts.size.base};
            font-weight: ${theme.fonts.weight.bold};
            align-items: center;
          }
          button {
            width: 46px;
            height: 46px;
            font-size: 21px;
            font-weight: 700;
            text-align: center;
            border: 0;
            color: #999;
            background-color: transparent;
          }
        }
        footer {
          height: 60px;
          flex: 1;
          display: flex;
          border-top: solid 1px #eee;
          a,
          button {
            margin-left: 0 !important;
            height: 60px;
            line-height: 60px;
          }
        }
      }
      .modal.openModal {
        display: flex;
        align-items: center;
        animation: modal-bg-show 0.3s;
      }
      @keyframes modal-show {
        from {
          transform: translateY(50%);
        }
        to {
          transform: translateY(0);
        }
      }
    `
  }}
`
export const ModalLayout = ({ isOpen, toggleModal, title, children }) => {
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  })
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden"
    } else {
      document.body.style.overflow = "auto"
    }
    window.onresize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      })
    }
  }, [isOpen])
  return (
    <ModalContainer>
      <div className={isOpen ? "openModal modal" : "modal"}>
        {isOpen ? (
          <section>
            <header>
              <div className="right" />
              <h3 className="title">{title}</h3>
              <button onClick={toggleModal}>
                <FontAwesomeIcon icon={faClose} color={"#222"} size={24} />
              </button>
            </header>
            <main style={{ maxHeight: windowSize.height / 1.3 }}>
              {children}
            </main>
          </section>
        ) : null}
      </div>
    </ModalContainer>
  )
}
