import React from "react"
import { withRouter } from "react-router-dom"
import { useQuery } from "@apollo/client"
import { NOTICES_QUERY } from "../../Config/Queries"

import { Layout } from "../../Components/Layouts/Layout"
import PageTitle from "../../Components/Layouts/PageTitle"
import styled, { css } from "styled-components"
import { Ready, Container } from "../../styles/styles"
import Loadings from "../../Components/Loading"
import Item from "../../Components/Notice/Item"
import Pagination from "../../Components/Pagination"
import qs from "qs"

const SContainer = styled(Container)`
  ${({ theme }) => {
    return css`
      padding: 0 ${theme.paddings.lg};
    `
  }}
`
const BoardList = styled.div`
  ul {
    li {
    }
  }
`

function List({ history, match, location }) {
  const queryString = qs.parse(location.search.substr(1))
  const page = queryString.page ? queryString.page : 1
  const blockSize = 5
  const first = 10
  const skip = first * (page - 1)

  const baseUrl = "?"

  const { data, loading } = useQuery(NOTICES_QUERY, {
    variables: {
      orderBy: "id_DESC",
      skip,
      first,
    },
  })

  return (
    <Layout isNav={true} isFooter={true}>
      <PageTitle title="공지사항" />
      <SContainer>
        {!loading ? (
          <>
            {data.TicketNoticeList.count > 0 ? (
              <BoardList>
                <ul>
                  {data.TicketNoticeList.notices.map((item, index) => {
                    return (
                      <li key={index}>
                        <Item item={item} />
                      </li>
                    )
                  })}
                </ul>
                <Pagination
                  totalRecord={data.TicketNoticeList.count}
                  blockSize={blockSize}
                  pageSize={first}
                  currentPage={page}
                  baseUrl={baseUrl}
                />
              </BoardList>
            ) : (
              <Ready>
                <div className="list_none">등록된 공지사항이 없습니다.</div>
              </Ready>
            )}
          </>
        ) : (
          <>
            <Loadings desc="로딩..." />
          </>
        )}
      </SContainer>
    </Layout>
  )
}

export default withRouter(List)
