import React, { useEffect, useState } from "react"
import { withRouter } from "react-router-dom"

import { Layout } from "../../../Components/Layouts/Layout"
import PageTitle from "../../../Components/Layouts/PageTitle"
import styled, { css } from "styled-components"
import {
  Col,
  FooterButton,
  ProductTab,
  SAccordion,
  TabBars,
} from "../../../styles/styles"
import Alter from "../../../Components/Alter"
import Labels from "../../../Components/Labels"
import IsWishlist from "../../../Components/Wishlist/Product/Common/Detail/IsWishlist"
import Intro from "../../../Components/Product/Common/Intro"
import Review from "../../../Components/Review/Product/Common/Detail/Review"
import Inquiry from "../../../Components/Inquiry/Product/Common/Detail/Inquiry"
import PushModal from "../../../Components/Product/Product/Detail/Push/PushModal"
import ReservationModal from "../../../Components/Product/Product/Detail/Reservation/ReservationModal"
import PresentModal from "../../../Components/Product/Product/Detail/Present/PresentModal"
import Button from "../../../Components/Buttons"
import Tabs, { TabPane } from "rc-tabs"
import { StickyContainer, Sticky } from "react-sticky-17"
import ReactMoment from "react-moment"
import NumberFormat from "react-number-format"
import { graphqlUrl, orderinfo } from "../../../Config/Env"
import { toast } from "react-toastify"
import { dayOfWeekName, isPayName, placeTypeName } from "../../../Config/Util"
import KakaoMap from "../../../Components/KakaoMap"

import { useReactiveVar } from "@apollo/client"
import { isLoggedInVar } from "../../../apollo"
import { EmojiHappy } from "iconsax-react"
import RowInfo from "../../../Components/RowInfo"

const Manager = styled.div`
  ${({ theme }) => {
    return css`
      border-top: solid 5px #eee;
      padding: ${theme.paddings.xl};
      .tit {
        display: block;
        margin-bottom: 5px;
        font-size: 15px;
        color: #444;
        font-weight: ${theme.fonts.weight.medium};
      }
      ul {
        li {
          display: flex;
          flex: 1;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          border-bottom: solid 1px #ececec;
          padding: ${theme.paddings.sm} 0;
          :last-child {
            border: 0;
          }
          .left {
            width: 80px;
            font-size: ${theme.fonts.size.sm};
            color: ${theme.colors.black03};
            font-weight: ${theme.fonts.weight.medium};
          }
          .right {
            flex: 1;
            text-align: right;
            font-size: ${theme.fonts.size.base};
            color: ${theme.colors.black02};
            font-weight: ${theme.fonts.weight.base};
          }
        }
      }
    `
  }}
`
const DetailButton = styled.div`
  ${({ theme }) => {
    return css`
      display: flex;
      flex-direction: row;
      padding: 0 ${theme.paddings.xl} ${theme.paddings.base};
      .like {
        margin-right: 10px;
        background-color: white;
        border: solid 1px ${theme.colors.danger};
        width: 100%;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0;
        font-size: 14px;
        color: ${theme.colors.danger};
        border-radius: 4px;
        span {
          margin-left: 5px;
        }
        &.active {
          background-color: ${theme.colors.danger};
          color: white;
        }
      }
      .tease {
        background-color: white;
        border: solid 1px ${theme.colors.primary};
        width: 100%;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0;
        font-size: 14px;
        color: ${theme.colors.primary};
        border-radius: 4px;
        font-weight: normal;
        span {
          margin-left: 5px;
        }
        &:hover,
        &.active {
          background-color: ${theme.colors.primary};
          color: white;
        }
      }
    `
  }}
`
const TicketDetail = styled.div`
  ${({ theme }) => {
    return css`
      border-bottom: solid 5px #eee;
      .box_top {
        display: flex;
        padding: 15px;
        flex: 1;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        .img {
          margin: 20px 0 0;
          position: relative;
          max-width: 200px;
          overflow: hidden;
          border-radius: 5px;
          overflow: hidden;
          :before {
            content: "";
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            border: 1px solid rgba(0, 0, 0, 0.05);
            border-radius: 5px;
          }
          img {
            max-width: 100%;
            display: block;
            width: 100%;
            height: auto;
          }
        }
        .cont {
          margin: 10px 0 0;
          .date {
            color: ${theme.colors.black04};
            font-size: ${theme.fonts.size.sm};
            font-weight: 400;
          }
          h3 {
            margin-top: ${theme.margins.base};
            overflow: hidden;
            font-size: 18px;
            color: ${theme.colors.black01};
            font-weight: ${theme.fonts.weight.bold};
            line-height: 1.4;
          }
        }
      }
      .box_content {
        padding: 0 ${theme.paddings.xl} 10px;
        .map_box {
          margin-top: ${theme.margins.sm};
          border-radius: 5px;
          overflow: hidden;
          border: solid 1px #eee;
          .map {
            min-height: 160px;
          }
          .txt {
            background: ${theme.colors.lightgray};
            padding: 8px 10px;
            text-align: center;
            font-size: 14px;
            color: #444;
          }
        }
      }
    `
  }}
`

const renderTabBar = (props, DefaultTabBar) => (
  <Sticky bottomOffset={80}>
    {({ style }) => (
      <ProductTab>
        <DefaultTabBar {...props} style={{ ...style }} />
      </ProductTab>
    )}
  </Sticky>
)

function Presenter({ history, match, location, data }) {
  const isLoggedIn = useReactiveVar(isLoggedInVar)

  const [pushModalIsOpen, setPushModalIsOpen] = useState(false)
  const pushToggleModal = () => {
    if (!isLoggedIn) {
      toast.error("로그인 후 이용해주세요.")
      history.push(`/login`)
      return
    }
    if (isSoldOut) {
      toast.error("상품이 품절되어 쪼르기를 할 수 없습니다.")
      return
    }
    setPushModalIsOpen(!pushModalIsOpen)
  }

  const [reservationModalIsOpen, setReservationModalIsOpen] = useState(false)
  const reservationToggleModal = () => {
    if (!isLoggedIn) {
      toast.error("로그인 후 이용해주세요.")
      history.push(`/login`)
      return
    }
    if (isSoldOut) {
      toast.error("상품이 품절되어 구입할 수 없습니다.")
      return
    }
    setReservationModalIsOpen(!reservationModalIsOpen)
  }

  const [presentModalIsOpen, setPresentModalIsOpen] = useState(false)
  const presentModalToggleModal = () => {
    if (!isLoggedIn) {
      toast.error("로그인 후 이용해주세요.")
      history.push(`/login`)
      return
    }
    if (isSoldOut) {
      toast.error("상품이 품절되어 선물할 수 없습니다.")
      return
    }
    setPresentModalIsOpen(!presentModalIsOpen)
  }

  const {
    TicketProductDetail: {
      id,
      commonStatus,

      coverImage,

      store,
      isRefund,

      managerName,
      managerTel,
      managerEmail,

      minute,
      limit,
      isPay,

      price,
      isDiscount,
      discountPrice,
      totalPrice,

      applyStartDateTime,
      applyEndDateTime,
      placeType,

      placeZipcode,
      placeAddress,
      placeAddressDetail,
      placeLongitude,
      placeLatitude,

      ticketCategory,

      name,
      contents,

      isSoldOut,

      createdAt,
      updatedAt,

      ticketProductDatesCount,
      ticketProductDates,
      ticketProductReviewsCount,
      ticketProductReviews,
      ticketProductInquiriesCount,
      ticketProductInquiries,
    },
  } = data

  const CoverImage =
    coverImage === null
      ? "../assets/img/product/product-default.jpg"
      : `${graphqlUrl}${coverImage}`
  const Percent = parseInt((discountPrice / price) * 100)
  const startDate = new Date(applyStartDateTime)
  const endDate = new Date(applyEndDateTime)

  const [isDetail, setIsDetail] = useState(false)
  const toggleDetail = () => {
    setIsDetail(!isDetail)
  }
  const [items, setItems] = useState([])
  useEffect(() => {
    if (isRefund) {
      setItems([
        {
          uuid: "0",
          heading: orderinfo.Refund.title,
          content: <div>{orderinfo.Refund.content}</div>,
        },
      ])
    }
  }, [data])
  return (
    <>
      <Layout isNav={false} isFooter={false} isFooterButton={true}>
        <PageTitle title={name} />
        <TicketDetail>
          <div className="box_top">
            <div className="img">
              <img src={CoverImage} alt={name} />
            </div>
            <div className="cont">
              <Labels color="primary" border="solid">
                {ticketCategory.name}
              </Labels>
              <h3>{name}</h3>
            </div>
          </div>
          <div className="box_content">
            <RowInfo
              textLeft={"유효기간"}
              textRight={
                <>
                  <ReactMoment format="YYYY.MM.DD">
                    {applyStartDateTime}
                  </ReactMoment>
                  ({dayOfWeekName(startDate.getDay())}) ~{" "}
                  <ReactMoment format="MM.DD">{applyEndDateTime}</ReactMoment>(
                  {dayOfWeekName(endDate.getDay())}) {placeTypeName(placeType)}
                </>
              }
            />
            <RowInfo
              textLeft={"가격"}
              textRight={
                <>
                  {isPay ? (
                    <>
                      <b>
                        <NumberFormat
                          value={totalPrice}
                          displayType={"text"}
                          thousandSeparator={true}
                        />
                      </b>
                      드림
                    </>
                  ) : (
                    isPayName(isPay)
                  )}
                </>
              }
            />
            <RowInfo textLeft={"시간"} textRight={<>{minute}분</>} />
            <RowInfo
              textLeft={"장소"}
              textRight={
                <>
                  {placeType === "OffLine"
                    ? `${placeAddress} ${placeAddressDetail}`
                    : placeTypeName(placeType)}
                </>
              }
            />
            {placeType === "OffLine" && (
              <div className="map_box">
                <div className="map">
                  <KakaoMap
                    longitude={placeLongitude}
                    latitude={placeLatitude}
                  />
                </div>
              </div>
            )}
          </div>
          <DetailButton>
            <Col className="left">
              <IsWishlist isLoggedIn={isLoggedIn} />
            </Col>
            <Col className="right">
              <Button className="tease" onClick={pushToggleModal}>
                <EmojiHappy size="20" />
                <span>쪼르기</span>
              </Button>
            </Col>
          </DetailButton>
        </TicketDetail>
        <StickyContainer>
          <Tabs defaultActiveKey="1" renderTabBar={renderTabBar}>
            <TabPane tab={<span>상세정보</span>} key="1">
              <Intro contents={contents} />
              <Manager>
                <strong className="tit">담당자</strong>
                <Alter border="radius">
                  <ul>
                    <li key="managerName">
                      <div className="left">이름</div>
                      <div className="right">{managerName}</div>
                    </li>
                    <li key="managerEmail">
                      <div className="left">이메일</div>
                      <div className="right">{managerEmail}</div>
                    </li>
                    <li key="managerTel">
                      <div className="left">전화번호</div>
                      <div className="right">{managerTel}</div>
                    </li>
                  </ul>
                </Alter>
              </Manager>
              <SAccordion>
                <div className="saccordion_continer">
                  <button onClick={toggleDetail}>
                    {orderinfo.Refund.title}
                    <span className={`${!isDetail ? `` : `active`}`}></span>
                  </button>
                  <div className={`saccordion_text ${!isDetail ? `` : `hide`}`}>
                    {orderinfo.Refund.content}
                  </div>
                </div>
              </SAccordion>
            </TabPane>
            <TabPane
              tab={<span>후기 {ticketProductReviewsCount}</span>}
              key="2"
            >
              <Review
                ticketProductReviews={ticketProductReviews}
                uri="/products"
              />
            </TabPane>
            <TabPane
              tab={<span>문의 {ticketProductInquiriesCount}</span>}
              key="3"
            >
              <Inquiry
                ticketProductInquiries={ticketProductInquiries}
                uri="/products"
              />
            </TabPane>
          </Tabs>
        </StickyContainer>

        <PushModal
          isOpen={pushModalIsOpen}
          toggleModal={pushToggleModal}
          id={id}
        />
        {commonStatus === "C" && (
          <>
            {!isSoldOut ? (
              <>
                <FooterButton>
                  <div className="inner">
                    <Button
                      onClick={reservationToggleModal}
                      color="lightwarning"
                      width="full"
                    >
                      예매하기
                    </Button>
                    <Button
                      onClick={presentModalToggleModal}
                      color="lightprimary"
                      width="full"
                    >
                      선물하기
                    </Button>
                  </div>
                </FooterButton>
                {isLoggedIn && (
                  <>
                    <ReservationModal
                      isOpen={reservationModalIsOpen}
                      toggleModal={reservationToggleModal}
                      id={id}
                      minute={minute}
                      limit={limit}
                      isPay={isPay}
                      price={totalPrice}
                      isDiscount={isDiscount}
                      discountPrice={discountPrice}
                      name={name}
                      startDate={startDate}
                      endDate={endDate}
                      ticketProductDatesCount={ticketProductDatesCount}
                      ticketProductDates={ticketProductDates}
                    />
                    <PresentModal
                      isOpen={presentModalIsOpen}
                      toggleModal={presentModalToggleModal}
                      id={id}
                      isPay={isPay}
                      name={name}
                      isDiscount={isDiscount}
                      discountPrice={discountPrice}
                      price={totalPrice}
                      ticketProductDatesCount={ticketProductDatesCount}
                      ticketProductDates={ticketProductDates}
                    />
                  </>
                )}
              </>
            ) : (
              <FooterButton>
                <div className="inner">
                  <Button color="lightdanger" width="full" size="lg" disabled>
                    품절
                  </Button>
                </div>
              </FooterButton>
            )}
          </>
        )}
        {commonStatus === "D" && (
          <FooterButton>
            <div className="inner">
              <Button color="lightdanger" width="full" size="lg" disabled>
                판매종료
              </Button>
            </div>
          </FooterButton>
        )}
      </Layout>
    </>
  )
}

export default withRouter(Presenter)
