import React, { useEffect, useState } from "react"
import { withRouter } from "react-router-dom"
import { useMutation } from "@apollo/client"
import { ORDERPROCESSING_MUTATION, PRODUCT_QUERY } from "../../Config/Queries"

import { Layout } from "../../Components/Layouts/Layout"
import PageTitle from "../../Components/Layouts/PageTitle"
import styled, { css } from "styled-components"
import {
  FooterButton,
  Container,
  MainText,
  MainTitle,
  SubText,
} from "../../styles/styles"
import Button from "../../Components/Buttons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faBackspace, faSync } from "@fortawesome/pro-light-svg-icons"
import { toast } from "react-toastify"
import { Refresh2, TagCross } from "iconsax-react"

const SContainer = styled(Container)`
  ${({ theme }) => {
    return css`
      background-color: ${theme.colors.lightgray};
    `
  }}
`
const SAuthPassword = styled.div`
  ${({ theme }) => {
    return css`
      display: flex;
      flex-direction: column;
      height: 100%;
      form {
        display: flex;
        flex-direction: column;
        height: 100%;
      }
      .password_top {
        display: flex;
        flex: 1 0 35%;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .password_input {
          display: flex;
          flex-direction: row;
          justify-content: center;
          margin-bottom: 12px;
          input {
            background-color: white;
            box-shadow: 0 2px 3px rgba(0, 0, 0, 0.08);
            width: 40px;
            height: 40px;
            border-radius: 4px;
            margin: 0 3px;
            text-align: center;
            color: #222;
            font-size: ${theme.fonts.size.lg};
            font-weight: ${theme.fonts.weight.bold};
          }
        }
      }
      .password_content {
        display: flex;
        flex: 1 0 50%;
        width: 100%;
        flex-direction: column;
        ul {
          background-color: white;
          border-top-left-radius: 30px;
          border-top-right-radius: 30px;
          box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.08);
          display: flex;
          flex-wrap: wrap;
          overflow: hidden;
          flex: 1;
          li {
            display: flex;
            flex: 1 0 33.3%;
            &.reset > button {
              color: ${theme.colors.primary};
            }
            &.remove > button {
              color: ${theme.colors.black02};
              font-size: ${theme.fonts.size.xl};
            }
            &.next > button {
              color: ${theme.colors.black02};
              font-size: ${theme.fonts.size.xl};
              font-weight: ${theme.fonts.weight.medium};
              :disabled {
                color: #bbb;
              }
            }
            button {
              background-color: transparent;
              margin: 0;
              width: 100%;
              display: block;
              font-size: 32px;
              font-weight: ${theme.fonts.weight.bold};
              color: ${theme.colors.black02};
              :hover {
                color: ${theme.colors.black01};
                background-color: rgba(0, 0, 0, 0.05);
              }
            }
          }
        }
      }
      .password_bottom {
        background-color: white;
        button {
          display: flex;
          flex: 1 0 33%;
          height: 50px;
          justify-content: center;
          align-items: center;
          background-color: transparent;
          font-size: ${theme.fonts.size.base};
          &:nth-child(1) {
            border-right: solid 1px #eee;
          }
          &.delete {
            color: ${theme.colors.primary};
          }
        }
      }
    `
  }}
`
const Code = styled.div`
  ${({ theme }) => {
    return css`
      width: 14px;
      height: 14px;
      border-radius: 12px;
      margin: 0 12px;
      justify-content: center;
      align-items: center;
      &.code1 {
        border: solid 1px ${theme.colors.primary};
      }
      &.code2 {
        background-color: ${theme.colors.primary};
      }
    `
  }}
`
const SMainTitle = styled(MainTitle)`
  margin: 36px 0 0;
  padding: 0 24px;
`

function AuthPassword({ history, match, location }) {
  const orderType = location?.state?.orderType
  const users = location?.state?.users
  const id = location?.state?.id
  const ticketProductDate = location?.state?.ticketProductDate
  const ticketProductDateTime = location?.state?.ticketProductDateTime
  const ticketDateTimePeoples = location?.state?.ticketDateTimePeoples
  const count = location?.state?.count
  const message = location?.state?.message

  const [authPassword, setAuthPassword] = useState("")
  const [passcode, setPassCode] = useState(["", "", "", "", "", ""])
  const [password1, setPassword1] = useState("")
  const [password2, setPassword2] = useState("")
  const [password3, setPassword3] = useState("")
  const [password4, setPassword4] = useState("")
  const [password5, setPassword5] = useState("")
  const [password6, setPassword6] = useState("")

  const [orderProcessingMutation, { loading: processing }] = useMutation(
    ORDERPROCESSING_MUTATION,
    {
      refetchQueries: () => [
        {
          query: PRODUCT_QUERY,
          variables: {
            id,
          },
        },
      ],
    }
  )

  const init = () => {
    config("")
    setAuthPassword("")
  }
  const insert = (text) => {
    if (authPassword.length < 6) {
      const newAuthPassword = authPassword + text
      config(newAuthPassword)
      setAuthPassword(authPassword + text)
    }
  }
  const remove = () => {
    if (authPassword.length > 0) {
      const newAuthPassword = authPassword.substr(0, authPassword.length - 1)
      config(newAuthPassword)
      setAuthPassword(authPassword.substr(0, authPassword.length - 1))
    }
  }
  const config = (newAuthPassword) => {
    setPassword1(newAuthPassword.substr(0, 1))
    setPassword2(newAuthPassword.substr(1, 1))
    setPassword3(newAuthPassword.substr(2, 1))
    setPassword4(newAuthPassword.substr(3, 1))
    setPassword5(newAuthPassword.substr(4, 1))
    setPassword6(newAuthPassword.substr(5, 1))
  }
  const onPressReset = () => {
    setPassCode(["", "", "", "", "", ""])
    setAuthPassword("")
  }
  const onPressNumber = (num) => {
    let tempCode = passcode
    for (var i = 0; i < tempCode.length; i++) {
      if (tempCode[i] == "") {
        tempCode[i] = `${num}`
        setAuthPassword(`${authPassword}${num}`)
        break
      } else {
        continue
      }
    }
    setPassCode(tempCode)
  }
  const onPressCancel = () => {
    let tempCode = passcode
    for (var i = tempCode.length - 1; i >= 0; i--) {
      if (tempCode[i] != "") {
        tempCode[i] = ""
        setAuthPassword(authPassword.slice(0, i))
        break
      } else {
        continue
      }
    }
    setPassCode(tempCode)
  }
  useEffect(async () => {
    if (authPassword.length < 6) {
      return
    }
    try {
      if (processing) {
        return
      }

      const {
        data: { TicketOrderProcessing },
      } = await orderProcessingMutation({
        variables: {
          authPassword: authPassword,
          orderType: orderType,
          users: users,
          id: id,
          ticketProductDate: ticketProductDate?.id,
          ticketProductDateTime: ticketProductDateTime?.id,
          ticketDateTimePeoples: ticketDateTimePeoples,
          count: count,
          message: message,
        },
      })

      if (TicketOrderProcessing) {
        history.replace(`/order/result`, {
          orderType,
          users,
          id,
          ticketProductDate,
          ticketProductDateTime,
          ticketDateTimePeoples: ticketDateTimePeoples,
          count,
          message: message,
        })
      }

      return
    } catch (e) {
      console.log(e)
      var error = e.toString()
      error = error.replace("Error: GraphQL error:", "")
      setPassCode(["", "", "", "", "", ""])
      setAuthPassword("")
      toast.error(error)
    }
  }, [authPassword])

  const numbers = [
    { id: 1 },
    { id: 2 },
    { id: 3 },
    { id: 4 },
    { id: 5 },
    { id: 6 },
    { id: 7 },
    { id: 8 },
    { id: 9 },
    { id: 10 },
    { id: 0 },
    { id: 11 },
  ]
  return (
    <Layout isNav={false}>
      <PageTitle title="선물하기" />
      <SContainer>
        <SAuthPassword>
          <form>
            <SMainTitle>
              <MainText>
                <span className="TextBlue">결제 비밀번호 6자리</span>를
              </MainText>
              <MainText>입력해주세요.</MainText>
            </SMainTitle>
            <div className="password_top">
              <div className="password_input">
                {passcode.map((p, i) => {
                  let style = p != "" ? "code2" : "code1"
                  return <Code className={style} key={i} />
                })}
              </div>
              <SubText>
                결제 비밀번호는
                <br /> 결제 인증을 위해 이용됩니다.
              </SubText>
            </div>
            <div className="password_content">
              <ul>
                {numbers.map((num, index) => {
                  if (num.id == 11) {
                    return (
                      <li className="delete">
                        <button type="button" onClick={() => onPressCancel()}>
                          <TagCross variant="Bold" color={"#333"} size={32} />
                        </button>
                      </li>
                    )
                  }
                  if (num.id == 10) {
                    return (
                      <li className="next">
                        <button type="button" onClick={() => onPressReset()}>
                          <Refresh2 variant="Bold" color={"#333"} size={32} />
                        </button>
                      </li>
                    )
                  }
                  if (num.id != 10 && num.id != 11) {
                    return (
                      <li>
                        <button
                          type="button"
                          onClick={() => onPressNumber(num.id)}
                        >
                          {num.id}
                        </button>
                      </li>
                    )
                  }
                })}
              </ul>
            </div>
          </form>
        </SAuthPassword>
      </SContainer>
    </Layout>
  )
}

export default withRouter(AuthPassword)
