import React from "react";
import { withRouter } from "react-router-dom";
import { useQuery, useMutation } from "@apollo/client";
import {
  TOGGLEWISHLIST_MUTATION,
  ISWISHLIST_QUERY,
} from "../../../../Config/Queries";

import styled, { css } from "styled-components";
import SLoading from "../../../Loading";

const LikeButton = styled.div`
  ${({ theme }) => {
    return css`
      .like {
        background-color: white;
        width: 40px;
        height: 40px;
        justify-content: center;
        align-items: center;
        outline: none;
        padding: 0;
        ::before {
          content: "\f004";
          font-family: ${theme.fonts.family.fontAwesomeL};
          display: inline-block;
          font-size: ${theme.fonts.size.lg};
          color: ${theme.colors.gray};
          transition: color 0.15s ease-in-out;
        }
      }
      .like.active {
        ::before {
          font-family: ${theme.fonts.family.fontAwesomeS};
          color: ${theme.colors.danger};
        }
      }
    `;
  }}
`;

function Container({ history, match, location, id }) {
  const [toggleWishlistMutation] = useMutation(TOGGLEWISHLIST_MUTATION, {
    refetchQueries: () => [{ query: ISWISHLIST_QUERY, variables: { id } }],
  });

  const { data, loading } = useQuery(ISWISHLIST_QUERY, {
    variables: {
      id,
    },
  });

  const toggleWishlist = async () => {
    try {
      const {
        data: { TicketProductToggleWishlist },
      } = await toggleWishlistMutation({
        variables: {
          id,
        },
      });
      console.log(TicketProductToggleWishlist);
    } catch (e) {
      console.log(e);
    }
  };

  if (!loading) {
    const { TicketProductIsWishlist: isWishlist } = data;
    return (
      <LikeButton>
        <button
          className={isWishlist ? "like active  " : "like"}
          onClick={() => {
            toggleWishlist();
          }}
        ></button>
      </LikeButton>
    );
  } else {
    return <SLoading />;
  }
}

export default withRouter(Container);
