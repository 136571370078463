export const dayOfWeekName = (day) => {
  const dayOfWeek = ["일", "월", "화", "수", "목", "금", "토"];
  return dayOfWeek[day];
};

export const isPayName = (pay) => {
  const isPay = { true: "유료", false: "무료" };
  return isPay[pay];
};

export const placeTypeName = (type) => {
  const placeType = { OnLine: "온라인", OffLine: "" };
  return placeType[type];
};

export const maskingName = (strName) => {
  if (strName === undefined || strName === "") {
    return "";
  }
  // var pattern = /.{3}$/; // 정규식
  // return strName.replace(pattern, "***");
  return strName;
};

//일자 카운터
export const datesCounter = (startDate, endDate) => {
  const ms = endDate.getTime() - startDate.getTime();

  return ms / (1000 * 60 * 60 * 24);
};
